import React, { useEffect, useRef } from 'react';

const CanvaEditor = ({ clientId, templateUrl, onDesignExported }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.canva.com/design-embed/v1.js";
    script.async = true;
    script.onload = () => {
      if (window.Canva && containerRef.current) {
        window.Canva.DesignEmbed.create({
          container: containerRef.current,
          clientId,
          design: {
            type: 'TEMPLATE',
            // templateUrl: templateUrl, // ex: https://www.canva.com/design/your-template-id
          },
          onDesignExported: (design) => {
            console.log("✅ Visuel exporté :", design);
            if (onDesignExported) onDesignExported(design);
          },
        });
      }
    };
    document.body.appendChild(script);
  }, [clientId, templateUrl]);

  return (
    <div>
      <h3>🎨 Créer ton visuel avec Canva</h3>
      <div ref={containerRef} style={{ height: 600 }} />
    </div>
  );
};

export default CanvaEditor;

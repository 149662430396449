import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,

    Stack,

    Button,

    Container,
    Typography,

    Grid,

    Box,
    Modal,
    TextField,

    MenuItem,

    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Divider,
    styled,
    alpha,
    LinearProgress,
    Avatar,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import Page from '../../../../../components/Page';
import Iconify from '../../../../../components/Iconify';
import { db } from '../../../../../firebase.config';
import CheckCard from '../../../../../components/CheckCard';
import { UserAuth } from '../../../../../context/AuthContext';
import CustomButton from '../../../../../components/CustomButton';
import CustomTag from '../../../../../components/customTag';


export default function TikTokLinkAccount() {

    const getTikTokAuthUrl = () => {
        const clientId = 'sbawjfwa8box4jycf9';
        const redirectUri = encodeURIComponent('https://organisateurs.choptonticket.com/bo');
        const scope = 'user.info.basic'; // Scope pour demander l'accès aux publicités et aux informations utilisateur
        const state = 'some-unique-state'; // Utilisé pour vérifier la validité de la demande
        window.location.href = `https://www.tiktok.com/auth/authorize/?client_key=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`;
    };


    return (

        <Box>
            <Stack paddingTop={3} spacing={3}>
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ border: '1px solid gainsboro', borderRadius: '7px' }}>
                    <Stack padding={4} direction='row'>
                        <Avatar sx={{ width: 70, height: 70 }}>
                            <Iconify icon='logos:tiktok-icon' sx={{ width: 50, height: 50 }} />
                        </Avatar>

                        <Box sx={{ display: 'flex', alignItems: 'end' }}>
                            <Iconify icon='mdi:link' sx={{ width: 20, height: 20 }} />
                        </Box>

                    </Stack>
                    <Stack spacing={2} padding={2}>
                        <Stack direction={'row'} spacing={1}>
                            <Typography variant='h4'>TikTok</Typography>
                            <CustomTag text={'Pas encore disponible'} type={'warning'}/>
                        </Stack>

                        <Typography variant='body2'>Configurez des campagnes publicitaires et partagez des événements sur votre page Facebook lorsque vous connectez votre compte avec Choptonticket.
                        </Typography>
                        {
                            // <CustomButton type='tertiaire' buttonText='Connecter votre compte tiktok' onClick={getTikTokAuthUrl} />
                        }

                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import { filter, forEach } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Menu,
  Link,
  FormControlLabel,
  Autocomplete,
  Divider,
  Alert,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import EmailEditor from 'react-email-editor';
import { httpsCallable } from 'firebase/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toPng } from 'html-to-image';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import parse from 'html-react-parser';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock
import USERLIST from '../../../../_mock/user';

import { db, functions } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import CustomButton from '../../../../components/CustomButton';
import TemplateViewer from './components/templateViewer';
import { Variables } from '../../../../context/VariableContext';
import { useSnackbar } from '../../../../context/snackbarContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '5px',
  boxShadow: 0,
  p: 3,
};

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateCampaign() {
  const { campaignID } = useParams();
  const { user } = UserAuth();
  const showSnackbar = useSnackbar()
  const { hideNavbar, setHideNavbar } = Variables();
  const theme = useTheme();
  const navigate = useNavigate();

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refreh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [contacts, setContact] = React.useState([]);
  const [orgID, setOrgID] = React.useState();
  // take operateurs from firebase
  const [populations, setPopulation] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  // modification de la campagne
  const [isModified, setIsModified] = React.useState(true);

  const handleFieldChange = () => {
    setIsModified(true);
  };


  // campaign settings
  const [nom, setNom] = React.useState([]);
  const [objet, setObject] = React.useState([]);
  const [selectedPopulation, setSelectedPopulation] = React.useState([]);
  const [SelectedEvent, setSelectedEvent] = React.useState();
  const [audience, setAudience] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      setContact([]);
      try {
        setLoading(true);
        // setSelectedTemplate(null)
        setTemplates([])
        setPopulation([]);
        setSelectedPopulation([])
        const tmpSelectedPop = []
        const tmpSelectedTags = []
        const tmpSelectedEvents = []
        if (campaignID) {
          const docRefCampaign = doc(db, 'campagnes', `${campaignID}`);
          const docSnapCampaign = await getDoc(docRefCampaign);
          if (docSnapCampaign.exists()) {
            console.log(docSnapCampaign.data())
            setNom(docSnapCampaign.data()?.name)
            setObject(docSnapCampaign.data()?.object)
            setAudience(docSnapCampaign.data()?.audiences)
            setCampagneType(docSnapCampaign.data()?.campagneType)
            setRecever(docSnapCampaign.data()?.audienceType)

            const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
            const docSnap = await getDoc(docRef0);
            const q1 = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/populations`));
            const snapshot1 = await getDocs(q1);
            snapshot1.forEach(async (d) => {
              setPopulation((populations) => [...populations, d]);
              if (docSnapCampaign.data()?.audiences.indexOf(d.id) !== -1) {
                tmpSelectedPop.push(true)
                tmpSelectedTags.push(d)
              } else {
                tmpSelectedPop.push(false)
              }
              setSelectedTags(tmpSelectedTags)
              setSelectedPopulation(tmpSelectedPop)
            });

            // recupération de event
            const q2 = query(collection(db, `evenement`), where('orgId', '==', docSnap.data().idOrg), where('status', '==', 1),
              orderBy('dateDebut', 'desc'));
            const snapshot2 = await getDocs(q2);
            const tmpevent = []
            snapshot2.forEach(async (d1) => {
              console.log(d1.data().nomEvent)
              tmpevent.push(d1)
              if (docSnapCampaign.data()?.eventID === d1.id) {
                setSelectedEvent(d1)
              }
              // setSelectedTags(tmpSelectedTags)

            });
            setEvents(tmpevent);
            setOrgID(docSnap.data().idOrg)

            const q = query(collection(db, `templates`));
            const snapshot = await getDocs(q);

            snapshot.forEach(async (d) => {
              setTemplates((templates) => [...templates, d]);
            });
          }
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, campaignID]);


  const [htmlContent, setHtmlContent] = useState(null);
  React.useEffect(() => {
    (async () => {
      try {


        const docMailContentRef = doc(db, `organisations/${orgID}/crm/${orgID}/campagneTemplates`, campaignID)
        const docMailContentSnap = await getDoc(docMailContentRef);
        if (docMailContentSnap.exists()) {
          setSelectedTemplate(docMailContentSnap)
          setHtmlContent(docMailContentSnap.data()?.html)
        }



      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [orgID, campaignID, refreh]);


  // loading
  const [loading, setLoading] = React.useState(false);
  const handleChange = async (index) => {
    setLoading(true)
    let tmpAudience = []
    const tmpSelectedPop = selectedPopulation
    tmpAudience = audience || []
    tmpSelectedPop.splice(index, 1, !tmpSelectedPop.at(index));
    console.log(index)
    console.log(tmpSelectedPop)
    try {
      const i = tmpAudience.indexOf(populations[index].id)
      if (i !== -1) {
        tmpAudience.splice(i, 1);
      } else {
        tmpAudience.push(populations[index].id)
      }
      console.log(tmpAudience)
      const docRef = await updateDoc(doc(db, `campagnes`, campaignID), {
        audiences: tmpAudience,
        updatAt: new Date(),
      });
      setAudience(tmpAudience)
      setSelectedPopulation(tmpSelectedPop)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const goToconfirm = async () => {
    try {
      setLoading(true)
      // Sauvegarde dans Firestore
      console.log('ok')
      await setDoc(doc(db, `automations/campagnes/automations`, campaignID), {
        id: campaignID,
        launchAt: new Date(),
      });
      console.log('ok')
      await setDoc(doc(db, `organisations/${orgID}/crm/${orgID}/campagnes`, campaignID), {
        status: 1,
        launchAt: new Date(),
      });
      // rattache la campagne et le template
      const statRef = doc(db, `campagnes`, `${campaignID}`);

      await updateDoc(statRef, {
        status: 1,
        audienceType: 1,
        launchAt: new Date(),
      });
      console.log('ok pour le lancement de la campagne');

    } catch (e) {
      console.error('Erreur lors de la confirmation de la campagne:', e);
    }
    setLoading(false)
  }

  const saveCampaign = async () => {
    try {
      setLoading(true)
      // rattache la campagne et le template
      const statRef = doc(db, `campagnes`, `${campaignID}`);
      console.log(SelectedEvent)
      await updateDoc(statRef, {
        status: 0,
        audienceType: campagneType === 1 ? '' : recever,
        campagneType,
        eventID: SelectedEvent ? SelectedEvent.id : '',
        object: objet,
        updateAt: new Date(),
      });
      setHideNavbar(false)
      setBuilder(false)
      console.log('ok pour le lancement de la campagne');
      showSnackbar('Campagne enregistrer avec succès', false)
      navigate(`/marketing/campaigns`, { replace: true });
    } catch (e) {
      showSnackbar('Erreur lors de la sauvegarde de la campagne', true)
      console.error('Erreur lors de la confirmation de la campagne:', e);
    }
    setLoading(false)
  }

  // templates
  const [builder, setBuilder] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);

  const getTemplate = async () => {
    setLoading(true);
    setTemplates([])
    const templatesData = [];
    /* const docRef0 = doc(db, 'templates');

    const docSnap = await getDocs(db, 'templates'); */
    /* if (docSnap.exists()) {
      
    } */
    // setIdOrg(docSnap.data().idOrg)
    if (templateType === 1) {
      const q = query(collection(db, `organisations/${orgID}/crm/${orgID}/campagneTemplates`));
      const snapshot = await getDocs(q);
      snapshot.forEach(async (d) => {
        console.log(d.data())
        setTemplates((templates) => [...templates, d]);
      });
    } else if (templateType === 2) {
      const q = query(collection(db, `publicTemplates`));
      const snapshot = await getDocs(q);
      snapshot.forEach(async (d) => {
        console.log(d.data())
        setTemplates((templates) => [...templates, d]);
      });
    }


    setLoading(false);
  };
  const [templateType, setTemplateType] = useState();

  React.useEffect(() => {
    (async () => {
      setTemplates([]);
      try {
        await getTemplate()
      } catch (error) {
        console.log('error');
      }
      setLoading(false);
    })();
  }, [templateType]);

  const [showScreenWarning, setShowScreenWarning] = useState(false);
  const [curentTemp, setCurentTemp] = React.useState();
  const [templates, setTemplates] = React.useState([]);
  const pickTemplate = (index) => {
    window.scrollTo(0, 0)
    setCurentTemp(index)
    if (window.innerWidth < 768) { // Taille en pixels pour détecter un écran petit
      setShowScreenWarning(true);
    } else {
      setShowScreenWarning(false);

    }
    setHideNavbar(true)
    setBuilder(true)
  };

  // email builder
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml(data => {
      const { design, html } = data;
      // console.log('exported html:', html);
      // Sauvegarder dans Firestore la version HTML et la structure design
    });
    console.log('salut')
    console.log(emailEditorRef.current?.editor)
  };

  const onLoad = () => {
    /* const editor = emailEditorRef.current.editor;
    
    // Charger les paramètres personnalisés dans l'éditeur
    editor.loadDesign({
      body: {
        rows: [],
      },
    });
 
    // Ajouter le slider pour arrondir les bords des images
    const imageSettings = editor.getToolboxConfig('image');
 
    if (imageSettings) {
      imageSettings.properties.push({
        label: 'Arrondir les bords',
        name: 'borderRadius',
        type: 'slider',
        default: 0,
        min: 0,
        max: 50,
      });
 
      editor.setToolboxConfig('image', imageSettings);
    }
  */
    if (curentTemp) {
      emailEditorRef.current.editor.loadDesign(templates[curentTemp].data().ddesign);
    } else if (selectedTemplate) {
      console.log(selectedTemplate)
      emailEditorRef.current.editor.loadDesign(selectedTemplate.data().ddesign);
    }
  };

  // test modal :
  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [mail, setmail] = React.useState();
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const sendTest = async () => {
    setLoading(true)
    try {
      // envoye du token de validation
      emailEditorRef.current.editor.exportHtml(async data => {
        const { design, html } = data;
        // console.log('exported html:', html);
        // Sauvegarder dans Firestore la version HTML et la structure design

        const addMessage = httpsCallable(functions, 'testCampagne');
        const result = await addMessage({ email: mail, content: html });
        console.log(result.data)
      });
      handleCloseDeleteModal()
      // ouverture de modal de validation
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  // save template campagne in organisation table
  const removeUndefinedFields = (obj) => JSON.parse(JSON.stringify(obj));

  const saveTemplateToFirebase = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      setLoading(true)
      const { design, html } = data;

      const ddesign = removeUndefinedFields(design);

      // Assurez-vous d'avoir le bon conteneur ici
      const editorContainer = document.getElementById('prewiewcontainer1');

      try {
        const previewImage = await toPng(editorContainer); // Capture du conteneur contenant l'éditeur
        console.log(previewImage);
        const storage = getStorage();
        const storageRef = ref(storage, `templatePreviews/${campaignID}/${campaignID}.png`);
        const snapshot = await uploadString(storageRef, previewImage, 'data_url');

        // Obtenir l'URL de téléchargement de l'image
        const imageUrl = await getDownloadURL(snapshot.ref);

        if (ddesign && html) {
          try {
            // Sauvegarde dans Firestore
            await setDoc(doc(db, `organisations/${orgID}/crm/${orgID}/campagneTemplates`, campaignID), {
              ddesign,
              html,
              imageUrl,
              title: nom,
              createdAt: new Date(),
            });
            // rattache la campagne et le template
            const statRef = doc(db, `campagnes`, `${campaignID}`);

            await updateDoc(statRef, {
              mailContentID: campaignID,
            });
            handleCloseSaveModal()
            setHideNavbar(false)
            setBuilder(false)
            setRefresh(!refreh)
            showSnackbar('Modification sauvegarder avec succès ', false)
          } catch (e) {
            console.error('Erreur lors de la sauvegarde du template:', e);
            showSnackbar('Erreur lors de la sauvegarde des modifications', true)
          }
        } else {
          showSnackbar('Modification sauvegarder avec succès ', true)
          console.error('Une erreur est survenue');
        }
      } catch (error) {
        showSnackbar('Erreur lors de la capture du preview image', true)
        console.error('Erreur lors de la capture du preview image:', error);
      }
      setLoading(false)
    });

  };

  const supprimerContentToCampagne = async () => {

    setLoading(true)

    try {

      const statRef = doc(db, `campagnes`, `${campaignID}`);

      await updateDoc(statRef, {
        mailContentID: null,
      });
      const docMailContentRef = doc(db, `organisations/${orgID}/crm/${orgID}/campagneTemplates`, campaignID)
      await deleteDoc(docMailContentRef);
      // handleCloseSaveModal()
      // setBuilder(false)
      setHtmlContent(null)
      setRefresh(!refreh)
      showSnackbar('Le contenue de votre campagne a été suprimé avec succès ', false)
    } catch (e) {
      console.error('Erreur lors de la sauvegarde du template:', e);
      showSnackbar('Erreur lors de la suppression du template ', true)
      setLoading(false)
    }
    setLoading(false)
  };

  const modifyContent = (index) => {
    setCurentTemp(null)
    setHideNavbar(true)
    setBuilder(true)
  };

  const [selectedTags, setSelectedTags] = React.useState([]);
  const handleTagChange = async (event, value) => {
    setLoading(true)
    const tmp = []
    value.forEach(element => {
      console.log(element.id)
      tmp.push(element.id)
    });
    try {
      await updateDoc(doc(db, `campagnes`, campaignID), {
        audiences: tmp,
        updatAt: new Date(),
      });
      setSelectedTags(value);
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  };

  const [campagneType, setCampagneType] = React.useState();
  const [recever, setRecever] = React.useState();
  const [countContact, setCountContact] = React.useState();


  // event
  const handleEventSelect = (selectedEvent) => {
    console.log('Event selected:', selectedEvent);
    setCountContact(selectedEvent.data().participant)
    setSelectedEvent(selectedEvent)
    // Faire quelque chose avec l'événement sélectionné (ex: le sauvegarder dans la campagne)
  };

  // save modal 
  const [htmlpreview, setHtmlPreview] = useState('')
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const handleOpenSaveModal = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      setLoading(true)
      const { design, html } = data;
      try {
        let handlehml = html.replace(/<img[^>]*src=["']https:\/\/cdn\.tools\.unlayer\.com[^>]*>/g, '')
        handlehml = handlehml.replace(/https:\/\/cdn\.templates\.unlayer\.com\/assets\/[^\s]+/g, "https://firebasestorage.googleapis.com/v0/b/event-86cbf.appspot.com/o/imageChoptonticket%2Fpreview.png?alt=media&token=8580a57d-7522-4ee3-8335-efc4a1a9261a");
        handlehml = handlehml.replace(
          /https:\/\/assets\.unlayer\.com\/projects\/[^\s"']+/g,
          "https://firebasestorage.googleapis.com/v0/b/event-86cbf.appspot.com/o/imageChoptonticket%2Fpreview.png?alt=media&token=8580a57d-7522-4ee3-8335-efc4a1a9261a"
        );
        setHtmlPreview(handlehml)
        console.log('Image capturée avec succès !');
        setOpenSaveModal(true);
      } catch (error) {
        console.error('Erreur lors de la capture de l\'image :', error);
        setLoading(false)
      }
      setLoading(false)
    })

  };
  const handleCloseSaveModal = () => {
    setHtmlPreview('')
    setOpenSaveModal(false);
  }




  return (
    <Page title="Campagne">
      {
        !builder ?
          <Box paddingBottom={10}>
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 3 } }}>

              <Typography variant="h2" gutterBottom>
                {nom}
              </Typography>
              <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>

                <Box paddingTop={1} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>

                  <Grid container spacing={1} padding={2} >
                    <Grid item xs={12} sm={4}>
                      <Typography variant='body1'>Nom de la camapagne</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField value={nom} onChange={(e) => setNom(e.target.value)} fullWidth />
                    </Grid>
                  </Grid>

                </Box>
              </Box>
              <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                <Stack direction='row' justifyContent='space-between'>
                  <Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="h4">Audience cible</Typography>
                    </Stack>
                    <Typography variant="caption">A qui envoyez-vous cette campagne</Typography>
                  </Stack>
                  <Typography>0 selectionnés</Typography>
                </Stack>
                <Stack spacing={3} paddingTop={3}>
                  <Stack spacing={1}>
                    <Typography variant='body1'> <strong>Type d'email</strong> </Typography>
                    <Stack my={2} direction='row' spacing={1}>
                      <Box onClick={() => setCampagneType(0)} sx={campagneType === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                        :
                        { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                        <Typography variant='body2'>Email promotionnel</Typography>
                      </Box>
                      <Box onClick={() => setCampagneType(1)} sx={campagneType === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                        :
                        { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                        <Typography variant='body2'>Informations pratiques</Typography>
                      </Box>
                    </Stack>
                    <Typography variant='body2'>Ces mails seront envoyer a vos abonné new letters uniquement</Typography>
                  </Stack>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    campagneType === 0 ?
                      <Stack spacing={1}>
                        <Typography variant='body1'> <strong>Envoyer à</strong> </Typography>
                        <Stack my={2} direction='row' spacing={1}>
                          <Box onClick={() => setRecever(0)} sx={recever === 0 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Tous mes contacts</Typography>
                          </Box>
                          <Box onClick={() => setRecever(1)} sx={recever === 1 ? { bgcolor: '#F5EFFA', border: '1px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Une population</Typography>
                          </Box>
                        </Stack>

                      </Stack>
                      : campagneType === 1 ?
                        <Stack spacing={1}>
                          <Typography variant='body1'> <strong>Evenement</strong> </Typography>
                          <Box padding={2}>
                            {
                              events.length > 0 ?
                                <>

                                  <Autocomplete
                                    options={events}
                                    getOptionLabel={(option) => option.data().nomEvent}
                                    value={SelectedEvent}
                                    renderOption={(props, option) => (
                                      <Stack direction='row' spacing={1} component="li" {...props} display="flex" alignItems="center">
                                        <LazyLoadImage
                                          src={`${option.data().idVisuel}`}
                                          alt={`${option.data().idVisuel}`}
                                          className="img-lazy"
                                          width={70}
                                          height={70}
                                          style={{ objectFit: 'cover' }}
                                          effect="blur" // opacity | black-and-white
                                        />
                                        <Box>
                                          <Typography variant="subtitle1">{option?.data().nomEvent}</Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            dans 3 jours
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            {option?.data().participant} participants
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    )}
                                    renderInput={(params) => <TextField {...params} placeholder="Select Event" variant="outlined" />}
                                    onChange={(_event, value) => handleEventSelect(value)}
                                    filterOptions={(options, { inputValue }) =>
                                      options.filter((option) =>
                                        option?.data().nomEvent.toLowerCase().includes(inputValue.toLowerCase())
                                      )
                                    }
                                  />
                                </>

                                :
                                <Box sx={{ padding: 2, bgcolor: '#FFADB0', borderRadius: '10px' }}>
                                  <Typography variant='body2'>Aucun evenement disponible</Typography>
                                </Box>
                            }
                          </Box>
                        </Stack> : null
                  }
                </Stack>
                {
                  campagneType === 0 ?
                    <>
                      {
                        recever === 0 ?
                          <Box padding={2}>
                            <Typography variant='body1'>Ces mails seront envoyer a vos abonné new letters uniquement </Typography>
                          </Box>
                          : recever === 1 ?
                            <Box padding={2}>
                              <Autocomplete
                                multiple
                                id="tags-standard"
                                options={populations}
                                getOptionLabel={(population) => population.data().name}
                                value={selectedTags}
                                onChange={handleTagChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={populations ? 'Choisisser au moins une population' : null}
                                    // error={!!error}
                                    multiline
                                  // helperText={error}
                                  />
                                )}
                                renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((population, index) => (
                                    <Chip
                                      sx={{ bgcolor: '#F5EFFA' }}
                                      key={population.name}
                                      label={population.data().name}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                              />
                            </Box>
                            : null
                      }
                    </>
                    : campagneType === 1 ?
                      <Box sx={{ padding: 2, bgcolor: '#F5EFFA', borderRadius: '10px' }}>
                        <Typography variant='body2'>les mails seront envoyé à <strong>{countContact}</strong> contacts</Typography>
                      </Box>
                      : null

                }
              </Box>
              <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="h4">Sujet</Typography>
                </Stack>
                <Typography variant="body2">Quel est le sujet de cette campagne</Typography>
                <Box paddingTop={1} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>

                  <Grid container spacing={1} padding={2} >
                    <Grid item xs={12} sm={4}>
                      <Typography variant='body1'>Sujet</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField value={objet} onChange={(e) => setObject(e.target.value)} fullWidth />
                    </Grid>
                  </Grid>

                </Box>
              </Box>
              <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                <Stack direction="row" alignItems="center" justifyContent='space-between' spacing={1}>
                  <Stack>
                    <Typography variant="h4">Conception</Typography>
                    <Typography variant="body2">Choisissez un template pour commencer ou partez de zero</Typography>
                  </Stack>
                  {
                    htmlContent && <Stack direction='row' spacing={1}>
                      <CustomButton loading={loading} type='secondaire' buttonText='Modifier' onClick={modifyContent} />
                      <CustomButton loading={loading} type='secondaire' buttonText='Supprimer' onClick={supprimerContentToCampagne} />
                    </Stack>
                  }

                </Stack>


                {
                  htmlContent ?
                    <Box width="100%" padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                      <Card sx={{ padding: 2, minHeight: 500 }} onClick={() => { pickTemplate(1) }}>
                        <Box sx={{ flex: 1, overflow: 'auto' }}>
                          <iframe
                            title={selectedTemplate?.data()?.titre}
                            srcDoc={selectedTemplate?.data()?.html}
                            style={{ width: '100%', height: 450, border: 'none' }}
                          />
                        </Box>
                        <Typography>{selectedTemplate?.data()?.titre}</Typography>
                      </Card>
                    </Box>
                    :
                    <>
                      {/** contenu du template */}
                      {showTemplates ? (
                        <Box width="100%" padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                          <Stack direction='row'>
                            <CustomButton type='back' buttonText='retour' onClick={() => { setShowTemplates(false) }} />
                            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography variant='subtitle1'><strong>Choisir votre template</strong></Typography>
                            </Box>
                          </Stack>

                          <Grid container spacing={1} padding={2}>
                            {templates.map((template, index) => (
                              <Grid key={index} item xs={12} sm={3} >
                                <Card sx={{ padding: 2, minHeight: 300, cursor: 'pointer' }} onClick={() => { pickTemplate(index) }}>
                                  <Box sx={{ flex: 1, overflow: 'auto' }}>
                                    <LazyLoadImage
                                      src={`${template.data().imageUrl}`}
                                      alt={`${template.data().imageUrl}`}
                                      className="img-lazy"
                                      height={350}
                                      style={{ objectFit: 'cover' }}
                                      effect="blur" // opacity | black-and-white
                                    />
                                  </Box>
                                  <Box sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant='body2'>{template.data().title}</Typography>
                                  </Box>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>

                        </Box>
                      ) :
                        <Box padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                          <Grid container spacing={1}>
                            <Grid item xs={4} sm={3} onClick={() => { }}>
                              <Stack justifyContent='center' sx={{ cursor: 'pointer', width: '100%', height: '100%', padding: 2, border: '1px solid gainsboro', borderRadius: '7px' }} onClick={(e) => {
                                setHideNavbar(true)
                                setBuilder(true)
                              }}>
                                <Typography variant='body2'><strong>Nouveau template</strong></Typography>
                                <Typography variant='body2'>Créer un nouveau template</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={4} sm={3} onClick={() => { }}>
                              <Stack justifyContent='center' sx={{ cursor: 'pointer', width: '100%', height: '100%', padding: 2, border: '1px solid gainsboro', borderRadius: '7px' }} onClick={() => { setShowTemplates(true); setTemplateType(1) }}>
                                <Typography variant='body2'><strong>Templates de campagnes</strong></Typography>
                                <Typography variant='body2'>Tous vos template utilisé lors de vos campagnes</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={4} sm={3} onClick={() => { }}>
                              <Stack justifyContent='center' sx={{ cursor: 'pointer', width: '100%', height: '100%', padding: 2, border: '1px solid gainsboro', borderRadius: '7px' }} onClick={() => { setShowTemplates(true); setTemplateType(2); }}>
                                <Typography variant='body2'><strong>Templates publics</strong></Typography>
                                <Typography variant='body2'>Provided by Choptonticket</Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      }
                    </>
                }

              </Box>
              {isModified && (
                <Container>
                  <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: 'white',
                      padding: '16px',
                      // borderTop: '2px solid gainsboro',
                      boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                      zIndex: 1000,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',

                    }}
                  >
                    <CustomButton
                      type='secondaire'
                      buttonText='retour'
                      loading={loading}
                      onClick={() => window.history.back()}
                    />
                    <Stack direction='row' spacing={1}>
                      <CustomButton
                        type='secondaire'
                        buttonText='enregistrer et quitter'
                        loading={loading}
                        onClick={saveCampaign}
                      />
                      <CustomButton
                        type='primaire'
                        buttonText='Confirmer'
                        loading={loading}
                        onClick={goToconfirm}
                      />
                    </Stack>

                  </Stack>
                </Container>

              )}
            </Container>
          </Box>
          :
          <Box sx={{
            display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', position: 'absolute',  // Permet de placer la div à un endroit spécifique
            top: 0,                // Placer la div au haut de la page
            left: 0,
          }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ flex: '0 0 auto', padding: 1, backgroundColor: '#f5f5f5' }}>
              <CustomButton startIcon={<Iconify icon="material-symbols:arrow-back-ios" />} type='secondaire' buttonText='retour' onClick={() => {
                setHideNavbar(false)
                setBuilder(false)
              }} />
              <Typography variant='body2'><strong>Comception</strong></Typography>
              {
                !showScreenWarning && <Stack direction='row' spacing={1}>
                  <CustomButton type='secondaire' buttonText='Envoyer un tester' onClick={() => { setOpenDeleteModal(true) }} />
                  <CustomButton loading={loading} type='primaire' buttonText='Enregistrer' onClick={handleOpenSaveModal} />
                  <Modal
                    open={openSaveModal}
                    onClose={handleCloseSaveModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ borderRadius: '7px' }}
                  >
                    <Box sx={styleModal}>
                      <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='subtitle1'>
                          Utiliser ce template pour votre campagne
                        </Typography>
                      </Box>

                      <Stack direction='row' spacing={2} paddingTop={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CustomButton loading={loading} type='secondaire' buttonText='Annuler' onClick={handleCloseSaveModal} />
                        <CustomButton loading={loading} type='primaire' buttonText='Enregistrer' onClick={saveTemplateToFirebase} />
                      </Stack>
                    </Box>
                  </Modal>
                </Stack>
              }

            </Stack>
            <Box sx={{ flex: 1, }}>
              {
                showScreenWarning ?

                  <Box>
                    <Alert severity="warning">Veuillez vous connectez avec un PC pour avoir access a l'editeur d'email</Alert>
                  </Box>

                  :
                  <EmailEditor
                    ref={emailEditorRef}
                    options={{
                      appearance: {
                        theme: 'classic_dark',
                        panels: {
                          tools: {
                            dock: 'left',
                          },
                        },
                      }
                    }}
                    onLoad={onLoad} style={{ height: '92vh' }} />
              }

            </Box>
            <div id="prewiewcontainer1" style={{
              position: 'absolute',  // Permet de placer la div à un endroit spécifique
              top: 0,                // Placer la div au haut de la page
              left: 0,               // Placer la div à gauche
              zIndex: -1,            // Place la div derrière les autres éléments
            }} dangerouslySetInnerHTML={{ __html: htmlpreview }} />
            {/** modal de test */}
            {/** popup de suppression du billet */}
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: '7px' }}
            >
              <Box sx={styleModal}>
                <Stack spacing={4}>

                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="subtitle1">Envoyer un email de test</Typography>
                  </Box>
                  <Box sx={{ marginTop: 5, paddingX: 2 }} dir="ltr">
                    <TextField value={mail} onChange={(e) => setmail(e.target.value)} fullWidth />
                  </Box>
                  <Stack direction='row' spacing={2} justifyContent='center'>
                    <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseDeleteModal} />
                    <CustomButton type='primaire' buttonText='Envoyer' onClick={sendTest} loading={loading} />
                  </Stack>

                </Stack>

              </Box>
            </Modal>
          </Box>
      }

    </Page>
  );
}

import React, { useEffect, useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Chip,
    Box,
    Divider,
    Tooltip,
    Alert,
    Container,
    Stack,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BoltIcon from '@mui/icons-material/Bolt';
import { collection, doc, getDoc, query, where } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CartesianGrid, Legend, LineChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { UserAuth } from '../../../../context/AuthContext';
import { useSnackbar } from '../../../../context/snackbarContext';
import { Variables } from '../../../../context/VariableContext';
import { db } from '../../../../firebase.config';
import Page from '../../../../components/Page';
import CustomButton from '../../../../components/CustomButton';
import CustomTag from '../../../../components/customTag';
import Sum from './components/shwoPaidCampaignComponent/sum';
import Perfs from './components/shwoPaidCampaignComponent/perfs';

const CampaignDashboard = () => {

    const { campaignID } = useParams();
    const { user, userData } = UserAuth();
    const showSnackbar = useSnackbar()
    const navigate = useNavigate()
    const [token, setToken] = useState();
    const [fbData, setFbData] = useState();
    const [fbCampagneData, setFbCampagneData] = useState();
    const [selectedAdAccount, setSelectedAdAccount] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [visuelUrl, setVisuelUrl] = useState();
    const [media, setMedia] = useState(null)
    const [visuel, setvisuel] = useState();
    const [text, setText] = useState("✨ Join us for an unforgettable night at the test event! 🌜 Get ready to dance the night away and make memories that will last a lifetime.Mark your calendars for Saturday, June 7th at 3:00 AM at the stunning 12 Via des Cerises venue. Don’t miss out on this epic event - get your tickets now! 🎉 #test #partytime #nightlife");
    const [titleLink, setTitleLink] = useState('');
    const [linkDesc, setLinkDesc] = useState("Reserver sur Choptonticket!");
    const [btText, setBtText] = useState('Acheter vos tickets');
    const [age, setAge] = useState({ min: 17, max: 35 });
    const handleChangeAge = (field, value) => {
        console.log(value)
        setAge(prev => ({ ...prev, [field]: value }));
    }
    const [gender, setGender] = useState('');

    const handleChange = (event) => {
        setGender(event.target.value);
    };
    const [type, setType] = useState(0);
    const [budget, setBudget] = useState(0);
    const [typeBudget, setTypeBudget] = useState(0);
    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [interests, setIntersts] = useState([]);
    const [selectedInterst, setSelectedInterest] = useState([]);

    // const { hideNavbar, setHideNavbar } = Variables()

    const [dateDebut, setDateDebut] = useState(null);
    const [timeDebut, setTimeDebut] = useState('all');


    const handleDateDebutChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };
    const [dateFin, setDateFin] = useState(null);
    const [timeFin, setTimeFin] = useState('');

    const handleTimestamp = (e) => {
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
    };

    const handleTimestampCustom = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
        return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    };
    const { clicks = 0, impressions = 0, cpc = 0, spend = 0 } = { clicks: 10, impressions: 20, cpc: 30, spend: 40 };

    const remainingBudget = budget - spend;
    const clickEstimate = budget / (cpc || 0.01);
    const performance = clicks / clickEstimate;

    // 🎯 Suggestion automatique
    const getSuggestion = () => {


    };

    const suggestion = getSuggestion();
    const metricsByDay = [
        { date: '2025-03-20', clicks: 15, impressions: 400 },
        { date: '2025-03-21', clicks: 25, impressions: 600 },
        { date: '2025-03-22', clicks: 20, impressions: 500 },
    ]


    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
                const docSnap = await getDoc(docRef0);
                // recuperation de la campagne
                const docRefCampaign = doc(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/paidCampains`, `${campaignID}`);
                const docSnapCampaign = await getDoc(docRefCampaign);
                if (docSnapCampaign.exists()) {
                    setFbCampagneData(docSnapCampaign.data())
                    setAge(docSnapCampaign.data().age)
                    setGender(docSnapCampaign.data().gender)
                    setCurrency(docSnapCampaign.data().currency)
                    setBudget(docSnapCampaign.data().budget)
                    setTypeBudget(docSnapCampaign.data().typeBudget)
                    setText(docSnapCampaign.data().text)
                    setLinkDesc(docSnapCampaign.data().linkDesc)
                    setTitleLink(docSnapCampaign.data().titleLink)
                    setSelectedInterest(docSnapCampaign.data().selectedInterst || [])
                    setSelectedLocations(docSnapCampaign.data().selectedLocations || [])
                    setDateDebut(docSnapCampaign.data().startAt ? handleTimestamp(docSnapCampaign.data().startAt) : null)
                    setTimeDebut(docSnapCampaign.data().startAt ? handleTimestampCustom(docSnapCampaign.data().startAt) : null)
                    setDateFin(docSnapCampaign.data().endAt ? handleTimestamp(docSnapCampaign.data().endAt) : null)
                    setTimeFin(docSnapCampaign.data().endAt ? handleTimestampCustom(docSnapCampaign.data().endAt) : null)
                    // setVisuelUrl(docSnapCampaign.data().mediaUrl)
                    setMedia(docSnapCampaign.data().media)
                    setSelectedAdAccount(docSnapCampaign.data().selectedAdAccount || {})
                    setSelectedPage(docSnapCampaign.data().selectedPage || {})
                }
                // recupération de event

                // setOrgID(docSnap.data().idOrg)
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, [user, userData, campaignID]);

    return (
        <Page title="Campagne">
            <Container sx={{ padding: 2, bgcolor: 'white' }}>
                <Stack spacing={2} direction={'column'} alignItems={'start'} sx={{width:'100%'}}>
                    <CustomButton buttonText={'Retour'} type={'secondaire'} />
                    <Box sx={{ padding: 2, borderRadius: '10px', bgcolor: '#f2f2f2', width: '100%' }}>
                        <Box>
                            <Typography variant="h5">🎯 Résumé de la campagne</Typography>
                            <CustomTag text={'Brouillon'} type={'warning'} />
                            <Typography variant="body2" color="text.secondary">Test</Typography>
                        </Box>
                        <Stack direction={'row'} spacing={2} sx={{ width: '100%' }}>
                            <CustomButton buttonText={'Modifier la campagne'} type={'secondaire'} />
                            <CustomButton buttonText={'Dupliquer la campagne'} type={'secondaire'} />
                        </Stack>
                    </Box>

                    <Stack my={2} direction='row' spacing={1}>
                        <Box onClick={() => {
                            setType(0)

                        }} sx={type === 0 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Details</Typography>
                        </Box>
                        <Box onClick={() => {
                            setType(1)

                        }} sx={type === 1 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Perfommance</Typography>
                        </Box>
                    </Stack>
                    {
                        type === 0 ?
                            <Sum />
                            : <Perfs />
                    }
                    <Grid container spacing={2}>
                        {/* 🧾 Résumé Campagne */}
                        <Grid item xs={12} md={4} padding={4}>
                            <Card sx={{ boxShadow: 5, borderRadius: '10px' }}>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Box>
                                            <Typography variant="h5">🎯 Résumé de la campagne</Typography>
                                            <Divider sx={{ my: 1 }} />

                                            <Typography variant="subtitle1">Test</Typography>
                                            <Typography variant="body2" color="text.secondary">Test</Typography>
                                        </Box>


                                        <Box >
                                            <Chip label={`Statut :`} color="primary" />
                                        </Box>

                                        <Box >
                                            <Tooltip title="Date de début">
                                                <Chip icon={<AccessTimeIcon />} label={''} sx={{ mr: 1 }} />
                                            </Tooltip>
                                            <Tooltip title="Date de fin">
                                                <Chip icon={<AccessTimeIcon />} label={''} />
                                            </Tooltip>
                                        </Box>

                                        <Box >
                                            <Typography variant="body2">💰 Budget total : {budget} €</Typography>
                                            <Typography variant="body2">🔻 Dépensé : {spend.toFixed(2)} €</Typography>
                                            <Typography variant="body2">📦 Reste : {remainingBudget.toFixed(2)} €</Typography>
                                        </Box>
                                        <CustomButton buttonText={'Modifier'} />
                                    </Stack>

                                </CardContent>
                            </Card>
                        </Grid>

                        {/* 📊 Performances & Suggestions */}
                        <Grid item xs={12} md={8}>
                            <Card sx={{ boxShadow: 5, borderRadius: '10px' }}>
                                <CardContent>
                                    <Typography variant="h5">📈 Performance de la campagne</Typography>
                                    <Divider sx={{ my: 1 }} />

                                    <Box display="flex" justifyContent="space-between" mt={1}>
                                        <Box>
                                            <Typography variant="body2">🖱️ Clics :</Typography>
                                            <Typography variant="h6">{clicks}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2">👁️ Impressions :</Typography>
                                            <Typography variant="h6">{impressions}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2">💵 CPC :</Typography>
                                            <Typography variant="h6">{cpc.toFixed(2)} €</Typography>
                                        </Box>
                                    </Box>


                                </CardContent>
                            </Card>
                            <Card sx={{ boxShadow: 5, borderRadius: '10px' }}>
                                <Box mt={4}>
                                    <Typography variant="h6" gutterBottom>
                                        📊 Évolution quotidienne
                                    </Typography>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <LineChart data={metricsByDay}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="clicks" stroke="#3f51b5" name="Clics" />
                                            <Line type="monotone" dataKey="impressions" stroke="#f50057" name="Impressions" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>

                            </Card>
                        </Grid>
                    </Grid>
                </Stack>

            </Container>

        </Page>

    );
};

export default CampaignDashboard;

import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Box, CircularProgress } from '@mui/material';
import { isUndefined } from 'lodash';
import { UserAuth } from '../context/AuthContext';
import { db } from '../firebase.config';

const ProtectedRoute = ({ children }) => {
  const { user, userData, logOut } = UserAuth();
  const [checkAdmin, setCheckAdmin] = useState(false);
  const navigate = useNavigate();
  const { eventID } = useParams();
  

  useEffect(() => {
    (async () => {
      if (user != null) {
        // console.log(userData)
        try {
          console.log(user.uid)
          const docRef0 = doc(db, 'organisateurs', user.uid);
          const docSnap = await getDoc(docRef0);
         
          if (!docSnap.exists()) { 
            navigate('/register/organisation', { replace: true });
            
          }

          if (isUndefined(docSnap?.data()?.idOrg)) { 
            navigate('/register/organisation', { replace: true });
            
          }

          const orgSnap = await getDoc(doc(db, 'organisations', docSnap?.data()?.idOrg));
          if (!orgSnap.exists()) { 
            navigate('/register/organisation', { replace: true });
          }

          if (orgSnap.data().certified === true) { 
            console.log('ok')
          }
          else{
            navigate('/register/organisation', { replace: true });
          }

          if (docSnap.exists()) {
            if (eventID != null) {
              // get event
              const eventRef = doc(db, 'evenement', eventID);
              const eventSnap = await getDoc(eventRef);
              if (eventSnap.exists()) {
                if (eventSnap.data()?.orgId === docSnap.data()?.idOrg ) {
                    setCheckAdmin(true);
                } else {
                    navigate('/bo');
                } 
              } else {
                navigate('/bo');
              }
            } else {
              setCheckAdmin(true);
            }
          } else {
            console.log('not exist');
            logOut();
            navigate('/login');
          }
        } catch (error) {
          console.log(error);
          setCheckAdmin(true);
        }
      }else{
        navigate('/login')
      }
    })();
  }, [user]);


  if (!checkAdmin) {
    // return <Navigate to='/login'/>
    return (
      <Box height={'100%'} width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return children;
};

export default ProtectedRoute;

/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { faker } from '@faker-js/faker';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Alert,
  Autocomplete,
  Chip,
  CardContent,
} from '@mui/material';

// components

import { collection, doc, Firestore, getDoc, getDocs, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Confetti, { ReactConfetti } from 'react-confetti';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { db, storage } from '../firebase.config';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import CustomButton from '../components/CustomButton';
import CheckCard from '../components/CheckCard';
import Sharefb from '../assets/images/Share_Event_FB.png';
import ShareTikTok from '../assets/images/Share_Tiktok.png';
import EmailMarketing from '../assets/images/email_marketing.png';

// sections

// ----------------------------------------------------------------------

export default function Publish() {
  // rich text
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const affiche = () => {
    console.log('salut');
    console.log(editorState.getCurrentContent());
  };
  const theme = useTheme();
  const { eventID } = useParams();
  const [events, setEvents] = React.useState([]);
  const [description, setDescription] = React.useState([]);
  const [desc, setDesc] = React.useState('');
  const [tickets, setTickets] = React.useState([]);
  const [operateurs, setOperateurs] = React.useState([]);
  const [visibilite, setVisibilite] = React.useState(0);
  const [publiée, setPublish] = React.useState(false);
  const navigate = useNavigate();

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);

  const setVisible = () => {
    setVisibilite(0);
  };

  const setPrivate = () => {
    setVisibilite(1);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setEvents([]);
        setTickets([]);
        setLoading(true);

        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);

        if (docSnap.exists()) {
          createEventList(docSnap.data());
          if (docSnap.data().visibility) {
            setVisibilite(docSnap.data().visibility)
          } else {
            setVisibilite(0)
          }
          // recupération des tickets
          const q = query(collection(db, `evenement/${docSnap.id}/tickets`));
          const snapshot = await getDocs(q);
          snapshot.forEach((d) => {
            console.log(d.data());
            setTickets((tickets) => [...tickets, d.data()]);
          });

          // recupération des operateurs

          // recupération des seances
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [refreh]);

  // upload functions

  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // --------------------

  // loading
  const [loading, setLoading] = React.useState(false);

  // convert timestamp

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  // fonction de mise à jour

  const handleToggle = () => {
    setOpen(!open);
  };

  const handlePublication = async () => {
    try {
      setLoading(true);
      if (tickets.length !== 0) {
        const refEvent = doc(db, `evenement`, eventID);
        const docSnap = await getDoc(refEvent);
        if (docSnap.exists()) {
          await updateDoc(refEvent, {
            status: parseInt(visibilite === 1 ? 3 : 1),
            visibility: parseInt(visibilite),
            tags: selectedTags
          });
          setPublish(!publiée);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handlePlanify = async () => {
    try {
      setLoading(true);
      if (tickets.length !== 0) {
        const refEvent = doc(db, `evenement`, eventID);
        const docSnap = await getDoc(refEvent);
        if (docSnap.exists()) {
          await updateDoc(refEvent, {
            status: parseInt(4),
            visibility: parseInt(visibilite),
            tags: selectedTags
          });
          setPublish(!publiée);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const allTags = ['Concert', 'Reggae', 'Zouk', 'DJ', 'Hip-hop', 'Pop', 'Electro', 'Jazz', 'Blues', 'Rap', 'RnB'];
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [error, setError] = React.useState('');

  const handleTagChange = (event, value) => {
    if (value.length <= 10) {
      setSelectedTags(value);
      setError('');
    } else {
      setError('Vous ne pouvez sélectionner que 10 tags au maximum.');
    }
  };

  return (
    <Page title="Publication" >
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      {events.map((event) =>
        !publiée ? (
          <>
          

            <Container key={event.nomEvent} sx={{ paddingTop: { xs: 3 }, bgcolor: 'white' }}>
            <Box sx={{ py: 3 }} dir="ltr">
                <Stack direction='row'
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <CustomButton
                    type='secondaire'
                    buttonText='Retour'
                    startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                  
                </Stack>
              </Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">
                  Publication de votre événement
                  <br />
                  <Typography variant="body2">
                    Vous êtes à un clic de publier votre événement ! Prenez le temps de vérifier vos paramètres.
                  </Typography>
                </Typography>
              </Stack>

              <Grid container padding={2} paddingY={5} spacing={5}>
                <Grid item xs={12} sm={5}>
                  <Card sx={{ borderRadius: '10px' }}>
                    <CardMedia component="img" height={370} image={`${event.idVisuel}`} alt="green iguana" />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', border: '2px solid gainsboro' }}>
                    <Stack >
                      <Typography variant="subtitle1">{event.nomEvent}</Typography>
                      <Typography variant="body2">
                        {event.type}
                      </Typography>
                      <Typography variant='body1'>
                        {format(new Date(handleTimestamp(event.dateDebut)), 'EEE dd MMM yyyy HH:mm', { locale: fr })} -{' '}
                        {format(new Date(handleTimestamp(event.dateFin)), 'EEE dd MMM yyyy hh:mm', { locale: fr })}{' '}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography variant='body2'>
                          {event.lieu_event}, {event.city}, {event.country}{' '}
                        </Typography>
                      </Stack>
                      {
                        /**
                         * <Stack direction='row' spacing={1} alignItems='center'>
                        <Iconify icon='material-symbols-light:date-range-outline-sharp' sx={{ height: 20, width: 20 }} />
                        <Typography variant='body2'>2 séances</Typography>
                      </Stack>
                         */
                      }
                      
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Iconify icon='ph:ticket-thin' sx={{ height: 20, width: 20 }} />
                        <Typography variant='body2'>{tickets.length} Billets mise en vente</Typography>
                      </Stack>
                      <Divider />
                      <Box sx={{ paddingY: 2, }}>
                        <Typography variant="subtitle1">Tags</Typography>
                        <Typography variant="body2">Aidez les gens à découvrir votre événement en ajoutant des étiquettes associées au thème, au sujet, à l'ambiance.</Typography>
                        <Box sx={{ maxWidth: 500, marginTop: 3 }}>

                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={allTags}
                            getOptionLabel={(option) => option}
                            value={selectedTags}
                            onChange={handleTagChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Ajouter des tags à votre event"
                                error={!!error}
                                multiline
                                helperText={error}
                              />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Chip
                                  key={option}
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                          />

                          <Typography variant='caption'>{selectedTags.length} / 10 tags</Typography>
                          {error && (
                            <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
                              {error}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', border: '2px solid gainsboro', marginX: 2 }}>
                <Typography variant="subtitle1">Votre événement est-il public ou privé ?</Typography>
                <Stack direction='row' spacing={1} marginY={2}>
                  <Box onClick={() => setVisibilite(0)} sx={visibilite === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                    :
                    { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='material-symbols:public' sx={{ height: 20, width: 20 }} />
                      <Typography variant='body2'>Public</Typography>
                    </Stack>

                  </Box>
                  <Box onClick={() => setVisibilite(1)} sx={visibilite === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                    :
                    { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='material-symbols:lock' sx={{ height: 20, width: 20 }} />
                      <Typography variant='body2'>Privé</Typography>
                    </Stack>

                  </Box>
                </Stack>

                <Typography variant='body2'>{visibilite === 1 ? 'Seules les personnes ayant le lien vers votre évènement pourront le voir.' : 'Tout le monde peut voir votre évènement. Les évènements publics sont visibles sur Choptonticket (site web & appli)' }</Typography>
              </Box>



              {/* ----------------------------------------------------- */}

              <Box sx={{ p: 3 }} dir="ltr">
                <Stack direction='row'
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <CustomButton
                    type='secondaire'
                    buttonText='Retour'
                    startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                  <Stack spacing={1} direction='row'>
                    <CustomButton
                      type='primaire'
                      backgroundColor="black"
                      color="#fff"
                      buttonText='Planifier'
                      startIcon={<Iconify icon='ic:baseline-schedule' sx={{ width: 20, height: 20 }} />}
                      onClick={() => {
                        navigate(`/${eventID}/dashboard/app`, { replace: true });
                      }}
                    />
                    <CustomButton
                      type='primaire'
                      backgroundColor="black"
                      color="#fff"
                      buttonText='Publier'
                      onClick={handlePublication}
                    />
                  </Stack>

                </Stack>
              </Box>
            </Container>
          </>
        ) : (
          <Container key={event.nomEvent} sx={{ paddingTop: { xs: 3 } }}>
            <Stack spacing={4} paddingBottom={5}>
              <Confetti />

              <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
                <Iconify icon={'qlementine-icons:success-32'} sx={{height:60, width:60, color:'green'}} />
                <Typography variant='h4'>
                  Félicitations
                </Typography>
                <Typography variant='subtitle1' textAlign={'center'}>
                  Votre evenement est publié, les participations peuvent a présent  acheter les billets
                </Typography>
                <CustomButton
                  backgroundColor="#5E17EB"
                  color="#fff"
                  type='primaire'
                  buttonText={<Typography>Retourner au tableau de bord</Typography>}
                  onClick={() => {
                    navigate(`/${eventID}/dashboard/app`, { replace: true });
                  }}
                />
              </Stack>
              <Box paddingTop={4}>
                <Typography variant='h5'>Comment promouvoir votre événement avec Choptonticket</Typography>
                <Stack direction='row' spacing={2} useFlexGap flexWrap="wrap" justifyContent='space-between'>
                  <Card sx={{ flex: 1 }}>
                    <CardMedia
                      sx={{ height: 300 }}
                      image={ShareTikTok}
                      title="green iguana"
                    />
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant='body1'><strong>Partagez votre événement avec vos abonnés</strong></Typography>
                        <Typography variant='body1'>En quelques clics, partagez votre événement sur TikTok et LinkedIn.</Typography>
                        <CustomButton
                          fullWidth
                          type='primaire'
                          buttonText='Partargez sur tiktok'
                          onClick={() => {
                            navigate(`/${eventID}/dashboard/app`, { replace: true });
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                  <Card sx={{ flex: 1 }}>
                    <CardMedia
                      sx={{ height: 300 }}
                      image={EmailMarketing}
                      title="green iguana"
                    />
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant='body1'><strong>Envoyez une campagne par e-mail</strong></Typography>
                        <Typography variant='body1'>Engagez de publics nouveaux et actuels avec une campagne marketing par email.</Typography>
                        <CustomButton
                          fullWidth
                          type='primaire'
                          buttonText='Créer une campagne'
                          onClick={() => {
                            navigate(`/${eventID}/dashboard/app`, { replace: true });
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                  <Card sx={{ flex: 1 }}>
                    <CardMedia
                      sx={{ height: 300 }}
                      image={Sharefb}
                      title="green iguana"
                    />
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant='body1'><strong>Ajouter votre événement sur Facebook</strong></Typography>
                        <Typography variant='body1'>Créez un événement Facebook directement à partir de choptonticket et regardez vos ventes de billets décoller.</Typography>
                        <CustomButton
                          fullWidth
                          type='primaire'
                          buttonText='Ajouter à facebook'
                          onClick={() => {
                            navigate(`/${eventID}/dashboard/app`, { replace: true });
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Stack>
              </Box>

            </Stack>
          </Container>

        )
      )}
    </Page>
  );
}

/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import { filter, forEach } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Menu,
  Link,
  FormControlLabel,
  Autocomplete,
  Divider,
  Alert,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import EmailEditor from 'react-email-editor';
import { httpsCallable } from 'firebase/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toPng } from 'html-to-image';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';

import Iconify from '../../../../../../components/Iconify';


import { db, functions } from '../../../../../../firebase.config';
import CheckCard from '../../../../../../components/CheckCard';
import { UserAuth } from '../../../../../../context/AuthContext';
import CustomButton from '../../../../../../components/CustomButton';
import { Variables } from '../../../../../../context/VariableContext';
import { useSnackbar } from '../../../../../../context/snackbarContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '5px',
  boxShadow: 0,
  p: 3,
};

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Sum() {
  



  return (
   
          <Box paddingBottom={10} sx={{width: '100%'}} padding={2}>
            <Stack spacing={2} padding={2}>
            <Typography variant='h6'>Reumé de la camapagne</Typography>
                <Grid container>
                    <Grid item xs={4}>
                        <Card sx={{width:'100%', boxShadow:0, borderRadius:'10px', bgcolor:'#f2f2f2', padding:2}}>
                            <Stack>
                                <Typography variant='subtitle1'>Audiance</Typography>
                                <Box>
                                    <Typography>dd</Typography>
                                </Box>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Stack>
            
          </Box>    
          
  );
}

/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Skeleton,
    InputAdornment,
    Menu,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, orderBy, query, startAfter } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock
import USERLIST from '../../../../_mock/user';

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import Swipeable from '../swiper';
import CustomButton from '../../../../components/CustomButton';
import CustomTag from '../../../../components/customTag';

// ----------------------------------------------------------------------
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 7,
        border: '0.5px solid black',
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));
const PAGE_SIZE = 10; // Nombre de contacts par page
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ViewPopulation() {
    const { eventID } = useParams();

    const navigate = useNavigate();
    const { populationID } = useParams();
    const { user } = UserAuth();
    const theme = useTheme();
    const [orgID, setOrgID] = React.useState();

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const { openDrawer, setOpenDrawer } = Variables();
    const { type, setType } = Variables();

    const handleImportation = () => {
        setType(6);
        setOpenDrawer(!openDrawer);
    };


    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // take operateurs from firebase
    const [userpop, setuserPop] = React.useState([]); // contient les id des users de la population
    const [contacts, setContacts] = React.useState([]);
    const [population, setPopulation] = React.useState();

    // take operateurs from firebase
    const [curent, setCurent] = React.useState();
    const [tmp, setTmp] = React.useState();
    // const [type, setType] = React.useState();
    const [search, setSearch] = React.useState('');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        if (page < newPage) {
            next();
        } else {
            console.log('back');
            back();
        }
        console.log(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
    };


    const next = async () => {
        const lastVisible = userpop[userpop.length - 1];
        const first = query(
            collection(db, `organisations/${orgID}/crm/${orgID}/populations/${populationID}/users`),
            orderBy('addedAt', 'desc'),
            startAfter(lastVisible),
            limit(PAGE_SIZE)
        );
        const snapshot = await getDocs(first);
        setuserPop(snapshot.docs)
        setContacts([]);
        snapshot.forEach(async (d) => {
            const contactDoc = doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, `${d.id}`);
            const contactSnap = await getDoc(contactDoc);
            setContacts((contacts) => [...contacts, contactSnap]);
        });

    };

    const back = async () => {
        const lastVisible = userpop[0];

        // -------------------------------------
        const q = query(
            collection(db, `organisations/${orgID}/crm/${orgID}/populations/${populationID}/users`),
            orderBy('addedAt', 'desc'),
            endBefore(lastVisible),
            limitToLast(PAGE_SIZE)
        );
        const snapshot = await getDocs(q);
        setuserPop(snapshot.docs)
        setContacts([]);
        snapshot.forEach(async (d) => {
            const contactDoc = doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, `${d.id}`);
            const contactSnap = await getDoc(contactDoc);
            setContacts((contacts) => [...contacts, contactSnap]);
        });

        // -------------------------------------
        /* const querySnapshot1 = await getDocs(
            query(
                collection(db, `organisations/${orgID}/crm/${orgID}/populations/${populationID}/users`),
                orderBy('addedAt', 'desc'),
                endBefore(lastVisible),
                limitToLast(PAGE_SIZE)
            )
        );
        setContacts([]);
        querySnapshot1.forEach(async (d) => {
            setContacts((contacts) => [...contacts, d]);
        }); */
    };


    React.useEffect(() => {
        (async () => {
            setContacts([]);
            try {
                setLoading(true);
                if (populationID) {
                    const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

                    const docSnap = await getDoc(docRef0);
                    /* if (docSnap.exists()) {
                      
                    } */
                    setOrgID(docSnap.data().idOrg)
                    const popRef0 = doc(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/populations`, `${populationID}`);

                    const popSnap = await getDoc(popRef0);
                    console.log('-----------------------------------')
                    if (popSnap.exists()) {
                        console.log('1-----------------------------------')
                        console.log(popSnap.data())
                        setPopulation(popSnap)
                        const snapshotCount = await getCountFromServer(query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/populations/${populationID}/users`), orderBy('addedAt', 'desc')));
                        setCount(snapshotCount.data().count);
                        // get participants
                        const first = query(
                            collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/populations/${populationID}/users`),
                            orderBy('addedAt', 'desc'),

                            limit(PAGE_SIZE)
                        );
                        const snapshot = await getDocs(first);
                        setuserPop(snapshot.docs)
                        snapshot.forEach(async (d) => {
                            const contactDoc = doc(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`, `${d.id}`);
                            const contactSnap = await getDoc(contactDoc);
                            setContacts((contacts) => [...contacts, contactSnap]);
                        });
                    }


                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [refreh, user, populationID]);

    const handleModif = async (index) => {
        setType(6);
        setOpenDrawer(!openDrawer);
    };

    const showContact = async (index) => {
        try {
          console.log(contacts[index].data())
          const docRef0 = doc(db, 'users', `${contacts[index].data().uid}`);
          const docSnap = await getDoc(docRef0);
          if (docSnap.exists()) {
            setCurent(docSnap?.data());
          } else {
            console.log('client n existe pas')
            console.log(contacts[index].data())
            setCurent(contacts[index].data());
            // setIDContact(contacts[index].id)
          }
    
        } catch (error) {
          console.log(index)
          console.log('client n existe pas 1')
          console.log(error)
          console.log(index)
          setCurent(contacts[index].data());
          setTmp(index)
          setType(1);
          // setIDContact(contacts[index].id)
        }
        console.log(index)
        setTmp(index)
        setType(1);
        
        setOpenDrawer(!openDrawer);
      };

    /* const handleTimestamp = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        return tmp[0];
    }; */
    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return date;
    };

    return (
        <Page title="Population">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 0 } }}>
                <CustomButton type='secondaire' buttonText='Retour' onClick={() => { navigate(`/marketing/populations`) }} />
                <Stack direction='row' spacing={1.5} mb={5} mt={1}>
                    <Box style={{ backgroundColor: '#F5EFFA', borderRadius: '7px', width: 85, height: 85, border: '1px solid gainsboro', fontSize: "1.5rem", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {population?.data().emoji}
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            {population?.data().name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {population?.data().description !== null ? population?.data().description : '-'}
                        </Typography>
                        <Stack direction={'row'} spacing={1}>
                            <Typography variant='caption'>
                                derniere mise a le
                                {population?.data().createdAt ? format(new Date(handleTimestamp(population?.data().createdAt)), ' eee dd MMM yyyy HH:mm', {
                                    locale: fr,
                                }).toString() : '-'}
                            </Typography>
                        </Stack>

                    </Box>
                </Stack>

                {
                    population?.data().type === 0 ?
                        <Stack spacing={1} mb={1}>
                            <Stack direction='row' alignItems='center'>
                                <Typography variant='subtitle1'>Filtres</Typography>
                                <CustomButton type='tertiaire' buttonText='editer' onClick={handleModif} />

                            </Stack>
                            <Stack direction='row' useFlexGap flexWrap="wrap" rowGap={1} spacing={2} padding={2} sx={{ border: '2px solid #f2f2f2', borderRadius: '7px' }}>
                                {
                                    population?.data().filterEvent != null ?
                                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                            <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                            <Typography variant='caption'>Participations de:</Typography>
                                            <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                        </Stack>
                                        : null
                                }
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Participations de:</Typography>
                                    <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Non participations de:</Typography>
                                    <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Nombre total d'event:</Typography>
                                    <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Total dépensé:</Typography>
                                    <Typography variant='caption'><strong> 1000 CFA et 2000 CFA</strong></Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Dernier achat:</Typography>
                                    <Typography variant='caption'><strong> 1000 CFA et 2000 CFA</strong></Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Abonné a ma page:</Typography>
                                    <Typography variant='caption'>true</Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Abonné aux notif:</Typography>
                                    <Typography variant='caption'>true</Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Abonné aux newsletters:</Typography>
                                    <Typography variant='caption'>true</Typography>
                                </Stack>
                                <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                    <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                    <Typography variant='caption'>Date d'ajout:</Typography>
                                    <Typography variant='caption'>true</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        : null
                }



                <Grid container spacing={2} paddingBottom={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} sm={4} md={6}>
                        {loading ? (
                            <Skeleton width="20%">
                                <Typography>.</Typography>
                            </Skeleton>
                        ) : (
                            <Typography variant="subtitle1">{count} contacts</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>

                        <Stack direction='row' spacing={2}>
                            <TextField InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify icon="iconoir:search" />
                                    </InputAdornment>
                                ),
                            }} placeholder="Rechercher" fullWidth />
                        </Stack>
                    </Grid>
                </Grid>
                <Box>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                                    <TableRow>
                                        <TableCell sx={{
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 100,
                                            whiteSpace: 'nowrap',
                                            bgcolor: '#f2f2f2'
                                        }}>Nom</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Age</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Dernier achat</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Total dépensé</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Nombre total d'event</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Abonné</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Abonné Notif push</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Abonné Newsletters</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Pays</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Ville</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Téléphone</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap', }}>Date d'ajout</TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : contacts.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="caption">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {contacts.map((contact, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    sx={{
                                                        position: 'sticky',
                                                        left: 0,
                                                        zIndex: 100,
                                                        bgcolor: 'white'
                                                    }}
                                                    id={contact.data().nom}
                                                    onClick={(e) => showContact(index)}
                                                    key={contact.data().nom}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Stack>
                                                        <Stack direction="row">
                                                            {contact.data().nom} {contact.data().prenom}
                                                        </Stack>
                                                        {contact.id}
                                                        <Typography variant='caption'>
                                                            derniere mise a le
                                                            {contact.data().createDate ? format(new Date(handleTimestamp(contact.data().createDate)), ' eee dd MMM yyyy', {
                                                                locale: fr,
                                                            }).toString() : '-'}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', }} id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    {contact.data().age ? <CustomTag text={`${contact.data().age}`} /> : '-'}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', }} id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                <Typography variant='caption'>
                                                    {contact.data().lastOrderDate ? format(new Date(handleTimestamp(contact.data().lastOrderDate)), 'eee dd MMM yyyy', {
                                                        locale: fr,
                                                    }).toString() : '-'}
                                                </Typography>
                                                </TableCell>
                                                
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    {contact.data().depenses ? contact.data().depenses : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    {contact.data().pays ? contact.data().pays : '-'}
                                                </TableCell>

                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    <CustomTag text={`${contact.data().push || contact.data().newslettres ? 'Oui' : 'Non'}`} type={contact.data().push || contact.data().newslettres ? 'success' : 'error'} />
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    <CustomTag text={`${contact.data().push ? 'Oui' : 'Non'}`} type={contact.data().push ? 'success' : 'error'} />
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    <CustomTag text={`${contact.data().newslettres ? 'Oui' : 'Non'}`} type={contact.data().newslettres ? 'success' : 'error'} />
                                                </TableCell>

                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    {contact.data().pays ? contact.data().pays : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    {contact.data().ville ? contact.data().ville : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    {contact.data().phone ? contact.data().phone : '-'}
                                                </TableCell>
                                                <TableCell id={contact.data().nom} onClick={(e) => showContact(index)}>
                                                    <Typography variant='caption'>
                                                        {contact.data().createDate ? format(new Date(handleTimestamp(contact.data().createDate)), 'eee dd MMM yyyy', {
                                                            locale: fr,
                                                        }).toString() : '-'}
                                                    </Typography>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={count}
                            rowsPerPage={PAGE_SIZE}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Scrollbar>
                </Box>

                {
                    type === 1 && <Swipeable type={type} user={curent} contact={contacts[tmp]} organisation={orgID} />
                }
                
            </Container>
            {
                type === 6 && <Swipeable type={type} organisation={orgID} popID={populationID} />
            }
            
        </Page>
    );
}

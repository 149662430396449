/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton } from '@mui/material';
import { Variables } from '../../../context/VariableContext';
import ContactDrawer from './drawer/contactDrawer';
import Iconify from '../../../components/Iconify';
import FilterDrawer from './drawer/filters';
import ImportationDrawer from './drawer/importationsContact';
import FilterCommDrawer from './drawer/filtersCommandes';
import ImportationOrdersDrawer from './drawer/importationsOrders';
import CreatePopulation from './drawer/population/createPopulation';
import CreateSeance from './drawer/seances/createSeance';

export default function Swipeable({ user, contact, organisation, popID, eventID, seanceID, seanceData, date }) {
  const { openDrawer, setOpenDrawer } = Variables();
  const { type, setType } = Variables();
  const [isOpen, setIsOpen] = React.useState(false);


  const toggleDrawer = (open) => {
    setIsOpen(open);
  };



  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
      role="presentation"
      onClick={(e) => {
        toggleDrawer(false);
      }}
      onKeyDown={(e) => {
        toggleDrawer(false);
      }}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const [isMounted, setIsMounted] = React.useState(false);

  const handleClose = () => {
    toggleDrawer(false); // Ferme le Drawer
    setTimeout(() => {
      setIsMounted(false); // Démonte le composant après l'animation
    }, 300); // La durée doit correspondre à l'animation du Drawer (300ms par défaut dans Material-UI)
  };

  const handleOpen = () => {
    setIsMounted(true); // Monte le composant immédiatement
    // Ouvre le Drawer
    setTimeout(() => {
      toggleDrawer(true);  // Démonte le composant après l'animation
    }, 1);
  };

  React.useEffect(() => {

    try {
      if (openDrawer) {
        handleOpen()
      } else {
        handleClose()
      }
    } catch (error) {
      console.log('error');
    }

  }, [openDrawer]);

  return (
    <Box sx={{ mt: 5, width: 500 }}>
      {
        isMounted &&
        <SwipeableDrawer
          anchor="right"
          open={isOpen}
          onClose={() => {
            toggleDrawer(true);
          }}
          onOpen={() => {
            toggleDrawer(true);
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: 550 } }}>
            <Box paddingX={2} paddingY={{ xs: 1, sm: 1.5 }}>
              <IconButton
                onClick={() => {
                  setOpenDrawer(false);
                }}
              >
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Box>
            <Divider />
            {
              type === 1 && user ? <ContactDrawer user={user} contact={contact} /> : type === 2 ? <FilterDrawer orgID={organisation} /> : type === 3 ? <ImportationDrawer orgID={organisation}/> : type === 4 ? <FilterCommDrawer /> : type === 5 ? <ImportationOrdersDrawer /> : type === 6 ? <CreatePopulation orgID={organisation} populationID={popID} /> : type === 7 ? <CreateSeance eventID={eventID} seanceID={seanceID} seanceData={seanceData} date={date} /> : ''
            }

          </Box>
        </SwipeableDrawer>
      }

    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    Typography,
    Box,
    TextField,
    Stack,
    Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import { db } from "../../../../../firebase.config";
import Page from "../../../../../components/Page";
import Iconify from "../../../../../components/Iconify";
import { useSnackbar } from "../../../../../context/snackbarContext";
import { UserAuth } from "../../../../../context/AuthContext";

const dependencyMap = {
    // Création d'événement
    "create_event": "manage_event",
    "edit_event": "manage_event",
    "delete_event": "manage_event",
    "status_event": "manage_event",
    
    // Gestion des tickets
    "create_tickets": "manage_tickets",
    "edit_tickets": "manage_tickets",
    "delete_tickets": "manage_tickets",
    
    // Gestion des séances
    "create_seance": "manage_seance",
    "edit_seance": "manage_seance",
    "delete_seance": "manage_seance",
    
    // Gestion des codes de promotion
    "create_promocode": "manage_promocode",
    "edit_promocode": "manage_promocode",
    "delete_promocode": "manage_promocode",
    
    // Gestion des affiliations
    "create_affiliate": "manage_affiliate",
    "edit_affiliate": "manage_affiliate",
    "delete_affiliate": "manage_affiliate",
    
    // Gestion des widgets
    "edit_widget": "manage_widget",
    
    // Gestion des participants
    "add_participant": "manage_participant",
    "refund_participant": "manage_participant",
    
    // Gestion des opérateurs de scan
    "add_ops": "manage_ops",
    "edit_ops": "manage_ops",
    "delete_ops": "manage_ops",
    
    // Gestion des finances
    "see_balance": "manage_finance",
    "see_paiement_summary": "manage_finance",
    "manage_paiements_info": "manage_finance",
    "update_paiements_info": "manage_finance",
    
    // Gestion des utilisateurs et des rôles
    
    
    // Gestion du marketing
    "manage_campaigns": "manage_marketing",
    "manage_paid_campaigns": "manage_marketing",
    "manage_contacts": "manage_marketing",
    "manage_populations": "manage_marketing",
    "manage_tracking_links": "manage_marketing",
    "add_tracking_links": "manage_marketing",
    "delete_tracking_links": "manage_marketing",
    "sell_on_facebook": "manage_marketing",
    "settings": "manage_marketing",
    
    // Gestion de l'organisation
    "update_org": "manage_org",
    "manage_team": "manage_org",
    "manage_users": "manage_org",
    "manage_roles": "manage_org",
    "add_roles": "manage_roles",
    "add_users": "manage_users",
    "remove_users": "manage_users",
    "update_users": "manage_users",
    "edit_roles": "manage_roles",
    "remove_roles": "manage_roles",   
};

const firstSelectionMap = {
    "Création d'événement": "manage_event",
    "Gestions des finances": "manage_finance",
    "Marketing": "manage_marketing",
    "Gestion d'organisation": "manage_org",
};

const permissionsData = [
    {
        category: "Création d'événement",
        description:
            "Permettez aux utilisateurs de saisir les informations relatives à l'événement, de créer des billets et de personnaliser les formulaires de commande.",
        permissions: [
            { key: "manage_event", label: "Gestion des événements" },
            { key: "create_event", label: "Créer un événement" },
            { key: "edit_event", label: "Modifier un événement" },
            { key: "delete_event", label: "Supprimer un événement" },
            { key: "status_event", label: "Changer le statut événement" },
            { key: "manage_tickets", label: "Gestion des tickets" },
            { key: "create_tickets", label: "Ajouter des tickets" },
            { key: "edit_tickets", label: "Modifier des tickets" },
            { key: "delete_tickets", label: "Supprimer des tickets" },
            { key: "manage_seance", label: "Gestion des seances" },
            { key: "create_seance", label: "Créer des seances" },
            { key: "edit_seance", label: "Modifier des seances" },
            { key: "delete_seance", label: "Supprimer des seances" },
            { key: "manage_promocode", label: "Gestion des codes de promotion" },
            { key: "create_promocode", label: "Créer des codes de promotion" },
            { key: "edit_promocode", label: "Modifier des codes de promotion" },
            { key: "delete_promocode", label: "Supprimer des codes de promotion" },
            { key: "manage_affiliate", label: "Gestion des affiliations" },
            { key: "create_affiliate", label: "Créer des affiliations" },
            { key: "edit_affiliate", label: "Modifier des affiliations" },
            { key: "delete_affiliate", label: "Supprimer des affiliations" },
            { key: "manage_widget", label: "Gestion des widgets" },
            { key: "edit_widget", label: "Modifier des widgets" },
            { key: "manage_participant", label: "Gestion des participants" },
            { key: "add_participant", label: "Ajouter des participants" },
            { key: "refund_participant", label: "Rembourser des participants" },
            { key: "manage_ops", label: "Gestion des opérateurs scan" },
            { key: "add_ops", label: "Ajouter des opérateurs scan" },
            { key: "edit_ops", label: "Modifier des opérateurs scan" },
            { key: "delete_ops", label: "Supprimer des opérateurs scan" },
            { key: "show_stat", label: "Accéder au stats de vente" },
        ],
    },
    {
        category: "Gestions des finances",
        description: "Permettez aux utilisateurs de gérer les commandes.",
        permissions: [
            { key: "manage_finance", label: "Gestion des finances" },
            { key: "see_balance", label: "Gestion des finances" },
            { key: "see_paiement_summary", label: "Voir l'historique de paiement" },
            { key: "manage_paiements_info", label: "Gestion des informations de paiement" },
            { key: "update_paiements_info", label: "Modifier les informations de paiement" },
        ],
    },
    {
        category: "Marketing",
        description:
            "Vendez plus de billets en créant des campagnes, en suivant l'engagement grâce aux analyses et en vendant sur Facebook.",
        permissions: [
            { key: "manage_marketing", label: "Gestion marketing" },
            { key: "manage_campaigns", label: "Gestion des campagnes email et push" },
            { key: "manage_paid_campaigns", label: "Gestion des camapgnes facebook" },
            { key: "manage_contacts", label: "Gestion des contacts" },
            { key: "manage_populations", label: "Gestion des populations" },
            { key: "manage_tracking_links", label: "Gestion des liens de suivi" },
            { key: "add_tracking_links", label: "Ajouter des liens de suivi" },
            { key: "delete_tracking_links", label: "Supprimer des liens de suivi" },
            { key: "sell_on_facebook", label: "Vendre sur facebook" },
            { key: "settings", label: "Acces aux paramètres" },
        ],
    },
    {
        category: "Gestion d'organisation",
        description:
            "Invitez vos collaborateurs à vous rejoindre sur Choptonticket",
        permissions: [
            { key: "manage_org", label: "Gestion de l'organistion" },
            { key: "update_org", label: "mettre a jour les informations de l'organisation" },
            { key: "manage_team", label: "Gestion de l'équipe" },
            { key: "manage_users", label: "Gestion des utilisateurs" },
            { key: "add_users", label: "Inviter des utilisateurs" },
            { key: "remove_users", label: "Retirer des utilisateurs" },
            { key: "update_users", label: "Mettre à jour les utilisateurs" },
            { key: "manage_roles", label: "Gestion des rôles" },
            { key: "add_roles", label: "Ajouter des rôles" },
            { key: "edit_roles", label: "Modifier des rôles" },
            { key: "remove_roles", label: "Supprimer des rôles" },
        ],
    },
];

const RoleInterface = () => {
    const { user, userData } = UserAuth()
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [orgId, setOrgId] = useState();
    const showSnackbar = useSnackbar()
    const { roleId } = useParams()
    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [originalPermissions, setOriginalPermissions] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    const [disabledPermissions, setDisabledPermissions] = useState({});


    const updateDisabledPermissions = (updatedPermissions) => {
        const newDisabledPermissions = {};

        Object.entries(dependencyMap).forEach(([child, parent]) => {
            let length = 0;
            // eslint-disable-next-line consistent-return, array-callback-return
            const parentCategory = Object.keys(updatedPermissions).find((cat) => {
                if (updatedPermissions[cat]?.includes(parent)) {
                    length = updatedPermissions[cat]?.length
                    return updatedPermissions[cat]
                }
            }
            );
            console.log(length)
            if (parentCategory && length > 2) {
                newDisabledPermissions[parent] = true;
            } else {
                newDisabledPermissions[parent] = false;
            }
        });
        Object.entries(firstSelectionMap).forEach(([child, parent]) => {
            let length = 0;
            // eslint-disable-next-line consistent-return, array-callback-return
            const parentCategory = Object.keys(updatedPermissions).find((cat) => {
                if (updatedPermissions[cat]?.includes(parent)) {
                    length = updatedPermissions[cat]?.length
                    return updatedPermissions[cat]
                }
            }
            );
            if (parentCategory && length > 1) {
                newDisabledPermissions[parent] = true;
            } else {
                newDisabledPermissions[parent] = false;
            }
        });
        console.log(newDisabledPermissions)

        setDisabledPermissions(newDisabledPermissions);
    };

    const handleTogglePermission = (category, permission) => {
        setSelectedPermissions((prev) => {
            const updatedPermissions = { ...prev };
            if (!updatedPermissions[category]) updatedPermissions[category] = [];

            if (updatedPermissions[category].includes(permission)) {
                console.log('Permission included')
                updatedPermissions[category] = updatedPermissions[category].filter(
                    (perm) => perm !== permission
                );

                Object.entries(dependencyMap).forEach(([child, parent]) => {
                    if (child === permission) {
                        const parentCategory = Object.keys(updatedPermissions).find(cat =>
                            updatedPermissions[cat]?.includes(parent)
                        );

                        if (parentCategory) {
                            updatedPermissions[parentCategory] = updatedPermissions[parentCategory].filter(
                                (perm) => perm !== parent
                            );
                        }

                    }
                });

                // Vérifier si c'était la dernière permission cochée dans cette catégorie
                if (updatedPermissions[category].length === 0) {
                    updatedPermissions[category] = updatedPermissions[category].filter(
                        (perm) => perm !== firstSelectionMap[category]
                    );
                }
            } else {
                console.log('permission not inclued')
                updatedPermissions[category].push(permission);

                if (dependencyMap[permission]) {
                    const parentPerm = dependencyMap[permission];
                    console.log(parentPerm)
                    if (!updatedPermissions[category].includes(parentPerm)) {
                        updatedPermissions[category].push(parentPerm);
                    }
                }
                console.log(category)
                if (firstSelectionMap[category]) {
                    const firstPerm = firstSelectionMap[category];
                    console.log(firstPerm)
                    console.log(updatedPermissions[category])
                    if (!updatedPermissions[category].includes(firstPerm)) {
                        console.log('salut')
                        updatedPermissions[category].push(firstPerm);
                    }
                }
            }
            updateDisabledPermissions(updatedPermissions);
            return { ...updatedPermissions };
        });
    };
    const savePermissions = async () => {
        if (!orgId || !name.trim()) {
            showSnackbar('Veuillez saisir un nom pour votre role', true)
            return; // Éviter de créer un rôle sans nom
        }
        if (!orgId) {
            showSnackbar("Une erreur s'est produire lors de la creation de votre role", true)
            return; // Éviter de créer un rôle sans nom
        }
        try {
            if (roleId) {
                // Modifier un rôle existant
                const roleRef = doc(db, `organisations/${orgId}/roles`, roleId);
                await setDoc(roleRef, { name, permissions: selectedPermissions, updateBy: user.uid }, { merge: true });
            } else {
                // Créer un nouveau rôle
                const newRoleRef = collection(db, `organisations/${orgId}/roles`);
                // const newRoleRef = collection(db, `admins/basedRole/roles`);
                await addDoc(newRoleRef, { name, permissions: selectedPermissions, createBy: user.uid });
            }
            setOriginalPermissions(selectedPermissions);
            showSnackbar("Votre role a été créé avec succès", false)
            navigate('/organisation/team/roles', { replace: true })
        } catch (error) {
            showSnackbar("Une erreur s'est produire lors de la creation de votre role", true)
            console.log(error)
        }


    };

    const cancelChanges = () => {
        setSelectedPermissions(originalPermissions);
        setName(roleId ? name : ""); // Restaurer le nom du rôle ou le vider
    };
    const [expandedSections, setExpandedSections] = useState(
        permissionsData.map(section => section.category) // Tous les accordéons ouverts
    );

    const toggleAccordion = (category) => {
        setExpandedSections((prev) =>
            prev.includes(category) ? prev.filter(item => item !== category) : [...prev, category]
        );
    };

    useEffect(() => {
        const fetchOrgId = async () => {
            console.log('--------------------------------')
            console.log(user.uid)
            setOrgId(userData.idOrg)
        };

        fetchOrgId();

    }, [user, userData]);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (!orgId || !roleId) return;
            const roleRef = doc(db, `organisations/${orgId}/roles`, roleId);
            const roleSnap = await getDoc(roleRef);
            if (roleSnap.exists()) {
                console.log("Données du rôle récupérées :", roleSnap.data());
                const roleData = roleSnap.data();
                setSelectedPermissions(roleData.permissions || {});  // Stocke correctement les permissions
                setOriginalPermissions(roleData.permissions || {});
                updateDisabledPermissions(roleData.permissions)
                setName(roleData.name || "");
            }
        };

        if (orgId && roleId) {
            fetchPermissions();
        }
    }, [orgId, roleId]); // S'assurer que orgId et roleId sont bien définis avant de récupérer les permissions


    return (
        <Page title="Ajout de role">
            <Container>
                <Stack
                    alignItems='start'
                    mb={3}
                    spacing={1}

                >
                    <CustomButton
                        type='secondaire'
                        buttonText='Retour'
                        startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                        onClick={() => window.history.back()}
                    />
                    <Typography variant="h3" gutterBottom>
                        Ajouter un nouveau role <br />
                    </Typography>
                </Stack>
                <Stack spacing={2} padding={2}>
                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        placeholder="Nom du rôle"
                    />
                    <TextField
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        placeholder="Rechercher une permission"
                        sx={{ mb: 2 }}
                    />
                    {permissionsData.map((section) => {
                        const filteredPermissions = section.permissions.filter(perm =>
                            perm.label.toLowerCase().includes(searchQuery.toLowerCase())
                        );

                        if (filteredPermissions.length === 0) return null;

                        return (
                            <Accordion
                                key={section.category}
                                expanded={expandedSections.includes(section.category)} // Ouvrir l'accordéon
                                onChange={() => toggleAccordion(section.category)}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1">{section.category}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start' // Alignement des checkboxes
                                }}>
                                    <Typography variant="caption" sx={{ mb: 1 }}>
                                        {section.description}
                                    </Typography>

                                    {filteredPermissions.map((perm) => (

                                        <FormControlLabel
                                            key={perm.key}
                                            control={<Checkbox checked={selectedPermissions[section.category]?.includes(perm.key) || false} onChange={() => handleTogglePermission(section.category, perm.key)} disabled={disabledPermissions[perm.key]} />}
                                            label={perm.label}
                                            sx={{ width: '100%' }}
                                        />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Stack>
            </Container>

            <Container>
                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        // borderTop: '2px solid gainsboro',
                        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                    }}
                >
                    <CustomButton type="secondaire" buttonText="Annuler" onClick={cancelChanges} />
                    <CustomButton type="primaire" buttonText={roleId ? "Modifier" : "Ajouter"} onClick={savePermissions} />
                </Stack>
            </Container>
        </Page>

    );
};

export default RoleInterface;

import { useEffect, useState } from 'react';
import {
    Menu,
    MenuItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Avatar,
    Typography,
    Box,
    Button
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { collection, getDocs } from 'firebase/firestore';


export default function ElementSwitcher({ accounts = [], onSelect, defaut }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(accounts[defaut] || null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (account, index) => {
        setSelectedAccount(account);
        // eslint-disable-next-line no-unused-expressions
        onSelect && onSelect(account, index);
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
            >
                {
                    selectedAccount?.picture && <Avatar
                        src={selectedAccount?.picture?.data?.url}
                        sx={{ width: 28, height: 28, mr: 1 }}
                    />
                }
                <Typography variant='body2'>{selectedAccount?.name || 'Sélectionner une équipe'}</Typography>
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {accounts.map((account, index) => (
                    <MenuItem
                        key={account.id}
                        selected={selectedAccount?.id === account.id}
                        onClick={() => handleSelect(account, index)}
                    >
                        {
                            account?.picture && <ListItemIcon>
                                <Avatar src={account?.picture?.data?.url} sx={{ width: 24, height: 24 }} />
                            </ListItemIcon>
                        }
                        <ListItemText primary={account.name} />
                        {selectedAccount?.id === account.id && <CheckIcon fontSize="small" />}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

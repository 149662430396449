import React, { useState } from 'react';
import { TextField, MenuItem, Select, Grid, InputLabel, FormControl, useMediaQuery, Box, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker, } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Iconify from './Iconify';


const CustomSeanceDateTimePicker = ({ date, debutTime, endTime, onDateChange, onDebutTimeChange, onEndTimeChange, minDate, errorDate, errorHour, disabled}) => {
    const [selectedDate, setSelectedDate] = useState(date);
    const [d, setDisable] = useState(disabled);
    const [selectedDebutTime, setSelectedDebutTime] = useState(debutTime);
    const [selectedEndTime, setSelectedEndTime] = useState(endTime);
    const [error, setError] = useState(false);
    const [duration, setDuration] = useState(null); // 🔹 Stockage de la durée
    const today = new Date();
    const minSelectableDate = minDate ? new Date(minDate) : today;

    // Générer les options pour l'heure et les minutes combinées (00:00 - 23:45 par intervalles de 15 minutes)
    const times = [];
    // eslint-disable-next-line no-plusplus
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const hourStr = String(hour).padStart(2, '0');
            const minuteStr = String(minute).padStart(2, '0');
            times.push(`${hourStr}:${minuteStr}`);
        }
    }

    const isMobile = useMediaQuery('(max-width:600px)');
    const menuHeight = isMobile ? '200px' : '300px';

    // 🔹 Fonction pour calculer la durée entre l'heure de début et l'heure de fin
    const calculateDuration = (startTime, endTime) => {
        if (!startTime) return null;

        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        let durationInMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);
        if (durationInMinutes < 0) durationInMinutes += 24 * 60; // Gestion du dépassement de minuit

        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        return `${hours > 0 ? `${hours }h` : ''}${minutes > 0 ? `${minutes  }m` : ''}`;
    };

    // Gérer le changement de date
    const handleDateChange = (newValue) => {
        
        setSelectedDate(newValue);
        onDateChange(newValue);
    };

    // Gérer le changement de temps
    const handleDebutTimeChange = (event) => {
        console.log('enfant')
        const newValue = event.target.value;
        setSelectedDebutTime(newValue);
        onDebutTimeChange(newValue);
    };
    // Gérer le changement de temps
    const handleEndTimeChange = (event) => {
        console.log('enfant')
        const newValue = event.target.value;
        setSelectedEndTime(newValue);
        onEndTimeChange(newValue);
    };

    React.useEffect(() => {
            (async () => {
                try {
                    console.log(debutTime)
                } catch (error) {
                    console.log('error');
                }
            })();
        }, [date]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <Grid container spacing={3}>
                {/* Sélection de la date */}
                <Grid item xs={12} md={5}>
                    <DatePicker
                        disabled={d}
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={minSelectableDate}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Date'
                                fullWidth
                                error={errorDate}
                                placeholder="Choisissez une date" // Placeholder pour le champ de date
                                InputProps={{
                                    ...params.InputProps,
                                    // Utilisation d'un placeholder via `inputProps` si nécessaire
                                    'aria-placeholder': 'Choisissez une date'
                                }}
                            />
                        )}
                    />
                </Grid>

                {/* Sélection de l'heure */}
                <Grid item xs={6} md={3.5}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">heure de debut</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="heure de debut"
                            value={selectedDebutTime}
                            error={errorDate}
                            disabled={d}
                            onChange={handleDebutTimeChange}
                            IconComponent={() => <Box paddingRight={2}><Iconify icon='mdi:clock-outline' sx={{ height: 20, width: 20 }} /></Box>}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: menuHeight, // Définir la hauteur max du menu
                                        // Correspond à la largeur du champ
                                    },
                                },
                            }}
                        >
                            <MenuItem value='' disabled>
                                Choisisser une heure
                            </MenuItem>
                            {times.map((time) => (
                                <MenuItem key={time} value={time}>
                                    {time}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={3.5}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">heure de fin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="heure de fin"
                            value={selectedEndTime}
                            error={errorDate}
                            disabled={d}
                            onChange={handleEndTimeChange}
                            IconComponent={() => <Box paddingRight={2}><Iconify icon='mdi:clock-outline' sx={{ height: 20, width: 20 }} /></Box>}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: menuHeight, // Définir la hauteur max du menu
                                        // Correspond à la largeur du champ
                                    },
                                },
                            }}
                        >
                            <MenuItem value='' disabled>
                                Choisisser une heure
                            </MenuItem>
                            {times.map((time) => (
                                <MenuItem key={time} value={time}>
                                    {time } <Typography variant='caption'> {calculateDuration(selectedDebutTime, time) !== '' ? `(${calculateDuration(selectedDebutTime, time)})`: null}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default CustomSeanceDateTimePicker;
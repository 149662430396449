/* eslint-disable no-nested-ternary */
import React from 'react';
import { useParams } from 'react-router-dom';
// material
import {
  Container,
} from '@mui/material';
// components
import Page from '../../../components/Page';

import ViewPopulation from './population/viewPopulation';
import PopulationsList from './population/populationsList';
import withAuthorization from '../../../withAuthorization';

// ---------------------------------------------------------------------


function PopulationCrm() {
  const { populationID } = useParams();

  

  // loading
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (populationID) {
          setView(true)
        } else {
          setView(false)
        }
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [populationID]);



  return (
    <Page title="Population">
      <Container sx={{ marginTop: { xs: 0 }}}>
        {
          !loading &&
          <>
          {
            view ?
            <ViewPopulation />
            :
            <PopulationsList />
          }
          </>
        }
      </Container>
    </Page>
  );
}

export default withAuthorization(PopulationCrm, "manage_populations")

import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,

  Stack,

  Button,

  Container,
  Typography,

  Grid,

  Box,
  Modal,
  TextField,

  MenuItem,

  IconButton,
  Backdrop,
  CircularProgress,
  Menu,
  Divider,
  styled,
  alpha,
  LinearProgress,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import CustomButton from '../../../components/CustomButton';
import PaidCampaign from './campaing/dashbord/paidCampaign';

// ----------------------------------------------------------------------
// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  '@media (max-width: 600px)': {
    width: '90%', // Redimensionne sur mobile
  },
};
// ----------------------------------------------------------------------




export default function CampaignCrm() {
  const { eventID } = useParams();
  const { user } = UserAuth();
  const { pathname } = useLocation();




  // for change value of globla quota
  const [open, setOpen] = React.useState(false);

  const [refreh, setRefresh] = React.useState(false);



  // take operateurs from firebase
  const navigate = useNavigate();
  const [campaigns, setCampaign] = React.useState([]);
  const [orgID, setOrgId] = React.useState();

  React.useEffect(() => {
    (async () => {
      setCampaign([]);
      try {
        setLoading(true);

        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

        const docSnap = await getDoc(docRef0);
        /* if (docSnap.exists()) {
          
        } */
        setOrgId(docSnap.data().idOrg)
        const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/campagnes`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          const docRefCampaign = doc(db, 'campagnes', `${d.id}`);
          const docSnapCampaign = await getDoc(docRefCampaign);
          if (docSnapCampaign.exists()) {
            setCampaign((campaigns) => [...campaigns, { id: d.id, data: docSnapCampaign.data() }]);
          }

        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refreh, user]);

  // loading
  const [loading, setLoading] = React.useState(false);


  const handleModif = (index) => {
    navigate(`${campaigns[index].id}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [campagneType, setCampagneType] = React.useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {

    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSubmit = async (e) => { };

  const [openRecetteModal, setOpenRecetteModal] = React.useState(false);
  const [nom, setNom] = React.useState();
  const handleOpenRecetteModal = async () => {
    setOpenRecetteModal(true);

  };
  const handleCreateCampaign = async () => {
    setLoading(true)
    try {

      const campaign = await addDoc(collection(db, `campagnes`), {
        name: nom,
        organisationId: orgID,
        dateCreation: new Date(),
        status: 'brouillon'
      });

      await setDoc(doc(db, `organisations/${orgID}/crm/${orgID}/campagnes`, `${campaign.id}`), {
        id: campaign.id,
      });

      navigate(`${campaign.id}`);
      console.log(campaign.id)
    } catch (error) {
      console.log(error)
    }
    setNom()
    setLoading(false)
    setOpenRecetteModal(false);
    setRefresh(!refreh)
  };

  const deleteCampaign = async (index) => {
    setLoading(true)
    try {
      await deleteDoc(doc(db, `organisations/${orgID}/crm/${orgID}/campagnes`, `${campaigns[index].id}`));
      await deleteDoc(doc(db, `campagnes`, `${campaigns[index].id}`));
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    setRefresh(!refreh)
  };
  const handleCloseRecetteModal = () => setOpenRecetteModal(false);

  React.useEffect(() => {
    (async () => {
      try {
        const tmp = pathname.split('/')
        console.log(tmp)
        if (tmp[tmp.length - 1] === 'campaigns') {
          // navigate(`/marketing/campaigns`, { replace: true });
          setCampagneType(0)
        } else if (tmp[tmp.length - 1] === 'ads') {
         
          setCampagneType(1)
        }
      } catch (error) {
        console.log('error');
      }

    })();
  }, []);

  return (
    <Page title="Campagne">
      <Box>
        <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container sx={{ marginTop: { xs: 0 }, }}>
          <CheckCard />
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={2} >
            <Typography variant="h3" gutterBottom>
              Campagnes marketing
            </Typography>
          </Stack>
          <Stack my={2} direction='row' spacing={1}>
            <Box onClick={() => {
              setCampagneType(0) 
              navigate(`/marketing/campaigns`);
            }} sx={campagneType === 0 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
              :
              { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
              <Typography variant='body2'>campagne mail</Typography>
            </Box>
            <Box onClick={() => {
              setCampagneType(1)
              navigate(`/marketing/ads`);
            }} sx={campagneType === 1 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
              :
              { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
              <Typography variant='body2'>Ads facebook</Typography>
            </Box>
          </Stack>

          {
            campagneType === 0 ?
              <Box>
                <Typography variant='h5'>
                  Interagissez avec votre public par le biais d'e-mail personnalisées et des annonces d'événements
                </Typography>
                <Typography variant='body2'>
                  Envoyez des invitations à tout votre public, des invitations à des événements aux anciens participants et réengagez les abonnés avec des campagnes de messagerie personnalisables.
                </Typography>

                <Stack marginTop={2} direction='row' sx={{ bgcolor: '#F5EFFA', borderRadius: '10px' }} padding={3}>
                  <Stack>
                    <Typography variant='h5'>0/3000 par mois</Typography>
                    <LinearProgress variant="buffer" value={0} />
                  </Stack>
                </Stack>


                <Grid container spacing={2} paddingTop={10} paddingBottom={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField placeholder='Rechercher par nom' fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <Stack direction='row' justifyContent='end' sx={{ height: '100%' }}>

                      <CustomButton type='primaire' buttonText='Créer une campagne email' onClick={handleOpenRecetteModal} />
                      <Modal
                        open={openRecetteModal}
                        onClose={handleCloseRecetteModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ borderRadius: '7px' }}
                      >
                        <Box sx={styleModal}>
                          <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='subtitle1'>
                              Nouvelle campagne
                            </Typography>
                          </Box>

                          <Divider />
                          <Stack spacing={3} padding={3} justifyContent='center' alignItems='center'>
                            <Typography variant='body1'> Créer une nouvelle campagne e-mail</Typography>
                            <TextField value={nom} onChange={(e) => setNom(e.target.value)} fullWidth placeholder='Nom de la campagne' />
                          </Stack>

                          <Divider />
                          <Stack direction='row' spacing={2} padding={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                            <CustomButton type='secondaire' buttonText='fermer' onClick={handleCloseRecetteModal} />
                            <CustomButton type='primaire' buttonText='Suivant' onClick={handleCreateCampaign} />
                          </Stack>
                        </Box>
                      </Modal>
                    </Stack>
                  </Grid>
                </Grid>

                {campaigns.map((campaign, index) => (
                  <Card key={index} sx={{ padding: 3, marginY: 3, bgcolor: '#f2f2f2', border: '1px solid #f2f2f2' }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack>
                        <Typography variant='h6'>{campaign.data.name}</Typography>
                        <Typography variant='caption'>créé le 10 aout 2024</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent='space-evenly' alignItems='center' spacing={5}>
                        {
                          campaign?.data?.status === 'brouillon' ?
                            <Box sx={{ bgcolor: '#ADFFD5', borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography fontSize={11} sx={{ color: '#006832' }} variant='caption'>Connecté</Typography>
                            </Box>
                            :
                            <Box sx={{ bgcolor: '#FFD6D6', borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography fontSize={11} sx={{ color: '#720000' }} variant='caption'>Non connecté</Typography>
                            </Box>
                        }

                        <Stack>
                          <Typography variant='body2'>Ouvert</Typography>
                          <Typography><strong>--</strong></Typography>
                        </Stack>
                        <Stack>
                          <Typography variant='body2'>Clique</Typography>
                          <Typography><strong>--</strong></Typography>
                        </Stack>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? 'long-menu' : undefined}
                          aria-expanded={openMenu ? 'true' : undefined}
                          aria-haspopup="true"
                          key={index} onClick={(e) => handleOpenMenu(e, index)}
                          sx={{ border: '1px solid gainsboro' }}
                        >
                          <Iconify icon="material-symbols-light:more-vert" />
                        </IconButton>

                        <StyledMenu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem key={`${index}-1`} onClick={() => {
                            handleCloseMenu();
                            handleModif(selectedIndex); // Utilise l'index sélectionné
                          }} disableRipple value={1}>
                            <Typography variant='body1'>Envoyer</Typography>
                          </MenuItem>
                          <MenuItem key={`${index}-1`} onClick={() => {
                            handleCloseMenu();
                            handleModif(selectedIndex); // Utilise l'index sélectionné
                          }} disableRipple value={2}>
                            <Typography variant='body1'>Modifier</Typography>
                          </MenuItem>
                          <MenuItem key={`${index}-1`} onClick={() => {
                            handleCloseMenu();
                            deleteCampaign(selectedIndex); // Utilise l'index sélectionné
                          }} disableRipple value={3}>
                            <Typography variant='body1'>Supprimer</Typography>
                          </MenuItem>
                        </StyledMenu>

                      </Stack>
                    </Stack>
                  </Card>
                ))}
              </Box>
              :
              <PaidCampaign />
          }


        </Container>
      </Box>

    </Page>
  );
}

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  Box,
  FormControl,
  FormLabel,
} from '@mui/material';
// hooks
// components
// sections
import { useForm, useFrom } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { isUndefined, parseInt } from 'lodash';
import { UserAuth } from '../context/AuthContext';
import Iconify from '../components/Iconify';
import { RHFTextField, FormProvider, RHFCheckbox } from '../components/hook-form';
import AuthSocial from '../sections/auth/AuthSocial';
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/Logo';
import Page from '../components/Page';
import useResponsive from '../hooks/useResponsive';
import { db } from '../firebase.config';
import RHFSelect from '../components/hook-form/RHFSelect';
import CustomNumber from '../components/customNumber';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  // position: "fixed",
  // maxWidth: 464,
  display: 'flex',
  background: 'linear-gradient(217deg, #5516CA, rgba(255,0,0,0) 100.71%), linear-gradient(127deg, #e66465, rgba(0,255,0,0) 60.71%), linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%)',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterOrg() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  // *******************************************************************

  const { user, signUp, signUpSecondStep, userData } = UserAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [statusJur, setStatus] = useState();
  const [num, setNum] = useState();
  const [code, setCode] = useState();
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [implentations, setImplentations] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [certifie, setCert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const RegisterOrgSchema = Yup.object().shape({
    nom: Yup.string().required("Le nom de l'organisation est obliagtoire"),
    ville: Yup.string().required('La ville est obligatoire'),
    pays: Yup.string().required('Le pays est obligatoire '),
    tel: Yup.number().optional(),
    confirmed: Yup.boolean().required('chan'),
  });

  const defaultValues = {
    nom: '',
    ville: '',
    pays: '',
    confirmed: true,
    tel: 0
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    console.log(e.statut);
    console.log(e.nom);
    console.log(e.ville);
    console.log(e.pays);

    try {
      let tmpId
      const docRef0 = doc(db, 'organisateurs', user.uid);
      const docSnap = await getDoc(docRef0);
      console.log(docSnap.data().idorg)
      if (isUndefined(docSnap.data().idOrg)) {
        console.log('l attribut est indefine')
        const orgRef = await addDoc(collection(db, "organisations"), {
          dateCreation: serverTimestamp(),
        });
        tmpId = orgRef.id
        await updateDoc(docRef0, {
          idOrg: tmpId
        });

      } else {
        tmpId = docSnap.data().idOrg
      }

      const statRef = doc(db, `organisations`, `${tmpId}`);
      await updateDoc(statRef, {
        type: parseInt(statusJur),
        nomOrg: e.nom,
        updateDate: serverTimestamp(),
        certified: true,
      });

      // informations sur le contact
      const refCoord = doc(db, `organisations/${tmpId}/coordonnee`, `${tmpId}`);
      await setDoc(refCoord, {
        city: selectedCity,
        country: selectedCountry,
        tel: num,
      });
      navigate('/bo/new_event', { replace: true });
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
    // navigate('/bo', { replace: true });
  };

  const handleCountry = (e) => {
    setSelectedCountry(e.target.value)
    setCity(implentations[0].villes)
    // navigate('/bo', { replace: true });
  };
  const handleCity = (e) => {
    setSelectedCity(e.target.value)
    // navigate('/bo', { replace: true });
  };

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        const tmp = []
        const q = query(collection(db, 'implentations'));
        const docSnap = await getDocs(q);
        docSnap.forEach((element) => {
          // etImplentations((implentations) => [...implentations, element.data()]);
          tmp.push(element.data())
        });
        setImplentations(tmp)
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  /* useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          const orgRef = doc(db, 'organisations', docSnap.data().idOrg);
          const orgSnap = await getDoc(orgRef);
          if (orgSnap.data().certified === true) {
            navigate('/bo', { replace: true });
          }
        } else {
          await setDoc(docRef0, {
            id: user.uid,
            role: {id:0, name: 'super Admin'},
            dateCreation: serverTimestamp(),
            email: user.email,
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [user]); */


  // *****************************************************************************

  return (
    <Page title="Inscription">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Stack justifyContent='center' alignItems='center' paddingX={10} sx={{}}>
              <Typography textAlign={'center'} paddingBottom={5} variant='h3' sx={{ color: 'white' }}>Sell your event better, faster, stronger.</Typography>
              <Typography textAlign='center' sx={{ color: 'whitesmoke' }} fontSize={20}>
                Nous facilitons la vente de billets et le développement de vos communautés, afin que vous puissiez vous
                concentrer sur la création d’évènements exceptionnels.
              </Typography>
            </Stack>
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography variant="h3" gutterBottom>
              Informations sur votre organisation
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 2 }}>Veuillez renseigner les champs ci-dessous</Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Stack my={2} direction='row' spacing={1}>
                    <Box onClick={() => setStatus(0)} sx={statusJur === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                      :
                      { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                      <Typography variant='body2'>Particulier</Typography>
                    </Box>
                    <Box onClick={() => setStatus(1)} sx={statusJur === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                      :
                      { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                      <Typography variant='body2'>Entreprise</Typography>
                    </Box>
                  </Stack>
                  <Stack spacing={1} direction='row'>
                    {
                      statusJur !== 0 && <RHFTextField name="nom" label="Nom de l'organisation" />
                    }

                  </Stack>
                </Stack>
                <FormControl fullWidth variant="outlined" style={{ minWidth: 120, marginRight: 8 }}>
                  <Select
                    value={selectedCountry}
                    onChange={handleCountry}
                  // disabled
                  >
                    {implentations.map((option, index) => (
                      <MenuItem name={`${index}`} key={option.countryCode} value={option.name}>
                        {`${option.name} (${option.countryCode})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" style={{ minWidth: 120, marginRight: 8 }}>
                  <Select
                    value={selectedCity}
                    onChange={handleCity}
                  // disabled
                  >
                    {city.map((option, index) => (
                      <MenuItem name={`${index}`} key={option.id} value={option.name}>
                        {`${option.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <CustomNumber number={num} onNumberChange={(e) => setNum(e)} />
                <RHFCheckbox name="confirmed" label="je certifie sur l'honneur l'exactitude des informations ci-dessus." />

                <Typography>{console.log()}</Typography>

                <LoadingButton
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Continuer
                </LoadingButton>
              </Stack>
            </FormProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

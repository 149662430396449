/* eslint-disable no-else-return */


export function getTimeDifferenceInDays(firebaseTimestamp) {
    const today = new Date();
    const targetDate = firebaseTimestamp.toDate(); // Convertit le Timestamp Firebase en objet Date
    const differenceInTime = targetDate - today;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays === 0) {
      return "aujourd'hui";
    } else if (differenceInDays === 1) {
      return "demain";
    } else if (differenceInDays > 1) {
      return `dans ${differenceInDays} jours`;
    } else {
      return `il y a ${Math.abs(differenceInDays)} jours`;
    }
  }

  export function getTimeDifference(firebaseTimestamp, p) {
    const now = new Date();
    const targetDate = firebaseTimestamp.toDate();
    const differenceInMilliseconds = targetDate - now;
    const differenceInSeconds = Math.floor(Math.abs(differenceInMilliseconds) / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);
    const differenceInWeeks = Math.floor(differenceInDays / 7);
    const differenceInMonths = Math.floor(differenceInDays / 30.44);
    const differenceInYears = Math.floor(differenceInDays / 365);

    const prefix = p === 0 ? "il y a" : "dans";

    if (differenceInSeconds < 60) {
        return `${prefix} ${differenceInSeconds} secondes`;
    } else if (differenceInMinutes < 60) {
        return `${prefix} ${differenceInMinutes} minutes`;
    } else if (differenceInHours < 24) {
        return `${prefix} ${differenceInHours} heures`;
    } else if (differenceInDays === 0) {
        return "aujourd'hui";
    } else if (differenceInDays === 1) {
        return prefix === "il y a" ? "hier" : "demain";
    } else if (differenceInDays < 7) {
        return `${prefix} ${differenceInDays} jours`;
    } else if (differenceInWeeks < 4) {
        return `${prefix} ${differenceInWeeks} semaines`;
    } else if (differenceInMonths < 12) {
        return `${prefix} ${differenceInMonths} mois`;
    } else {
        return `${prefix} ${differenceInYears} ans`;
    }
}


export function hasPermission(permissions, requiredPermission) {
  return Object.values(permissions || {}).some((perms) =>
    perms.includes(requiredPermission)
  );
}
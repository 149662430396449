import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// material
import {
    Card,

    Stack,

    Button,

    Container,
    Typography,

    Grid,

    Box,
    Modal,
    TextField,

    MenuItem,

    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Divider,
    styled,
    alpha,
    LinearProgress,
    Avatar,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import CustomButton from '../../../components/CustomButton';
import TikTokLinkAccount from './parameter/intégration/TiktokLinkAccount';
import FacebookLinkAccount from './parameter/intégration/FacebookLinkAccount';


export default function Parameter() {

    return (
        <Page title="Campagne">
            <Box>

                <Container sx={{ marginTop: { xs: 0 }, }}>
                    <CheckCard />
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={2} >
                        <Typography variant="h3" gutterBottom>
                            Paramêtres
                        </Typography>
                    </Stack>
                    <Stack mb={4} direction='row' spacing={1}>
                        <Box sx={{ bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1}>
                            <Typography variant='body2'>Intégration</Typography>
                        </Box>
                        <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1}>
                            <Typography variant='body2'>Personnalisations</Typography>
                        </Box>
                    </Stack>



                    <Stack paddingTop={0} spacing={3}>
                        <TikTokLinkAccount />
                        <FacebookLinkAccount />
                    </Stack>
                </Container>
            </Box>

        </Page>
    );
}

import { faker } from '@faker-js/faker';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Chip,
  OutlinedInput,
  InputLabel,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@mui/material';
// components

import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { LoadingButton } from '@mui/lab';
import { db, functions } from '../../firebase.config';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { UserAuth } from '../../context/AuthContext';
import CustomButton from '../../components/CustomButton';
import withAuthorization from '../../withAuthorization';

// sections
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------

function AddOperateur() {
  const { eventID } = useParams();
  const theme = useTheme();
  const { user } = UserAuth();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // recuperation de billets

  const [tickets, ticketList] = React.useState([]);
  const [operateurs, setOperateurs] = React.useState([]);
  const [idOrg, setIdOrg] = React.useState();

  React.useEffect(() => {
    (async () => {
      try {
        // recuperation des operateurs
        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

        const docSnap = await getDoc(docRef0);
        /* if (docSnap.exists()) {
          
        } */
        setIdOrg(docSnap.data().idOrg)


        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d.data()]);
          console.log(d.id, ' => ', d.data());
        });
        // setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);

  // name input
  const [name, setName] = React.useState();

  // id input
  const [id, setId] = React.useState();

  // pass input
  const [pass, setPass] = React.useState();

  // ticket
  const [ticketVerif, setTicket] = React.useState([]);

  const createTicketList = (e) => {
    setTicket((ticketVerif) => [...ticketVerif, e]); // ajouter a la fin
  };

  // choix du ticket
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [personName, setPersonName] = React.useState([]);

  const handleChangeTicket = (event) => {
    const {
      target: { value },
    } = event;
    setTicket(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    console.log(ticketVerif);
  };

  // handle submit

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    console.log(name);
    console.log(id);
    console.log(pass);
    console.log(ticketVerif);
    const generateAuthLink = httpsCallable(functions, "generateAuthLink");
    try {
      const response = await generateAuthLink({
        operatorID: id,
        name,
        eventID
      });
      console.log('link');
      console.log(response.data);
      await setDoc(doc(db, `evenement/${eventID}/operateurs`, `${id}`), {
        nameOp: name,
        idOp: id,
        event: eventID,
        tickets: ticketVerif,
        link: response.data.Rlink,
        isActive: true,
        isScanning : false,
        autorisations: {'rbt': true, 'supp':false, 'prt': true, 'rsv': false },
      }); 
      setMsg({ content: 'Opérateur créé avec succès', color: 'green' });
      setOpenSnack(true);
      navigate(-1, { replace: true });
    } catch (error) {
      console.log('error');
      console.log(error.message);
      setMsg({ content: "une erreur est survenue", color: 'red' });
      setOpenSnack(true);
    }
    setLoading(false)
  };

  // chargement
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Page title="Opérateur">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack direction="column" alignItems="start" justifyContent="space-between" spacing={2} mb={3}>
        <CustomButton startIcon={<Iconify icon="material-symbols:arrow-back-ios" />} onClick={() => window.history.back()} type={'secondaire'} buttonText={'Retour'} />
          <Typography variant="h4" gutterBottom>
            Ajouter un opérateur
          </Typography>
        </Stack>
       
        <form onSubmit={handleSubmit}>
          <Stack sx={{ marginTop: 2 }} spacing={2}>
            {/* information acheteur */}
            <Box sx={{ p: 3, border: '2px solid gainsboro', borderRadius: '10px' }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={10} sm={4} md={4}>
                  <Typography variant="h6">
                    Opérateur
                    <br />
                    <Typography variant="body2">Informations de connexion</Typography>
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ p: 2 }} dir="ltr">
                <Grid paddingY={1} alignItems="center" container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography>Nom</Typography>
                  </Grid>

                  <Grid item xs={12} sm={8} md={6}>
                    <TextField
                      placeholder='Ex : Acces VIP, Operateur 2, Alex'
                      name="nom"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid paddingY={1} alignItems="center" container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography>Email</Typography>
                  </Grid>

                  <Grid item xs={12} sm={8} md={6}>
                    <TextField name="id" type="email" value={id} onChange={(e) => setId(e.target.value)} fullWidth />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* liste de controle */}
            <Box sx={{ p: 3, border: '2px solid gainsboro', borderRadius: '10px' }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={10} sm={4} md={4}>
                  <Typography variant="h6">Categorie de ticket à vérifier </Typography>
                </Grid>
              </Grid>

              <Box sx={{ p: 2 }} dir="ltr">
                <Grid paddingY={1} alignItems="center" container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography>choix du ticket</Typography>
                  </Grid>

                  <Grid item xs={12} sm={8} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={ticketVerif}
                        onChange={handleChangeTicket}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key={0} value="Tous les tickets">
                          Tous les tickets
                        </MenuItem>
                        {tickets.map((ticket, index) => (
                          <MenuItem key={index} value={ticket.ticket_name} style={getStyles(name, personName, theme)}>
                            {ticket.ticket_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box sx={{ p: 3 }} dir="ltr">
              <Grid container spacing={1}>
                <Grid item xs={6} sm={2} md={2}>
                  <LoadingButton
                    loading = {loading}
                    fullWidth
                    variant="outlined"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '7px',
                      borderColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'black',
                      },
                    }}
                    onClick={() => window.history.back()}
                  >
                    Annuler
                  </LoadingButton>
                </Grid>
                <Grid item xs={6} sm={2} md={2}>
                  
                  <LoadingButton
                  loading = {loading}
                    fullWidth
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    variant="contained"
                    type="submit"
                  >
                    Enregister
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </form>
      </Container>
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Box>
            {msg.content}{' '}
            <Iconify
              sx={{ color: msg.color }}
              icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Box>
        }
      />
    </Page>
  );
}
export default withAuthorization(AddOperateur, "add_ops")

import React from 'react';

import AppHero from '../components/home/hero';
import AppWorks from '../components/home/works';
import AppPricing from '../components/home/pricing';
import AppFaq from '../components/home/faq';
import AppFeature from '../components/home/features';
import AppFooter from '../components/home/footer';
import Functions from '../components/home/services';
// import AppFeature from '../components/home/feature';
// import AppWorks from '../components/home/works';
// import AppFaq from '../components/home/faq';
// import AppPricing from '../components/home/pricing';
// import AppContact from '../components/home/contact';

function AppHome() {
  return (
    <div className="main">
      <AppHero/>
      {
        /**
         * 
         */
      }
      {
        /**
         * <AppWorks />
         */
      }
      
      
      <Functions />
      <AppPricing />
      <AppFaq/>
      <AppFooter/>
    </div>
  );
}

export default AppHome;
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
// material
import {
    Box,
    Checkbox,
    Divider,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
// components
import {
    collection,
    doc,
    getDocs,
    query,
    Timestamp,
    updateDoc,
} from 'firebase/firestore';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { db } from '../../../../../firebase.config';
import { UserAuth } from '../../../../../context/AuthContext';
import { Variables } from '../../../../../context/VariableContext';
import CustomSeanceDateTimePicker from '../../../../../components/customSeanceDateTimePicker';
import CustomButton from '../../../../../components/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function CreateSeance({ eventID, date, seanceID, seanceData }) {
    const { user } = UserAuth();
    const { setOpenDrawer, generalReload, setGeneralReload } = Variables();
    const [step, setStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [dateDebut, setDateDebut] = useState(null);
    const [timeDebut, setTimeDebut] = useState('');
    const [name, setName] = useState('');
    const [typeBillet, setTypeBillet] = React.useState(0);
    const [tickets, setTicket] = React.useState([]);
    const [selectedTickets, setSelectedTickets] = React.useState([]);
    const [totalselectedTickets, setTotalSelectedTicket] = React.useState([]); //
    const [QuotaselectedTickets, setQuotaSelectedTicket] = React.useState([]);

    const [addTicket, setAddTicket] = React.useState(false);

    const [recurence, setRecurence] = React.useState();

    const handleChange = (event) => {
        setRecurence(event.target.value);
    };


    const handleDateChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };
    const [timeFin, setTimeFin] = useState('');


    const handleTimeFinChange = (newTime) => {
        setTimeFin(newTime);
    };

    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
    };


    const handleTimestampCustom = (e) => {
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    };

    const handleSelectedTickets = (index) => {
        console.log(index)
        console.log(selectedTickets)
        setSelectedTickets((prevArray) =>
            prevArray.map((item, i) => (i === index ? !item : item))
        );
    };

    const handleAffinageQuota = (value, index) => {
        console.log(index)
        setQuotaSelectedTicket((prevArray) =>
            prevArray.map((item, i) => (i === index ? value : item))
        );
    };

    const formatDateWithTime = (date, timeDebut, timeFin) => {
        const createDateWithTime = (baseDate, time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return new Date(baseDate.setHours(hours, minutes, 0));
        };

        const dateDebut = createDateWithTime(new Date(date), timeDebut);
        const dateFin = createDateWithTime(new Date(date), timeFin);

        if (dateFin < dateDebut) {
            dateFin.setDate(dateFin.getDate() + 1);
        }

        return { dateDebut, dateFin };
    };



    React.useEffect(() => {
        if (seanceData) {
            try {
                setLoading(true);
                console.log("Données de la séance chargées :", seanceData);

                if (seanceData.date?.toDate) {
                    const formattedDate = format(seanceData.date.toDate(), 'yyyy-MM-dd', { locale: fr });
                    const formattedTimeDebut = format(seanceData.date.toDate(), 'HH:mm', { locale: fr });

                    console.log("Date début formatée :", formattedDate);
                    console.log("Heure début formatée :", formattedTimeDebut);

                    setDateDebut(formattedDate);
                    setTimeDebut(formattedTimeDebut);
                }

                if (seanceData.fin?.toDate) {
                    const formattedTimeFin = format(seanceData.fin.toDate(), 'HH:mm', { locale: fr });
                    console.log("Heure fin formatée :", formattedTimeFin);
                    setTimeFin(formattedTimeFin);
                }

                setName(seanceData?.name || '');

                setLoading(false);
            } catch (error) {
                console.error('Erreur lors du chargement des données de séance:', error);
            }
        }
    }, [seanceID, seanceData]);



    const createSeance = async () => {
        setLoading(true);
        try {
            const tmpDate = formatDateWithTime(dateDebut, timeDebut, timeFin);
            console.log(tmpDate)
            const tmpTickets = [];
            // boucle des tickets
            for (let j = 0; j < tickets.length; j++) {
                const billetTrouve = seanceData?.billets?.find(billet => billet.id === tickets[j].id);
                console.log(billetTrouve)
                if (selectedTickets[j] === true) {
                    if (parseInt(QuotaselectedTickets[j]) === 0 || QuotaselectedTickets[j] === null) {
                        console.log('erreur')
                        setLoading(false);
                        return
                    }
                    tmpTickets.push({
                        id: tickets[j].id,
                        quota: parseInt(QuotaselectedTickets[j]),
                        sell: billetTrouve ?  billetTrouve.sell : parseInt(0),
                        status: parseInt(1),
                    });
                }
            }
            console.log(tmpTickets)
            await updateDoc(doc(db, `evenement/${eventID}/seances`, `${seanceID}`), {
                name: name || '',
                date: Timestamp.fromDate(tmpDate.dateDebut),
                fin: Timestamp.fromDate(tmpDate.dateFin),
                billets: tmpTickets,
                status: 1,
            });

            setOpenDrawer(false);
            setGeneralReload(!generalReload)
        } catch (error) {
            console.error("Erreur lors de la création de la séance :", error);
            // alert("Une erreur est survenue. Veuillez réessayer.");
        }
        setLoading(false);
    };


    // recuperation de tickets
    React.useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const q = query(collection(db, `evenement/${eventID}/tickets`));
                const snapshot = await getDocs(q);

                const fetchedTickets = [];
                const fetchedSelectedTickets = [];
                const fetchedQuotas = [];

                snapshot.forEach((d) => {
                    fetchedTickets.push(d);
                    const existingBillet = seanceData?.billets?.find((b) => b.id === d.id);
                    if (existingBillet) {
                        fetchedSelectedTickets.push(existingBillet.status === 1);
                        fetchedQuotas.push(existingBillet.quota || 0);
                    } else {
                        fetchedSelectedTickets.push(false);
                        fetchedQuotas.push(0);
                    }
                });

                setTicket(fetchedTickets);
                setSelectedTickets(fetchedSelectedTickets);
                setQuotaSelectedTicket(fetchedQuotas);
            } catch (error) {
                console.log('error', error);
            }
            setLoading(false);
        })();
    }, [eventID, seanceData]);

    return (
        <Box>
            {
                // eslint-disable-next-line no-nested-ternary

                <Box
                    paddingBottom={3}
                    paddingX={3}
                    sx={{ height: { xs: '84vh', sm: ' 80.25vh', xl: '84.25vh' }, overflowX: 'auto', overflowY: 'auto' }}
                >
                    <Stack spacing={2} >
                        <Typography variant="subtitle1" paddingBottom={1} paddingTop={1}>
                            Informations generales
                        </Typography>

                        <TextField disabled={loading ? true : false} value={name} onChange={(e) => setName(e.target.value)} placeholder='Sous-titre : jour1, manche 1 ...' />


                        <Box>
                        
                        {
                            !loading && <CustomSeanceDateTimePicker disabled={loading} date={dateDebut} debutTime={timeDebut} onDebutTimeChange={handleTimeDebutChange} onDateChange={handleDateChange} endTime={timeFin} onEndTimeChange={handleTimeFinChange} />
                        }
                        

                        </Box>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='subtitle1'>Billets Associés</Typography>
                            {

                            }
                            <Typography variant='caption' sx={{ color: 'red' }}>Accun billet associé</Typography>
                        </Stack>

                        <Stack spacing={1}>

                            {tickets.map((ticket, i) => (
                                <>
                                    <Stack paddingY={1} paddingX={2} key={ticket.id}>
                                        <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}>
                                            <Checkbox
                                                disabled={loading}
                                                checked={selectedTickets[i]}
                                                onChange={() => {
                                                    handleSelectedTickets(i);
                                                }}

                                            />
                                            <Typography variant='body2'>{ticket.data().ticket_name}</Typography>

                                        </Stack>
                                        {selectedTickets[i] && (
                                            <TextField disabled={loading} placeholder='quantite de billet mise en vente' value={QuotaselectedTickets[i]}
                                                onChange={(e) => {
                                                    handleAffinageQuota(e.target.value, i);
                                                }} />
                                        )}
                                    </Stack>
                                    <Divider />
                                </>
                            ))}

                        </Stack>
                    </Stack>
                </Box>
            }
            <Stack direction='row' spacing={2} padding={2} justifyContent={'right'} alignItems={'center'} sx={{ borderTop: '1px solid gainsboro' }}>
                <CustomButton onClick={() => {
                    if (step === 0) {
                        setDateDebut(null)
                        setOpenDrawer(false);
                    }
                    else {
                        setStep(0)
                    }

                }} disabled={loading} type={'secondaire'} buttonText={'Annuler'} />
                <CustomButton onClick={createSeance} disabled={loading} type={'primaire'} buttonText={'Enregistrer'} />
            </Stack>
        </Box>
    );
}

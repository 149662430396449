import React from 'react';
// material
import {
    Stack,
    Container,
    Typography,
    Box,
} from '@mui/material';
// components
// eslint-disable-next-line no-unused-vars
import { useLocation, useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';

// mock

import { UserAuth } from '../../../context/AuthContext';
import Utilisateurs from './composant/utilisateurs';
import Roles from './composant/roles';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function GestionEquipeDashboard() {

    const { user } = UserAuth();


    const [menu, setMenu] = React.useState(0);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handleChange = (newValue) => {
        setMenu(newValue);
        if (newValue === 0) {

            navigate(`/organisation/team/users`);


        }
        if (newValue === 1) {
            navigate(`/organisation/team/roles`);
        }
        else if (newValue === '3') {
            if (!pathname.includes('contacts')) {
                navigate(`/marketing/contacts`);
            }
        }
        else if (newValue === '4') {
            if (!pathname.includes('populations')) {
                navigate(`/marketing/populations`);
            }
        }
        else if (newValue === '5') {
            if (!pathname.includes('socialmedia')) {
                navigate(`/marketing/socialmedia`);
            }
        }
        else if (newValue === '6') {
            if (!pathname.includes('settings')) {
                navigate(`/marketing/settings`);
            }
        }
    };

    React.useEffect(() => {
        (async () => {
            try {
                const tmp = pathname.split('/')
                console.log(tmp)
                if (tmp[tmp.length - 1] === 'users') {
                    setMenu(0)
                } else if (tmp[tmp.length - 1] === 'roles') {
                    setMenu(1)
                }
            } catch (error) {
                console.log('error');
            }

        })();
    }, []);


    return (
        <Page title="Gestion d'équipe">
            <Box>
                <Container sx={{ marginTop: { xs: 3 }, }}>
                    <Typography variant="h3" gutterBottom>
                        Gestion d'équipe <br />
                        <Typography variant="body2">
                            Ajouter des utilisateurs avec des roles associés pour vous aider dans la gestionn des vos events
                        </Typography>
                    </Typography>

                    <Stack my={2} direction='row' spacing={1}>
                        <Box onClick={() => handleChange(0)} sx={menu === 0 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Utilisateurs</Typography>
                        </Box>
                        <Box onClick={() => handleChange(1)} sx={menu === 1 ? { bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Typography variant='body2'>Roles</Typography>
                        </Box>
                    </Stack>
                    <Box>
                        {
                            menu === 0 ?
                                <Utilisateurs />
                                : <Roles />
                        }
                    </Box>
                </Container>
            </Box>

        </Page>
    );
}

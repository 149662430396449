import React, { useRef } from "react";
import { Card, CardHeader, CardContent, CardMedia, CardActions, Avatar, Typography, IconButton, Button, Stack, Box, Divider } from "@mui/material";
import { MoreHoriz, FavoriteBorder, ChatBubbleOutline, Share } from "@mui/icons-material";
import Iconify from "../../../../../components/Iconify";

const FacebookPost = ({ text, media, visuelPreview, visuel, linkTitle, linkDesc, btText }) => {
    // Fonction pour redémarrer la vidéo automatiquement
    const videoRef = useRef(null);
    const handleVideoEnd = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0; // Remet la vidéo au début
            videoRef.current.play(); // Relance la lecture
        }
    };

    return (
        <Card sx={{ maxWidth: 380, boxShadow: 5, borderRadius: 5, margin: "auto" }}>
            <CardHeader
                avatar={<Avatar src="https://via.placeholder.com/40" alt="Page Logo" />}
                action={<IconButton><MoreHoriz /></IconButton>}
                title={<Typography fontWeight={600}>Your Page</Typography>}
                subheader={<Typography color="text.secondary">Sponsored</Typography>}
            />
            <CardContent>
                <Typography variant="body2" color="text.primary">
                    {text !== '' ? text : 'Text du post'}
                </Typography>
            </CardContent>
            {/* Affichage de l'image ou lecture de la vidéo */}
            {media && (
                <div style={{ marginTop: 20 }}>
                    {media.type.startsWith("video/") ? (


                        <video
                            ref={videoRef}
                            src={media.url}
                            controls
                            autoPlay
                            muted
                            style={{ maxWidth: "100%", maxHeight: 400 }}
                            onEnded={handleVideoEnd} // Redémarrer la vidéo automatiquement
                        >
                            <track
                                kind="captions"
                                srcLang="fr"
                                label="Caption"
                                default
                            />
                        </video>
                    ) : (
                        <img
                            src={media.url}
                            alt="Aperçu"
                            style={{ maxWidth: "100%", maxHeight: 400 }}
                        />
                    )}
                </div>
            )}

            {
                !media && <CardMedia
                component="img"
                height="200"
                image={visuel || "https://firebasestorage.googleapis.com/v0/b/event-86cbf.appspot.com/o/imageChoptonticket%2Fpreview.png?alt=media&token=8580a57d-7522-4ee3-8335-efc4a1a9261a"}
                alt="Event image"
            />
            }


            <CardContent sx={{ bgcolor: '#f2f2f2' }}>
                <Stack direction={'row'} spacing={2}>
                    <Box sx={{flex: 1}}>
                        <Typography variant="body2" color="text.secondary">
                            Choptonticket.com
                        </Typography>
                        <Typography variant="body2">
                            <strong>{linkTitle !== '' ? linkTitle : ' Titre'}</strong>
                        </Typography>
                        <Typography variant="body2">
                            {linkDesc !== '' ? linkDesc : 'Description du lien'}
                        </Typography>
                    </Box>
                    <Box>
                        <Button size="small" variant="contained" color="primary" fullWidth sx={{ mt: 1 }}>
                            {btText}
                        </Button>
                    </Box>
                </Stack>


            </CardContent>
            <CardActions disableSpacing>
                <Stack sx={{ width: '100%' }}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <IconButton color="primary"><Iconify icon={'uiw:like-o'} sx={{ width: 15, height: 15 }} /></IconButton>
                        <Typography variant="caption"><strong>110 Likes</strong></Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                        <IconButton ><Iconify icon={'uiw:like-o'} sx={{ width: 25, height: 25 }} /></IconButton>
                        <IconButton ><Iconify icon={'fluent:comment-20-regular'} sx={{ width: 25, height: 25 }} /></IconButton>
                        <IconButton ><Iconify icon={'ph:share-fat-thin'} sx={{ width: 25, height: 25 }} /></IconButton>
                    </Stack>
                </Stack>

            </CardActions>

        </Card>
    );
}

export default FacebookPost;
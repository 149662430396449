/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    InputAdornment,
    Skeleton,
    Menu,
    Pagination,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, orderBy, query, startAfter } from 'firebase/firestore';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import Papa from 'papaparse';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';

// mock
import USERLIST from '../../_mock/user';

import { db } from '../../firebase.config';
import CheckCard from '../../components/CheckCard';
import { UserAuth } from '../../context/AuthContext';
import { Variables } from '../../context/VariableContext';

import useResponsive from '../../hooks/useResponsive';
import CustomButton from '../../components/CustomButton';
import CustomTag from '../../components/customTag';
import { useSnackbar } from '../../context/snackbarContext';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '5px',
    boxShadow: 0,
    p: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const options = ['Importer des contacts', 'Exporter en CSV'];

export default function Templates() {
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');
    const { eventID } = useParams();
    const { user } = UserAuth();
    const showSnackbar = useSnackbar()

    // for change value of globla quota
    const [open, setOpen] = React.useState(false);

    const [refreh, setRefresh] = React.useState(false);

    // take operateurs from firebase
    const [templates, setTemplates] = React.useState([]);
    const [curent, setCurent] = React.useState();
    const [tmp, setTmp] = React.useState();
    // const [type, setType] = React.useState();
    const [search, setSearch] = React.useState('');
    const [orgID, setOrgID] = React.useState(null);
    const { openDrawer, setOpenDrawer, generalReload, setGeneralReload } = Variables();
    const { type, setType } = Variables();
    // paginnation variable
    const PAGE_SIZE = 12; // Nombre de contacts par page
    const [paginationState, setPaginationState] = useState({
        firstVisible: null,
        lastVisible: null,
        hasNextPage: true,
        hasPreviousPage: false,
    });
    const [page, setPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setCount] = useState(0);

    const [queryContains, setQueryContains] = React.useState([]);



    const handleChangePage = (event, newPage) => {
        if (page < newPage) {
            next();
        } else {
            console.log('back');
            back();
        }
        console.log(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
    };


    const next = async () => {
        const lastVisible = templates[templates.length - 1];
        const querySnapshot1 = await getDocs(
            query(
                collection(db, `publicTemplates`),
                ...queryContains,
                orderBy('createdAt', 'desc'),
                startAfter(lastVisible),
                limit(PAGE_SIZE)
            )
        );
        setTemplates([]);
        querySnapshot1.forEach(async (d) => {
            setTemplates((billets) => [...billets, d]);
        });
    };

    const back = async () => {
        const lastVisible = templates[0];
        const querySnapshot1 = await getDocs(
            query(
                collection(db, `publicTemplates`),
                ...queryContains,
                orderBy('createdAt', 'desc'),
                endBefore(lastVisible),
                limitToLast(PAGE_SIZE)
            )
        );
        setTemplates([]);
        querySnapshot1.forEach(async (d) => {
            setTemplates((billets) => [...billets, d]);
        });
    };


    React.useEffect(() => {
        (async () => {
            try {
                // const term = searchParams.get('q');
                // console.log('params: ', term);
                const query1 = [];
                setLoading(true);
                setTemplates([]);
                // get participants
                const snapshot = await getCountFromServer(query(collection(db, `publicTemplates`), orderBy('createdAt', 'desc'), ...query1));
                setCount(snapshot.data().count);
                setPageNumber(Math.ceil(snapshot.data().count / PAGE_SIZE))
                const first = query(
                    collection(db, `publicTemplates`),
                    orderBy('createdAt', 'desc'),
                    ...query1,
                    limit(PAGE_SIZE)
                );
                const querySnapshot1 = await getDocs(first);
                querySnapshot1.forEach(async (d) => {
                    setTemplates((contacts) => [...contacts, d]);
                });
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
            setLoading(false);
        })();
    }, [user, refreh, orgID, generalReload]);

    // loading
    const [loading, setLoading] = React.useState(false);
    const [loadingPag, setLoadingPag] = React.useState(false);
    const navigate = useNavigate()
    const handleModif = async (index) => {
        try {
            console.log(templates[index].data())
            navigate(`/editor/${templates[index].id}`)

        } catch (error) {
            console.log(index)
            console.log('client n existe pas 1')
            console.log(error)
            // setIDContact(contacts[index].id)
        }
    };

    const handleFilter = () => {
        setType(2);
        setOpenDrawer(!openDrawer);
    };
    const handleImportation = () => {
        setType(3);
        setOpenDrawer(!openDrawer);
    };

    const handleSearch = async (tmp) => {
        setSearch(tmp);
        if (search.length > 3) {
            // loading
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleTimestamp = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        return tmp[0];
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpenDeleteModal = (index) => {
        setSelectedIndex(index)
        setOpenDeleteModal(true);
    }
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    const handleSupp = async (index) => {
        setLoading(true)
        const template = templates[index];

        try {
            await deleteDoc(doc(db, `publicTemplates`, `${template.id}`));
            showSnackbar('Le billet a été supprimé avec succès', false)
            setGeneralReload(!generalReload)
        } catch (error) {
            console.log(error.message);
            showSnackbar('Une erreur est survenue', true)
            setLoading(false)
        }

        setLoading(false);
        handleCloseDeleteModal();
    };


    return (
        <Page title="Templates">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/** popup de suppression du billet */}
            <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ borderRadius: '7px' }}
            >
                <Box sx={styleModal}>
                    <Stack spacing={4}>
                        {
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="subtitle1">Supprimer le billet</Typography>
                                </Box>
                                <Box sx={{ marginTop: 5 }} dir="ltr">
                                    <Typography align='center' variant='body2'>Voulez-vous vraiment supprimer le template <strong>{templates[selectedIndex]?.data()?.ticket_name}</strong> ? Cette action ne peut pas être annulée.</Typography>
                                </Box>
                                <Stack direction='row' spacing={2} justifyContent='center'>
                                    <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseDeleteModal} />
                                    <CustomButton type='primaire' buttonText='Supprimer' onClick={() => handleSupp(selectedIndex)} />
                                </Stack>
                            </>
                        }


                    </Stack>

                </Box>
            </Modal>
            <Container sx={{ marginTop: { xs: 3 } }}>
                <CheckCard />
                <Stack direction="row" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Templates
                    </Typography>
                </Stack>

                <Grid container spacing={2} paddingBottom={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} sm={4} md={6}>
                        {loading ? (
                            <Skeleton width="20%">
                                <Typography>.</Typography>
                            </Skeleton>
                        ) : (
                            <Typography variant="subtitle1">{count} Templates</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>

                        <Stack direction='row' spacing={2}>
                            <TextField value={search} onChange={(e) => handleSearch(e.target.value)} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify icon="iconoir:search" />
                                    </InputAdornment>
                                ),
                            }} placeholder="Rechercher" fullWidth />
                            <CustomButton type='primaire' startIcon={<Iconify icon="mingcute:add-fill" />} buttonText='Nouveau' onClick={() => navigate(`/editor`)} />
                        </Stack>
                    </Grid>
                </Grid>
                <Box sx={{ padding: 1 }}>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={count}
                        rowsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                <Grid container spacing={1} padding={2}>
                    {templates.map((template, index) => (
                        <Grid key={index} item xs={12} sm={4} >
                            <Card sx={{ padding: 2, minHeight: 300, cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} onClick={() => { handleModif(index) }} onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}>
                                {hoveredIndex === index && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            border: '1px solid #5E17EB',
                                            color: '#5E17EB',
                                            '&:hover': {
                                                border: 'transparent',
                                                backgroundColor: 'rgba(255, 0, 0, 0.8)',
                                                color: 'white',
                                            },
                                            zIndex: 1
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Empêche le clic de propager vers la carte
                                            handleOpenDeleteModal(index);
                                        }}
                                    >
                                        <Iconify icon={'material-symbols-light:delete-outline-rounded'} />
                                    </IconButton>
                                )}
                                <Box sx={{ flex: 1, overflow: 'auto' }}>
                                    <LazyLoadImage
                                        src={`${template.data().imageUrl}`}
                                        alt={`${template.data().imageUrl}`}
                                        className="img-lazy"
                                        height={350}
                                        style={{ objectFit: 'cover' }}
                                        effect="blur" // opacity | black-and-white
                                    />
                                </Box>
                                <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant='subtitle1'>{template.data().title}</Typography>
                                </Box>

                            </Card>
                        </Grid>
                    ))}

                </Grid>
                <Box sx={{ padding: 2 }}>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={count}
                        rowsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>

            </Container>
        </Page>
    );
}

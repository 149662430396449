/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import {
  Stack,
  Checkbox,
  Typography,
  Box,
  LinearProgress,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextareaAutosize,
  TextField,
  Grid,
  Button,
  alpha,
  Menu,
  styled,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Container,
} from '@mui/material';
// components
import { SketchPicker } from 'react-color';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { db } from '../../../firebase.config';
import CustomButton from '../../../components/CustomButton';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function OwnSite() {
  const { eventID } = useParams();
  // loading
  const [loading, setLoading] = React.useState(false);

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = useState('ffffff');
  const [textColor, setTextColor] = useState('000000');
  const [primaryColor, setPrimaryColor] = useState('000000');
  const [secondaryColor, setSecondaryColor] = useState('000000');
  const [customIndex, setCustom] = useState(0);
  const [embedParams, setEmbedParams] = useState('');
  const [colors, setColors] = useState({ bg: "", primaire: "#5E17EB", secondaire: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    if (event.target.id === 'bt-fdColor') {
      setCustom(1)
    } else if (event.target.id === 'bt-primaire') {
      setCustom(2)
    } else if (event.target.id === 'bt-secondaire') {
      setCustom(3)
    }
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
  };

  function replaceBetweenChars(str, startChar, endChar, replacement) {
    const regex = new RegExp(`${startChar}([^${endChar}]*)${endChar}`, 'g');
    return str.replace(regex, `${startChar}${replacement}${endChar}`);
  }

  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex.slice(1));
    console.error(color);
    // setColors({bg: color.hex.slice(1), primaire: colors.primaire, secondaire: colors.secondaire})
    if (embedParams.includes('bgColor')) {
      setEmbedParams(replaceBetweenChars(`${embedParams}&`, 'bgColor=', '&', color.hex.slice(1)).slice(0, -1));
    } else if (embedParams === '') {
      setEmbedParams(`bgColor=${color.hex.slice(1)}`);
    } else {
      setEmbedParams(`${embedParams}&bgColor=${color.hex.slice(1)}`);
    }
  };

  const handleTextColorChange = (color) => {
    setTextColor(color.hex);
  };
  const handlePrimaryColorChange = (color) => {
    setPrimaryColor(color.hex.slice(1));
    // setColors({bg: colors.bg, primaire: color.hex.slice(1), secondaire: colors.secondaire})
    if (embedParams.includes('pColor')) {
      setEmbedParams(replaceBetweenChars(`${embedParams}&`, 'pColor=', '&', color.hex.slice(1)).slice(0, -1));
    } else if (embedParams === '') {
      setEmbedParams(`pColor=${color.hex.slice(1)}`);
    } else {
      setEmbedParams(`${embedParams}&pColor=${color.hex.slice(1)}`);
    }
  };
  const handleSecondaryColorChange = (color) => {
    setSecondaryColor(color.hex.slice(1));
    if (embedParams.includes('sColor')) {
      setEmbedParams(replaceBetweenChars(`${embedParams}&`, 'sColor=', '&', color.hex.slice(1)).slice(0, -1));
    } else if (embedParams === '') {
      setEmbedParams(`sColor=${color.hex.slice(1)}`);
    } else {
      setEmbedParams(`${embedParams}&sColor=${color.hex.slice(1)}`);
    }
  };

  const [url, setUrl] = useState('');
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setUrl(`http://localhost:3001/fr/event_embed/${eventID}/${embedParams}`);
        // setUrl(`https://choptonticket.com/fr/event_embed/${eventID}/${embedParams}`);
        console.log(`http://localhost:3001/fr/event_embed/${eventID}/${embedParams}`);
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, embedParams]);

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { gilad, jason, antoine } = state;

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          //
          if (docSnap.data().custom !== null) {
            console.log(docSnap.data().custom)
            setBackgroundColor(docSnap.data().custom.bgColor)
            setPrimaryColor(docSnap.data().custom.pColor)
            setSecondaryColor(docSnap.data().custom.sColor)
            if (docSnap.data().custom.bgColor !== null) {
              setBackgroundColor(docSnap.data().custom.bgColor)
            } else {
              setBackgroundColor('ffff')
            }
            if (docSnap.data().custom.pColor !== null) {
              setPrimaryColor(docSnap.data().custom.pColor)
            } else {
              setPrimaryColor(docSnap.data().custom.pColor)
            }
            if (docSnap.data().custom.sColor !== null) {
              setSecondaryColor(docSnap.data().custom.sColor)
            } else {
              setSecondaryColor(docSnap.data().custom.sColor)
            }
            if (docSnap.data().custom.txtColor !== null) {
              setSecondaryColor(docSnap.data().custom.txtColor)
            } else {
              setSecondaryColor("00000")
            }
          }
        }
      } catch (error) {
        console.log('error');
      }
      setLoading(false)
    })();
  }, [eventID]);

  // save population
  const saveCustom = async () => {
    setLoading(true)
    try {
      const statRef = doc(db, `evenement`, `${eventID}`);
      await updateDoc(statRef, {
        custom: { bgColor: backgroundColor, pColor: primaryColor, sColor: secondaryColor },
      });
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleCopy = () => {
    navigator.clipboard.writeText(`choptonticket.com/fr/event/${eventID}`)
    // setMsg({ content: 'Lien copié dans le papier presse', color: 'green' });
    // setOpenSnack(true);
  };

  return (
    <Page title="Widget de vente" >
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}


      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', sm: 'center' }}
          justifyContent="space-between"
          mb={5}
          spacing={2}
        >
          <Typography variant="h3" gutterBottom>
            Vendre sur propre site <br />
            <Typography variant="body2">
              Obtenez le code d'intégration du module de vente
            </Typography>
          </Typography>
          <Box paddingY={2} sx={{ display: 'flex', justifyContent: 'end' }}>
            <CustomButton type='primaire' buttonText='Créer une nouvelle affiliation' onClick={() => { }} />
          </Box>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Stack spacing={2} padding={3} sx={{ bgcolor: '#F5EFFA', border: '3px solid #F5EFFA', borderRadius: '7px' }}>
            <Box>
              <Typography variant='h4'>Intégration dans une page HTML !</Typography>
              <Typography variant='body2'>ntégrez votre module billetterie directement dans la page HTML de votre site à l'endroit souhaité.</Typography>
            </Box>
            <Stack spacing={1} sx={{ borderRadius: '7px' }}>
              <Box>
                <Stack direction='row' alignItems='center' justifyContent='end'>
                  <Stack onClick={handleCopy} alignItems='center' direction='row' sx={{ color:'white', cursor: 'pointer', borderRadius: '10px', bgcolor: '#5E17EB', paddingX: 2, paddingY: 0.5 }} spacing={0.5}>
                    <Iconify icon='bi:copy' />
                    <Typography variant='caption'>Copier</Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box sx={{  borderRadius: '7px' }} dir="ltr">
                <Typography>
                  <TextField
                    disabled
                    sx={{ borderRadius: '7px' }}
                    minRows={2}
                    multiline
                    fullWidth
                    value={`<iframe width="100%" height="100%" src="${url}" title="cool" frameBorder={0} />`}
                  />
                </Typography>
              </Box>
              <Typography variant='caption'>
                Copiez et collez ce code à l’endroit où vous souhaitez que le widget apparaisse sur votre page Web
              </Typography>
            </Stack>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <CustomButton type='primaire' buttonText='Personaliser votre widget' onClick={() => { }} />
            </Box>

          </Stack>

          <Box padding={3} sx={{ bgcolor: '#F5EFFA', borderRadius: '7px' }}>
            <Typography variant='h4'>Intégration dans une nouvelle fenêtre !</Typography>
            <Typography variant='body2'>Un bouton personnalisé permet d'ouvrir une fenêtre popup contenant le module de vente.
            </Typography>
          </Box>

        </Stack>
      </Container>


    </Page>
  );
}

/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  Box,
  Modal,
  TextField,
  LinearProgress,
  Backdrop,
  CircularProgress,
  IconButton,
  Skeleton,
  Snackbar,
  ToggleButtonGroup,
  styled,
  Menu,
  alpha,
  MenuItem,
} from '@mui/material';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { db } from '../../firebase.config';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
// mock
import { Variables } from '../../context/VariableContext';
import CustomButton from '../../components/CustomButton';
import { shortNumber } from '../../utils/formatNumber';
import { useSnackbar } from '../../context/snackbarContext';

// ----------------------------------------------------------------------

//-----------------------------------------------------------------------

// for modal
const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 350,
  width: 500,
  bgcolor: 'background.paper',
  p: 4,
};
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '5px',
  boxShadow: 0,
  p: 3,
};

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function Billets() {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar()
  const { generalReload, setGeneralReload } = Variables()

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  // take tickets from firebase
  const [event, setEvent] = useState();
  const [tickets, setTicket] = React.useState([]);

  const [modif, setModif] = React.useState(false); // ouvrir section de modification

  
  const handleToggle = () => {
    setOpen(!open);
  };


  React.useEffect(() => {
    (async () => {
      setLoading(true);
      setTicket([]);
      try {
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
        }

        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setTicket((tickets) => [...tickets, d]);
          
        });
        
        setLoading(false);
      } catch (error) {
        setLoading(false);
        
      }
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // page de modification
  const [openModif, setOpenModif] = React.useState(false);
  const handleCloseModif = () => setOpenModif(false);
  const [temp, setTemp] = React.useState([]);

  // modification d'un billet
  const handleModif = async (index) => {
    setLoading(true);
    navigate(`${tickets[index].id}`)
    
    setLoading(false);
  };
 
  // supression d'un billet
  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleSupp = async (index) => {
    handleToggle();
    
    const ticket = tickets[index];
    if (ticket.data().sell === 0) {
      try {
        await deleteDoc(doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`));
        showSnackbar('Le billet a été supprimé avec succès', false)
        setGeneralReload(!generalReload)
      } catch (error) {
        console.log(error.message);
        showSnackbar('Une erreur est survenue', true)
        handleClose();
      }
    }
    handleClose();
    handleCloseDeleteModal();
  };

  // desactiver le billet
  const handleVisible = async () => {
    const ticket = tickets[selectedIndex];
    if (ticket.data().status === 0) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`);

        await updateDoc(statRef, {
          status: 1,
        });
        showSnackbar('Le Billet a été activé avec succès', false)
      } catch (error) {
        showSnackbar('Une erreur est survenue', true)
        handleClose();
        console.log(error.message);
      }
    } else if (ticket.data().status === 1) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`);
        await updateDoc(statRef, {
          status: 0,
        });
        showSnackbar('Le Billet a été desactivé avec succès', false)
      } catch (error) {
        showSnackbar('Une erreur est survenue', true)
        handleClose();
        console.log(error.message);
      }
    }
    handleClose();
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <Page title="Mes Billets">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Modal
          open={openModif}
          onClose={handleCloseModif}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Quota Maximal
            </Typography>
            <TextField
              value={temp.quota}
              size="small"
              fullWidth
              id="outlined-number"
              placeholder="Entrer le quota"
              type="number"
            />
            <Typography variant="body2" sx={{ mt: 2 }}>
              le quota est la capaciter maximale de personne que vous souhaitez acceuillir.
            </Typography>
            <Button onClick={handleOpen} size="small">
              Enregistrer
            </Button>
          </Box>
        </Modal>
        {/** popup de suppression du billet */}
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderRadius: '7px' }}
        >
          <Box sx={styleModal}>
            <Stack spacing={4}>
              {
                tickets[selectedIndex]?.data()?.sell > 0 ?
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant="subtitle1">Impossible de supprimer le billet</Typography>
                    </Box>
                    <Box sx={{ marginTop: 5 }} dir="ltr">
                      <Typography align='center' variant='body2'>Vous ne pouvez pas supprimer ce billet car il a déjà été commandé par des participants.</Typography>
                    </Box>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                      <CustomButton type='secondaire' buttonText='Ok' onClick={handleCloseDeleteModal} />
                    </Stack>
                  </>
                  :
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant="subtitle1">Supprimer le billet</Typography>
                    </Box>
                    <Box sx={{ marginTop: 5 }} dir="ltr">
                      <Typography align='center' variant='body2'>Voulez-vous vraiment supprimer le billet <strong>{tickets[selectedIndex]?.data()?.ticket_name}</strong> ? Cette action ne peut pas être annulée.</Typography>
                    </Box>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                      <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseDeleteModal} />
                      <CustomButton type='primaire' buttonText='Supprimer' onClick={() => handleSupp(selectedIndex)} />
                    </Stack>
                  </>
              }


            </Stack>

          </Box>
        </Modal>

        <>
          {/* en tete de la page */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h3" gutterBottom>
              Billet & Mise en vente <br />
              <Typography variant="body2">Creez et modifiez vos billets</Typography>
            </Typography>
            <CustomButton type='primaire' buttonText='Ajouter' startIcon={<Iconify icon="eva:plus-fill" sx={{ width: 20, height: 20 }} />} component={RouterLink}
              to="nouveau_billet" />
          </Stack>

          {/* Tableau des billets */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Table sx={{ minWidth: 500, maxWidth: 1000 }} aria-label="caption table">
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : tickets.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucun billet</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <TableBody>
                      {tickets.map((ticket, index) => (
                        <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                          <TableCell component="th" scope="row" onClick={() => handleModif(index)}>
                            <Stack>
                              <Typography variant='subtitle1'>{ticket.data().ticket_name}</Typography>
                              <Stack direction='row' spacing={1} alignItems='center'>
                                {
                                  ticket.data().sell !== 0 && ticket.data().sell >= ticket.data().quota ?
                                    <>
                                      <Iconify icon='radix-icons:dot-filled' sx={{ color: 'red' }} />
                                      <Typography variant='body2'>Complet</Typography>
                                    </>
                                    : ticket.data().status === 1 ?
                                      <>
                                        <Iconify icon='radix-icons:dot-filled' sx={{ color: 'green' }} />
                                        <Typography variant='body2'>En Vente</Typography>
                                      </>
                                      : ticket.data().status === 2 ?
                                        <>
                                          <Iconify icon='radix-icons:dot-filled' sx={{ color: 'yellow' }} />
                                          <Typography variant='body2'>Planifié</Typography>
                                        </> : null

                                }

                                {
                                  ticket.data().status === 0 && <Stack direction='row' spacing={0.5} alignItems='center'>
                                    <Iconify icon="gridicons:not-visible" />
                                    <Typography variant='body2'>Masqué</Typography>
                                  </Stack>
                                }

                              </Stack>
                            </Stack>

                          </TableCell>
                          <TableCell align="right" onClick={() => handleModif(index)}>
                            <Stack direction='row' spacing={1}>
                              <Typography variant="body2">{ticket.data().sell} <span color='#f2f2f2'>{ticket.data().encours > 0 ?  `+${ticket.data().encours} encours`: null}</span>  {event?.seanceActive !== 1 ? `/ ${ticket.data().quota}` : ''}</Typography>
                              <Typography variant="body2">Billet(s) vendu(s)</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right" onClick={() => handleModif(index)}>
                            {ticket.data().price !== 0 ? (
                              <Typography variant="body2">{shortNumber(ticket.data().price)} CFA</Typography>
                            ) : (
                              <Typography variant="body2">Gratuit</Typography>
                            )}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined} sx={{ border: '1px solid gainsboro' }}>
                              <Iconify icon="material-symbols-light:more-vert" />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >

                              <MenuItem key={`${index}-1`} onClick={(e) => {
                                handleCloseMenu();
                                handleModif(selectedIndex); // Utilise l'index sélectionné
                              }} disableRipple value={1}>
                                <Typography variant='body1'>Modifier</Typography>
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                }}
                                disableRipple
                                value={0}
                              >
                                <Typography variant='body1'>Copier</Typography>
                              </MenuItem>
                              {
                                tickets[selectedIndex]?.data().status === 1 ?
                                  <MenuItem
                                    onClick={(e) => {
                                      handleCloseMenu();
                                      handleVisible()
                                    }}
                                    disableRipple
                                    value={2}
                                  >
                                    <Typography variant='body1'>Désactiver</Typography>
                                  </MenuItem>
                                  : tickets[selectedIndex]?.data().status === 0 ?
                                    <MenuItem
                                      onClick={(e) => {
                                        handleCloseMenu();
                                        handleVisible()
                                      }}
                                      disableRipple
                                      value={2}
                                    >
                                      <Typography variant='body1'>Activer</Typography>
                                    </MenuItem> : null
                              }
                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                  handleOpenDeleteModal()
                                }}
                                disableRipple
                                value={2}
                              >
                                <Typography variant='body1'>Supprimer</Typography>
                              </MenuItem>

                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
        </>
      </Container>
    </Page>
  );
}

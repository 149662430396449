/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, Select, MenuItem, Typography, Stack, Container, Grid, Autocomplete, Card, CardHeader, CardContent, Stepper, StepLabel, Step, StepConnector, stepConnectorClasses, styled, CardMedia, Popper, Hidden, FormControl, InputLabel, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, IconButton, Paper, Chip, CircularProgress } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DatePicker } from '@mui/lab';
import PropTypes, { object } from 'prop-types';
import { collection, doc, getDoc, getDocs, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Variables } from '../../../../context/VariableContext';
import Iconify from '../../../../components/Iconify';
import CustomButton from '../../../../components/CustomButton';
import { db } from '../../../../firebase.config';
import { UserAuth } from '../../../../context/AuthContext';
import FacebookPost from './preview/previewMobileFb';
import InstagramPost from './preview/previewMobileInsta';
import CustomDateTimePicker from '../../../../components/CustomDateTimePicker';
import ElementSwitcher from '../../../../components/elementSwitch';
import CustomTag from '../../../../components/customTag';
import { useSnackbar } from '../../../../context/snackbarContext';
import CanvaEditor from '../../../../components/canva/canvaEditor';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,#5E17EB 0%,#5E17EB 50%,#5E17EB 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,#5E17EB 0%,#5E17EB 50%,#5E17EB 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,

    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: 'white',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                backgroundImage:
                    'linear-gradient( 95deg,#5E17EB 0%,#5E17EB 50%,#5E17EB 100%)',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            },
        },
        {
            props: ({ ownerState }) => ownerState.completed,
            style: {
                backgroundImage:
                    'linear-gradient( 95deg,#5E17EB 0%,#5E17EB 50%,#5E17EB 100%)',
            },
        },
    ],
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <Iconify icon={'fluent:people-audience-24-filled'} />,
        2: <Iconify icon={'healthicons:money-bag'} />,
        3: <Iconify icon={'ic:baseline-design-services'} />,
        4: <Iconify icon={'heroicons:rocket-launch-solid'} />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const CreatePaidCampaign = ({ accessToken }) => {
    const { campaignID } = useParams();
    const { user, userData } = UserAuth();
    const showSnackbar = useSnackbar()
    const navigate = useNavigate()
    const [token, setToken] = useState();
    const [fbData, setFbData] = useState();
    const [fbCampagneData, setFbCampagneData] = useState();
    const [selectedAdAccount, setSelectedAdAccount] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [visuelUrl, setVisuelUrl] = useState();
    const { hideNavbar, setHideNavbar } = Variables()
    const [loadingAISuggestion, setLoadingAISuggestion] = useState(false);
    const [aiSuggestion, setAISuggestion] = useState(null);

    const handleAISuggestion = async () => {
        if (!selectedEvent) {
            showSnackbar("Sélectionne d’abord un événement", true);
            return;
        }

        setLoadingAISuggestion(true);

        try {
            const res = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/getCampaignAISuggestion', {
                method: 'POST',
                body: JSON.stringify({
                    title: selectedEvent.data().nomEvent,
                    description: selectedEvent.data().description || '',
                    type: selectedEvent.data().type || '',
                    location: selectedEvent.data().localisationDes || '',
                    token,
                    // date: selectedEvent.data().dateDebut || '',
                    // audienceHint: "", // Optionnel si l’organisateur a défini une cible
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();
            console.error(data);

            if (res.ok) {
                setAISuggestion(data);
                setAge(data.age);
                setGender(data.gender);
                setBudget(data.budget);
                setText(data.text);
                setSelectedInterest(data.interests);
                setSelectedLocations(data.locations);
                showSnackbar("Suggestion IA chargée ✔️", false);
            } else {
                showSnackbar(`Erreur IA : ${data.error}`, true);
            }
        } catch (err) {
            console.error(err);
            showSnackbar("Erreur IA : impossible de générer la suggestion", true);
        }

        setLoadingAISuggestion(false);
    };

    // All function and variable refers to Audience
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const inputRef = useRef(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const handleEventSelect = (selectedEvent) => {
        console.log('Event selected:', selectedEvent);
        setSelectedEvent(selectedEvent)
        // Faire quelque chose avec l'événement sélectionné (ex: le sauvegarder dans la campagne)
    };

    const [interests, setIntersts] = useState([]);
    const [selectedInterst, setSelectedInterest] = useState([]);
    const handleTagChange = async (event, value) => {
        setLoading(true)
        try {
            setSelectedInterest(value);
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };


    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [overlappingLocation, setOverlappingLocation] = useState(null);
    // Gestion du changement de localisation
    const handleLocationChange = (event, newLocations) => {
        if (newLocations.length === 0) {
            setSelectedLocations([]);
            return;
        }

        const newLocation = newLocations[newLocations.length - 1]; // Dernière ajoutée
        const overlapping = checkOverlap(newLocation, selectedLocations);

        if (overlapping) {
            setOverlappingLocation(overlapping);
            return;
        }

        setSelectedLocations(newLocations);
        setOverlappingLocation(null);
    };

    const checkOverlap = (newLocation, existingLocations) => {
        console.log(newLocation)
        for (const loc of existingLocations) {
            if (
                (newLocation.type === "subcity" && loc.type === "city" && newLocation.region_id === loc.region_id) ||
                (newLocation.type === "city" && loc.type === "subcity" && loc.parentId === newLocation.id) ||
                (newLocation.type === "region" && loc.type === "city" && loc.parentId === newLocation.key) ||
                (newLocation.type === "country" && loc.type !== "country" && loc.country_code === newLocation.country_code) ||
                (loc.type === "country" && newLocation.type !== "country" && newLocation.country_code === loc.country_code)
            ) {
                return loc; // Conflit détecté
            }
        }
        return null;
    };


    const [age, setAge] = useState({ min: 17, max: 35 });
    const handleChangeAge = (field, value) => {
        console.log(value)
        setAge(prev => ({ ...prev, [field]: value }));
    }
    const [gender, setGender] = useState('');

    const handleChange = (event) => {
        setGender(event.target.value);
    };

    // fin function and variable for Audience

    // All function and variable refes to budget
    const [budget, setBudget] = useState(0);
    const [typeBudget, setTypeBudget] = useState(0);
    const [preview, setPreview] = useState(0);

    const handleChangeTypeBudget = (event) => {
        setTypeBudget(parseInt(event.target.value));
    };

    // date / time
    const [dateError, setDateError] = useState(false);
    const [dateErrorMsg, setDateErrorMsg] = useState('');
    const [dateDebut, setDateDebut] = useState(null);
    const [timeDebut, setTimeDebut] = useState('all');


    const handleDateDebutChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };
    const [dateFin, setDateFin] = useState(null);
    const [timeFin, setTimeFin] = useState('');

    const handleDateFinChange = (newDate) => {
        setDateFin(newDate);
    };

    const handleTimeFinChange = (newTime) => {
        setTimeFin(newTime);
    };

    /** all function related to design page */
    const [visuelType, setvisuelType] = useState(0);
    const [media, setMedia] = useState(null)
    const [visuel, setvisuel] = useState();
    const [text, setText] = useState("✨ Join us for an unforgettable night at the test event! 🌜 Get ready to dance the night away and make memories that will last a lifetime.Mark your calendars for Saturday, June 7th at 3:00 AM at the stunning 12 Via des Cerises venue. Don’t miss out on this epic event - get your tickets now! 🎉 #test #partytime #nightlife");
    const [titleLink, setTitleLink] = useState('');
    const [linkDesc, setLinkDesc] = useState("Reserver sur Choptonticket!");
    const [btText, setBtText] = useState('Acheter vos tickets');
    const [thumbnail, setThumbnail] = useState("");
    const handleRemoveVisuel = () => {
        console.log(visuel)
        setVisuelUrl()
        setvisuel()
        setMedia(null)
    }

    // Fonction pour extraire une image (thumbnail) à partir de la vidéo
    const generateThumbnail = (videoUrl) => new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = videoUrl;
        video.crossOrigin = "anonymous";
        video.muted = true;
        video.currentTime = 2;

        video.onloadeddata = () => {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext("2d");

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const dataUrl = canvas.toDataURL("image/png");
            resolve(dataUrl);
        };

        video.onerror = (e) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject("Erreur lors du chargement de la vidéo");
        };
    });

    useEffect(() => {
        if (visuel) {
            const url = URL.createObjectURL(visuel)
            setVisuelUrl(url);
            if (visuel.type.startsWith("video/")) {
                generateThumbnail(url).then(thumbnail => {
                    console.log("Thumbnail généré:", thumbnail);
                    setMedia({
                        url,
                        name: visuel.name,
                        object: visuel,
                        type: visuel.type,
                        thumbnail,
                    });
                }).catch(err => {
                    console.error("Erreur génération thumbnail:", err);
                })
            } else {
                setMedia({
                    url,
                    name: visuel.name,
                    object: visuel,
                    type: visuel.type,
                });
            }

        }
    }, [visuel]);

    // fin 

    /** all function related to verification page */
    const [expanded, setExpanded] = useState(false);
    const handleExpanded = (panel) => async (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    // fin 


    // const router = useRouter();


    const saveCampaign = async () => {
        console.log({ event: selectedEvent?.id, age, gender, selectedInterst });
        const campaignRef = doc(db, `organisations/${userData.idOrg}/crm/${userData.idOrg}/paidCampains`, campaignID);
        let mediaUrl = null;
        let thumbnailUrl = null;

        const tmpDateDebut = new Date(dateDebut);
        // Utiliser setHours pour remplacer l'heure et les minutes
        tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
        const tmpDateFin = new Date(dateFin);
        // Utiliser setHours pour remplacer l'heure et les minutes
        tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);

        // Vérifier si un fichier est fourni (image ou vidéo)
        if (visuel) {
            const fileExtension = visuel.name.split('.').pop(); // Récupère l'extension du fichier
            const maxSize = 18 * 1024 * 1024; // 18MB
            const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
            const allowedVideoTypes = ["video/mp4", "video/quicktime"]; // .mov = quicktime

            // Vérifier la taille du fichier
            if (visuel.size > maxSize) {
                alert("Le fichier dépasse la limite de 18MB.");
                return;
            }

            // Vérifier l'extension du fichier
            console.log(visuel.type)
            if (![...allowedImageTypes, ...allowedVideoTypes].includes(visuel.type)) {
                alert("Format non pris en charge. Choisissez une image (.jpg, .png, .jpeg) ou une vidéo (.mp4, .mov).");
                return;
            }
            try {
                const storage = getStorage();
                const fileType = visuel.type.startsWith("video/") ? "videos" : "images";

                const storageRef = ref(storage, `ads/${userData.idOrg}/${campaignID}/${fileType}/${campaignID}.${fileExtension}`);
                const uploadTask = uploadBytesResumable(storageRef, visuel);

                // Attendre l'upload et récupérer l'URL
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log(`Upload en cours: ${progress}%`);
                        },
                        (error) => reject(error),
                        async () => {
                            mediaUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            console.log("Média disponible à :", mediaUrl);
                            resolve();
                        }
                    );
                });
            } catch (error) {
                console.error("Erreur lors de l'upload du média :", error);
                return;
            }
        } else {
            mediaUrl = media.url
        }

        // Vérifier si un fichier est fourni (image ou vidéo)
        if (media.thumbnail) {
            const fileExtension = visuel.name.split('.').pop(); // Récupère l'extension du fichier
            const maxSize = 18 * 1024 * 1024; // 18MB
            const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];

            // Vérifier la taille du fichier
            if (visuel.size > maxSize) {
                alert("Le fichier dépasse la limite de 18MB.");
                return;
            }
            try {
                const storage = getStorage();
                const storageRef = ref(storage, `ads/${userData.idOrg}/${campaignID}/thumbnail/${campaignID}.${fileExtension}`);
                const uploadTask = uploadBytesResumable(storageRef, media.thumbnail);

                // Attendre l'upload et récupérer l'URL
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log(`Upload en cours: ${progress}%`);
                        },
                        (error) => reject(error),
                        async () => {
                            thumbnailUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            console.log("Média disponible à :", mediaUrl);
                            resolve();
                        }
                    );
                });
            } catch (error) {
                console.error("Erreur lors de l'upload du média :", error);
                return;
            }
        } else {
            thumbnailUrl = media.thumbnailUrl
        }

        // Sauvegarde des données dans Firestore
        console.log(media)
        await updateDoc(campaignRef, {
            event: selectedEvent?.id,
            selectedAdAccount,
            selectedPage,
            age,
            gender,
            selectedInterst,
            selectedLocations,
            budget: parseInt(budget),
            typeBudget: parseInt(typeBudget),
            text,
            titleLink,
            linkDesc,
            startAt: Timestamp.fromDate(tmpDateDebut),
            endAt: Timestamp.fromDate(tmpDateFin),
            media: { url: mediaUrl, name: media.name, type: media.type, thumbnail: thumbnailUrl || null }
        });



        console.log("Campagne mise à jour avec succès !");
        return 1
    };

    // convert timestamp

    const createCampaing = async () => {
        const campaignRef = doc(db, `organisations/${userData.idOrg}/crm/${userData.idOrg}/paidCampains`, campaignID);
        try {
            setLoading(true)
            console.log(userData)
            const saveResult = await saveCampaign()
            if (saveResult !== 1) {
                showSnackbar('', true)
            }
            const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/createFacebookAds', {
                method: 'POST',
                body: JSON.stringify({
                    orgId: userData.idOrg,
                    campaignId: campaignID,
                    access_token: token,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log(data)
            if (response.ok) {
                showSnackbar("Camapagne créé avec success", false);
                await updateDoc(campaignRef, {
                    status: 'active',
                });
                navigate(-1, { replace: true })
            } else {
                showSnackbar(data.error || "Une erreur est survenue.", true);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des intérêts:", error);

        } finally {
            setLoading(false);
        }
    }

    const handleTimestamp = (e) => {
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
    };

    const handleTimestampCustom = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
        return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    };

    const handleStep = (index) => {
        setActiveStep(index)
    };

    const handleSteps = (index) => {
        if (index === 4) {
            createCampaing()
            return
        }
        if (index > activeStep) {
            setActiveStep(index)
        } else {
            setActiveStep(index)
        }
        setActiveStep(index)
    };

    useEffect(() => {
        setHideNavbar(true)
    }, []);

    const [activeStep, setActiveStep] = useState(0)

    const steps = ['audience', 'budget', 'Conception', 'lancement'];

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
                const docSnap = await getDoc(docRef0);
                // recuperation de la campagne
                const docRefCampaign = doc(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/paidCampains`, `${campaignID}`);
                const docSnapCampaign = await getDoc(docRefCampaign);
                if (docSnapCampaign.exists()) {
                    setFbCampagneData(docSnapCampaign.data())
                    setAge(docSnapCampaign.data().age)
                    setGender(docSnapCampaign.data().gender)
                    setCurrency(docSnapCampaign.data().currency)
                    setBudget(docSnapCampaign.data().budget)
                    setTypeBudget(docSnapCampaign.data().typeBudget)
                    setText(docSnapCampaign.data().text)
                    setLinkDesc(docSnapCampaign.data().linkDesc)
                    setTitleLink(docSnapCampaign.data().titleLink)
                    setSelectedInterest(docSnapCampaign.data().selectedInterst || [])
                    setSelectedLocations(docSnapCampaign.data().selectedLocations || [])
                    setDateDebut(docSnapCampaign.data().startAt ? handleTimestamp(docSnapCampaign.data().startAt) : null)
                    setTimeDebut(docSnapCampaign.data().startAt ? handleTimestampCustom(docSnapCampaign.data().startAt) : null)
                    setDateFin(docSnapCampaign.data().endAt ? handleTimestamp(docSnapCampaign.data().endAt) : null)
                    setTimeFin(docSnapCampaign.data().endAt ? handleTimestampCustom(docSnapCampaign.data().endAt) : null)
                    // setVisuelUrl(docSnapCampaign.data().mediaUrl)
                    setMedia(docSnapCampaign.data().media)
                    setSelectedAdAccount(docSnapCampaign.data().selectedAdAccount || {})
                    setSelectedPage(docSnapCampaign.data().selectedPage || {})
                }
                // recupération de event
                const q2 = query(collection(db, `evenement`), where('orgId', '==', docSnap.data().idOrg), where('status', '==', 1),
                    orderBy('dateDebut', 'desc'));
                const snapshot2 = await getDocs(q2);
                const tmpevent = []
                snapshot2.forEach(async (d1) => {
                    // console.log(d1.data().nomEvent)
                    tmpevent.push(d1)
                    if (docSnapCampaign.data()?.event === d1.id) {
                        setSelectedEvent(d1)
                    }
                    // setSelectedTags(tmpSelectedTags)

                });
                console.log(tmpevent)
                setEvents(tmpevent);
                // setOrgID(docSnap.data().idOrg)
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, [user, userData, campaignID]);

    useEffect(() => {
        (async () => {
            // setCampaign([]);
            try {
                setLoading(true);
                const q = collection(db, `organisations/${userData.idOrg}/facebookAuthData`);
                const snapshot = await getDocs(q);
                const tmp = snapshot.docs[0]
                setToken(tmp.data().access_token)
                setFbData(tmp.data())
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [userData]);

    const [inputValue, setInputValue] = useState("");
    useEffect(() => {
        if (inputValue.length < 2) return; // Évite d'appeler l'API avec des valeurs trop courtes

        const fetchInterests = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/getFbInterest', {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: token,
                        query: inputValue,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                console.log(data)
                if (response.ok) {
                    setIntersts(data)
                } else {
                    showSnackbar(data.error || "Une erreur est survenue.", true);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des intérêts:", error);
            } finally {
                setLoading(false);
            }
        };

        const timeout = setTimeout(fetchInterests, 500); // Ajoute un délai pour éviter trop d'appels
        return () => clearTimeout(timeout);
    }, [inputValue]);

    const [inputValueLocation, setInputValueLocation] = useState("");
    useEffect(() => {
        if (inputValueLocation.length < 2) return; // Évite d'appeler l'API avec des valeurs trop courtes

        const fetchLocations = async () => {

            setLoading(true);
            try {
                const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/getFacebookLocations', {
                    method: 'POST',
                    body: JSON.stringify({
                        access_token: token,
                        query: inputValueLocation,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                console.log(data)
                if (response.ok) {
                    setLocations(data)
                    // showSnackbar("Invitation envoyée avec succès !");
                } else {
                    // showSnackbar(data.error || "Une erreur est survenue.", true);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des intérêts:", error);
            } finally {
                setLoading(false);
            }
        };

        const timeout = setTimeout(fetchLocations, 500); // Ajoute un délai pour éviter trop d'appels
        return () => clearTimeout(timeout);
    }, [inputValueLocation]);

    return (
        <Box>
            <Box paddingBottom={10}>
                <Box sx={{ padding: 4, bgcolor: '#F5EFFA', position: 'absolute', top: 0, width: '100%', zIndex: 99, }}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='h3'>Creation de votre campagne publicitaire</Typography>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                            {steps.map((label, index) => (
                                <Step sx={{ cursor: 'pointer' }} key={label} onClick={() => handleStep(index)}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>

                </Box>
                <Container maxWidth={'xl'} sx={{ marginTop: 15 }}>
                    <Grid container spacing={2} >
                        <Grid item xs={activeStep === 3 ? 12 : 7}>
                            {
                                activeStep === 0 ?
                                    <>
                                        <Typography variant="h3" gutterBottom>
                                            Audience
                                        </Typography>
                                        {/** selectionné à promouvoir */}
                                        <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>

                                            <Typography variant='subtitle1'>Quel event souhaitez vous promouvoir ?</Typography>
                                            <Typography variant='caption'>Selectionne un event et les information seront remplies automatiquement</Typography>
                                            {/**  selection de l'event */}
                                            <Autocomplete
                                                id="event-selector"
                                                options={events}
                                                getOptionLabel={(event) => event?.data().nomEvent}
                                                value={selectedEvent}
                                                disableClearable
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                onChange={(e, newValue) => {
                                                    handleEventSelect(newValue);
                                                    setOpen(false);
                                                }}
                                                PopperComponent={(props) => <Popper {...props} anchorEl={inputRef.current} placement="bottom-start" />}
                                                renderOption={(props, option) => (
                                                    <Stack
                                                        key={option.id}
                                                        direction="row"
                                                        spacing={1}
                                                        component="li"
                                                        {...props}
                                                        width={'100%'}
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <LazyLoadImage
                                                            src={option.data().idVisuel}
                                                            alt={option.data().nomEvent}
                                                            className="img-lazy"
                                                            width={70}
                                                            height={70}
                                                            style={{ objectFit: "cover" }}
                                                            effect="blur"
                                                        />
                                                        <Box>
                                                            <Typography variant="subtitle1">{option.data().nomEvent}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                dans 3 jours
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {option.data().participant} participants
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                )}
                                                renderInput={(params) => (
                                                    <>
                                                        {/* Champ invisible mais présent dans le DOM */}

                                                        <TextField {...params} inputRef={inputRef} fullWidth sx={{ visibility: 'hidden' }} />
                                                        {/* Carte cliquable qui ouvre la liste des événements */}
                                                        <Card
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                height: 85,
                                                                borderRadius: 2,
                                                                border: "2px solid gainsboro",
                                                            }}
                                                            onClick={() => setOpen(true)}
                                                        >
                                                            {selectedEvent ? (
                                                                <>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ width: 100, height: 100, objectFit: "cover" }}
                                                                        image={selectedEvent.data().idVisuel}
                                                                        alt={selectedEvent.data().nomEvent}
                                                                    />
                                                                    <CardContent>
                                                                        <Typography variant="h6">{selectedEvent.data().nomEvent}</Typography>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            dans 3 jours
                                                                        </Typography>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            {selectedEvent.data().participant} participants
                                                                        </Typography>
                                                                    </CardContent>
                                                                </>
                                                            ) : (
                                                                <CardContent>
                                                                    <Typography variant="body1" color="textSecondary">
                                                                        Clique ici pour sélectionner un événement
                                                                    </Typography>
                                                                </CardContent>
                                                            )}
                                                        </Card>


                                                    </>
                                                )}
                                            />
                                            {/** fin selection de l'event */}
                                            <Stack spacing={2} mt={3}>
                                                <Typography variant="subtitle1">🔮 Aide à la configuration IA</Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    L’intelligence artificielle va analyser ton événement et générer les meilleurs paramètres pour ta campagne Facebook.
                                                </Typography>

                                                <CustomButton
                                                    type="primaire"
                                                    buttonText="💡 Obtenir une suggestion IA"
                                                    onClick={handleAISuggestion}
                                                    loading={loadingAISuggestion}
                                                />
                                            </Stack>
                                        </Box>
                                        {/** Ajout des intérets */}
                                        <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                                            <Stack direction='row' justifyContent='space-between'>
                                                <Stack>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant="subtitle1">Audience cible</Typography>
                                                    </Stack>
                                                    <Typography variant="caption">Selectionner les intéret de votre audience cible</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack spacing={3} paddingTop={3}>


                                                <Stack spacing={1} justifyContent={'start'} alignItems={'start'}>
                                                    <Autocomplete
                                                        fullWidth
                                                        multiple
                                                        id="tags-standard"
                                                        options={interests}
                                                        getOptionLabel={(interest) => interest?.name}
                                                        value={selectedInterst}
                                                        onInputChange={(event, newValue) => setInputValue(newValue)}
                                                        onChange={handleTagChange}
                                                        loading={loading}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder={selectedInterst?.length === 0 ? 'Selectionner les intéret' : null}
                                                                // error={!!error}
                                                                multiline
                                                                // helperText={error}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <>
                                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                        renderTags={(tagValue, getTagProps) =>
                                                            tagValue.map((interest, index) => (
                                                                <Chip
                                                                    sx={{ bgcolor: '#F5EFFA' }}
                                                                    key={interest?.id}
                                                                    label={interest?.name}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))
                                                        }
                                                    />

                                                    <CustomButton size="small" type={'tertiaire'} buttonText={"Besoin pour l'ajout d'intéret"} />
                                                </Stack>
                                            </Stack>

                                        </Box>
                                        {/** age et genre */}
                                        <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="subtitle1">Age / genre</Typography>
                                            </Stack>
                                            <Typography variant="caption">Quel est le sujet de cette campagne</Typography>
                                            <Stack paddingY={2} spacing={4}>
                                                <Stack spacing={1}>
                                                    <Typography>Age</Typography>
                                                    <Stack direction={'row'} spacing={2}>
                                                        <TextField placeholder='min' value={age?.min} onChange={(e) => handleChangeAge('min', e.target.value)} />
                                                        <TextField placeholder='max' value={age?.max} onChange={(e) => handleChangeAge('max', e.target.value)} />
                                                    </Stack>
                                                </Stack>

                                                <Stack spacing={1}>
                                                    <Typography>Genre</Typography>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={gender}
                                                            label="Genre"
                                                            onChange={handleChange}
                                                            sx={{ maxWidth: 460 }}
                                                        >
                                                            <MenuItem value={'all'}>Homme</MenuItem>
                                                            <MenuItem value={'F'}>Femme</MenuItem>
                                                            <MenuItem value={'M'}>Tous les genres</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Stack>



                                            </Stack>

                                        </Box>
                                        {/** localisation */}
                                        <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                                            <Stack direction="column" spacing={1}>
                                                <Typography variant="subtitle1">Localisation</Typography>
                                                <Autocomplete
                                                    fullWidth
                                                    multiple
                                                    id="tag-standard"
                                                    options={locations}
                                                    getOptionLabel={(location) => location?.name}
                                                    value={selectedLocations}
                                                    onInputChange={(event, newValue) => setInputValueLocation(newValue)}
                                                    onChange={handleLocationChange}
                                                    loading={loading}
                                                    renderOption={(props, option) => {
                                                        const locationHierarchy = [
                                                            option.name,
                                                            option.city,
                                                            option.region,
                                                            option.country_code
                                                        ].filter(Boolean).join(", "); // Supprime les valeurs undefined/null et joint par ", "
                                                        return (
                                                            <li {...props} key={option?.id}>
                                                                {locationHierarchy} ({option?.type}) {/* Affiche Nom (Type) */}
                                                            </li>
                                                        )
                                                    }
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            helperText={overlappingLocation ? `Conflit détecté avec ${overlappingLocation.name}` : ""}
                                                            error={!!overlappingLocation}
                                                            placeholder={selectedLocations?.length === 0 ? 'Saisir une slocalisation (pays, ville, region, etc)...' : null}
                                                            // error={!!error}
                                                            multiline
                                                            // helperText={error}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    renderTags={(tagValue, getTagProps) =>
                                                        tagValue.map((location, index) => {
                                                            // Construire le tag complet avec subcity, city, region, country
                                                            console.log(location)
                                                            const locationHierarchy = [
                                                                location.name,
                                                                location.city,
                                                                location.region,
                                                                location.country_code
                                                            ].filter(Boolean).join(", "); // Supprime les valeurs undefined/null et joint par ", "
                                                            console.log(locationHierarchy)
                                                            return (
                                                                <Chip
                                                                    sx={{ bgcolor: '#F5EFFA' }}
                                                                    key={location?.id}
                                                                    label={locationHierarchy} // Affichage du tag
                                                                    {...getTagProps({ index })}
                                                                />
                                                            );
                                                        })
                                                    }
                                                />
                                            </Stack>

                                        </Box>
                                        {/** boutton de navigation */}

                                    </>
                                    : activeStep === 1 ?
                                        <>
                                            <Typography variant="h3" gutterBottom>
                                                Budget & planification
                                            </Typography>
                                            {/** Amount  */}
                                            <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography variant="subtitle1">Budget</Typography>
                                                </Stack>
                                                <Typography variant="caption">Combien souhaitez vous allouer pour votre campagne</Typography>
                                                <Stack paddingY={2} spacing={4}>
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={typeBudget}
                                                            onChange={handleChangeTypeBudget}
                                                        >
                                                            <FormControlLabel value={0} control={<Radio />} label="Budget Total" />
                                                            <FormControlLabel value={1} control={<Radio />} label="Par jour" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <Stack spacing={1}>
                                                        <Typography>Montant</Typography>
                                                        <TextField type='number' placeholder='montant alloué' value={budget} onChange={(e) => setBudget(e.target.value)} InputProps={{

                                                            endAdornment: (
                                                                <>
                                                                    <Typography variant='caption' color="inherit" size={20}> {currency}</Typography>

                                                                </>
                                                            ),
                                                        }} />
                                                        <Typography variant='caption'>Suggestion de budget : 200 {currency}</Typography>
                                                    </Stack>



                                                </Stack>

                                            </Box>
                                            <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography variant="subtitle1">Planification</Typography>
                                                </Stack>
                                                <Typography variant="caption">Date de debut et de fin de votre campagne</Typography>
                                                <Stack paddingY={2} spacing={1} >
                                                    <Box>
                                                        <Typography>Début</Typography>
                                                    </Box>
                                                    <Stack spacing={1}>
                                                        <CustomDateTimePicker date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                                                    </Stack>
                                                    <Box paddingTop={2}>
                                                        <Typography>Fin</Typography>
                                                    </Box>
                                                    <Stack spacing={1}>
                                                        <CustomDateTimePicker date={dateFin} time={timeFin} minDate={dateDebut || null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                                                    </Stack>
                                                </Stack>

                                            </Box>
                                        </>
                                        : activeStep === 2 ?
                                            <>
                                                <Typography variant="h3" gutterBottom>
                                                    Design
                                                </Typography>
                                                {/** Amount  */}
                                                <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>

                                                    <Typography variant="subtitle1">Evenement lié à ta campagne</Typography>
                                                    <Stack paddingY={2} spacing={4}>
                                                        {
                                                            selectedEvent &&
                                                            <Card sx={{ padding: 2, boxShadow: 5, borderRadius: 5 }}>
                                                                <Stack
                                                                    key={selectedEvent.id}
                                                                    direction="row"
                                                                    spacing={2}
                                                                    component="li"
                                                                    width={'100%'}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    <LazyLoadImage
                                                                        src={selectedEvent.data().idVisuel}
                                                                        alt={selectedEvent.data().nomEvent}
                                                                        className="img-lazy"
                                                                        width={85}
                                                                        height={85}
                                                                        style={{ objectFit: "cover", borderRadius: 10 }}
                                                                        effect="blur"
                                                                    />
                                                                    <Box>
                                                                        <Typography variant="subtitle1">{selectedEvent.data().nomEvent}</Typography>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            dans 3 jours
                                                                        </Typography>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            {selectedEvent.data().participant} participants
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Card>
                                                        }
                                                    </Stack>
                                                </Box>
                                                <Box sx={{ padding: 3, marginY: 3, border: '2px solid gainsboro', borderRadius: '7px' }}>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant="subtitle1">Information de base</Typography>
                                                    </Stack>
                                                    <Typography variant="caption">Date de debut et de fin de votre campagne</Typography>
                                                    <Stack paddingY={2} spacing={4}>

                                                        <Stack spacing={1}>

                                                            {media && (<Card sx={{ padding: 2, boxShadow: 5, borderRadius: 5 }}>
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={2}
                                                                    component="li"
                                                                    width={'100%'}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent={'space-between'}
                                                                >
                                                                    {
                                                                        media.type.startsWith("video/") ?
                                                                            <LazyLoadImage
                                                                                src={media.thumbnail}
                                                                                alt={media.thumbnail}
                                                                                className="img-lazy"
                                                                                width={60}
                                                                                height={60}
                                                                                style={{ objectFit: "cover", borderRadius: 10 }}
                                                                                effect="blur"
                                                                            /> :
                                                                            <LazyLoadImage
                                                                                src={media.url}
                                                                                alt={media.url}
                                                                                className="img-lazy"
                                                                                width={60}
                                                                                height={60}
                                                                                style={{ objectFit: "cover", borderRadius: 10 }}
                                                                                effect="blur"
                                                                            />
                                                                    }
                                                                    <Typography variant='body2'>{media?.name}</Typography>
                                                                    <CustomButton type={'secondaire'} buttonText={'supprimer'} onClick={() => handleRemoveVisuel()} />
                                                                </Stack>
                                                            </Card>)}
                                                            {!media && (

                                                                <Box >
                                                                    <Button
                                                                        fullWidth
                                                                        disableElevation
                                                                        size="medium"
                                                                        sx={{
                                                                            marginTop: 2,
                                                                            textTransform: 'initial',
                                                                            boxShadow: 'none',
                                                                            border: '1px dashed gainsboro',
                                                                            color: 'black',
                                                                            backgroundColor: '#f2f2f2',
                                                                            '&:hover': {
                                                                                backgroundColor: '#f2f2f2',
                                                                                color: '#5E17EB',
                                                                                borderColor: '#5E17EB',
                                                                            },
                                                                        }}
                                                                        variant="contained"
                                                                        component="label"
                                                                    >
                                                                        <input
                                                                            hidden
                                                                            accept="image/*, video/*"
                                                                            id="select-image-button"
                                                                            type="file"
                                                                            onChange={(e) => setvisuel(e.target.files[0])}
                                                                        />
                                                                        <Stack
                                                                            padding={2}
                                                                            sx={{
                                                                                justifyContent: 'space-around',
                                                                                alignItems: 'center',

                                                                            }}
                                                                        >

                                                                            <>
                                                                                <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, }} />
                                                                                <Typography variant='caption'><strong>Cliquer ici pour ajouter une image ou video</strong></Typography>
                                                                                <Typography variant='caption'>Dimension dimension: 1080px1080px</Typography>
                                                                            </>

                                                                        </Stack>
                                                                    </Button>
                                                                </Box>

                                                            )}
                                                        </Stack>
                                                        
                                                        <Stack spacing={1}>
                                                            <Typography>Text</Typography>
                                                            <TextField
                                                                id="outlined-multiline-flexible"
                                                                multiline
                                                                rows={4}
                                                                value={text}
                                                                onChange={(e) => setText(e.target.value)}
                                                                helperText={"This text is generated using Chat GPT-4o and is subject to Open AI's . Remember to review and edit your content for accuracy prior to publishing."}
                                                            />
                                                        </Stack>
                                                        <Stack spacing={1}>
                                                            <Typography>Titre du lien</Typography>
                                                            <TextField value={titleLink} onChange={(e) => setTitleLink(e.target.value)} />
                                                        </Stack>
                                                        <Stack spacing={1}>
                                                            <Typography>Description du lien</Typography>
                                                            <TextField value={linkDesc} onChange={(e) => setLinkDesc(e.target.value)} />
                                                        </Stack>
                                                        <Stack spacing={1}>
                                                            <Typography>Bouton</Typography>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={btText}
                                                                    label="Genre"
                                                                    onChange={(e) => setBtText(e.target.value)}
                                                                >
                                                                    <MenuItem value={'En savoir plus'}>En savoir plus</MenuItem>
                                                                    <MenuItem value={'Acheter vos tickets'}>Acheter vos tickets</MenuItem>
                                                                    <MenuItem value={"S'inscrire"}>S'inscrire</MenuItem>
                                                                    <MenuItem value={'Prend ta place'}>Prend ta place</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Stack>
                                                    </Stack>

                                                </Box>
                                            </>
                                            : activeStep === 3 ?
                                                <>
                                                    <Typography variant="h3" gutterBottom>
                                                        Revue et lancement
                                                    </Typography>
                                                    {   /** informtion sur le compte de service */}
                                                    <Card sx={{ padding: 1.5, boxShadow: 5 }}>
                                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                            <Stack alignItems={'center'}>
                                                                <Typography variant='subtitle1'>Pages Facebook</Typography>
                                                                <ElementSwitcher accounts={fbData.pages} defaut={selectedPage?.index ? selectedPage.index : fbData.defaultPage} onSelect={(account, index) => setSelectedPage({ index, id: account.id, name: account.name })} />
                                                            </Stack>
                                                            <Stack alignItems={'center'}>
                                                                <Typography variant='subtitle1'>Comptes publicitaires</Typography>
                                                                <ElementSwitcher accounts={fbData.adAccounts} defaut={selectedAdAccount.index ? selectedAdAccount?.index : fbData.defaultAdAccount} onSelect={(account, index) => setSelectedAdAccount({ index, id: account.id, name: account.name, })} />
                                                            </Stack>
                                                            <Stack direction={'row'} spacing={1}>
                                                                <Stack spacing={1} alignItems={'center'}>
                                                                    <Typography variant='subtitle1'>connecté en tant que </Typography>
                                                                    <CustomTag text={`${fbData?.userData.name}`} />
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                    </Card>
                                                    <Stack spacing={4} paddingY={4}>
                                                        {/** check up */}
                                                        <Stack>
                                                            <Typography variant='h5'>Verification</Typography>
                                                            <Stack>
                                                                <Stack>
                                                                    <Typography>Connectez votre compte facebook</Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                        {/** Campaign summary */}
                                                        <Stack spacing={2}>
                                                            <Typography variant='h5'>Resume de la campagne</Typography>
                                                            <Stack spacing={1}>
                                                                <Accordion
                                                                    expanded={expanded === 'panel1'}
                                                                    onChange={handleExpanded('panel1')}
                                                                    sx={{ bgcolor: '#f2f2f2' }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                    >
                                                                        <Stack spacing={2} sx={{ width: '100%' }} direction="row" alignItems="center">
                                                                            <Typography variant='h5'>Audience</Typography>


                                                                            <IconButton onClick={(e) => {
                                                                                setActiveStep(0)
                                                                                e.stopPropagation()
                                                                            }}>
                                                                                <Iconify icon="lucide:edit" sx={{ width: 20, height: 20 }} />
                                                                            </IconButton>


                                                                        </Stack>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography variant="caption">Nombre minimum et/ou maximum d’évènements pour lesquels vos contacts ont acheté des billets.</Typography>
                                                                        <Stack paddingY={1} spacing={2} direction="row" alignItems="center">
                                                                            d
                                                                        </Stack>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion
                                                                    expanded={expanded === 'panel2'}
                                                                    onChange={handleExpanded('panel2')}
                                                                    sx={{ bgcolor: '#f2f2f2' }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel2bh-header"
                                                                    >
                                                                        <Stack spacing={1} direction="row" alignItems="center">
                                                                            <Typography variant='h5'>Budget & Planification</Typography>

                                                                            <IconButton onClick={(e) => {
                                                                                setActiveStep(1)
                                                                                e.stopPropagation()
                                                                            }}>
                                                                                <Iconify icon="lucide:edit" sx={{ width: 20, height: 20 }} />
                                                                            </IconButton>

                                                                        </Stack>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography variant="caption">Nombre minimum et/ou maximum d’évènements pour lesquels vos contacts ont acheté des billets.</Typography>
                                                                        <Stack paddingY={1} spacing={2} direction="row" alignItems="center">
                                                                            d
                                                                        </Stack>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion
                                                                    expanded={expanded === 'panel3'}
                                                                    onChange={handleExpanded('panel3')}
                                                                    sx={{ bgcolor: '#f2f2f2', mb: 2 }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel3bh-header"
                                                                    >
                                                                        <Stack spacing={1} direction="row" alignItems="center">
                                                                            <Typography variant='h5'>Design</Typography>

                                                                            <IconButton onClick={(e) => {
                                                                                setActiveStep(2)
                                                                                e.stopPropagation()
                                                                            }}>
                                                                                <Iconify icon="lucide:edit" sx={{ width: 20, height: 20 }} />
                                                                            </IconButton>

                                                                        </Stack>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography variant="caption">Nombre minimum et/ou maximum d’évènements pour lesquels vos contacts ont acheté des billets.</Typography>
                                                                        <Stack paddingY={1} spacing={2} direction="row" alignItems="center">
                                                                            d
                                                                        </Stack>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>

                                                </> : null

                            }


                        </Grid>
                        {
                            activeStep !== 3 &&
                            <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                {
                                    activeStep === 2 ?
                                        <Box sx={{ width: '100%', height: '100%', bgcolor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Stack direction={'row'} paddingTop={2} paddingBottom={5}>
                                                <CustomButton onClick={() => setPreview(0)} buttonText={'Facebook'} />
                                                <CustomButton onClick={() => setPreview(1)} buttonText={'Insta'} />
                                            </Stack>
                                            <Stack justifyContent={'center'} alignItems={'center'}>
                                                {
                                                    preview === 0 ?
                                                        <FacebookPost text={text} media={media} linkDesc={linkDesc} linkTitle={titleLink} btText={btText} />
                                                        : preview === 1 ?
                                                            <InstagramPost text={text} media={media} visuelPreview={visuelUrl} visuel={visuel} linkDesc={linkDesc} linkTitle={titleLink} btText={btText} />
                                                            : null
                                                }
                                            </Stack>
                                        </Box>
                                        :
                                        <Box sx={{ width: '100%', padding: 5 }}>
                                            <Stack spacing={4}>
                                                <Card sx={{ padding: 4 }}>

                                                    <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                                                        <Iconify icon={'fluent:people-audience-20-regular'} sx={{ width: 100, height: 100 }} />
                                                        <Typography variant='subtitle1'>Estimation de taille de l'audience </Typography>
                                                        <Typography variant='h5'>9.1M - 11M</Typography>
                                                    </Stack>

                                                </Card>
                                                <Stack justifyContent={'center'} alignItems={'center'}>
                                                    <Typography variant='subtitle1'>Estimation de taille de l'audience </Typography>
                                                    <Typography variant='h5'>9.1M - 11M</Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>

                                }


                            </Grid>
                        }

                    </Grid>

                </Container>
            </Box>
            <Container>
                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        // borderTop: '2px solid gainsboro',
                        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                    }}
                >
                    <CustomButton
                        type='secondaire'
                        buttonText='enregistrer et quitter'
                        loading={loading}
                        onClick={saveCampaign}
                    />

                    <Stack direction='row' spacing={1}>
                        {
                            activeStep > 0 && <CustomButton
                                type='secondaire'
                                buttonText='Revenir en arriere'
                                loading={loading}
                                onClick={() => setActiveStep(activeStep - 1)}
                            />
                        }

                        <CustomButton
                            type='primaire'
                            buttonText={`${activeStep === 3 ? 'Confirmer' : 'Suivant'}`}
                            loading={loading}
                            onClick={() => handleSteps(activeStep + 1)}
                        />
                    </Stack>

                </Stack>
            </Container>
        </Box>
    );
};

export default CreatePaidCampaign;


/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable import/order */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Backdrop,
    CircularProgress,
    Grid,
    LinearProgress,
    TextField,
    TableHead,
    IconButton,
    Box,
    Skeleton,
    Divider,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
// mock
import { db } from '../../../firebase.config';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { shortNumber } from '../../../utils/formatNumber';
import CustomTag from '../../../components/customTag';
import Swipeable from '../../crm/component/swiper';
import { Variables } from '../../../context/VariableContext';

// ----------------------------------------------------------------------

export default function ShowSeance() {
    const { openDrawer, setOpenDrawer, generalReload, setGeneralReload } = Variables();
    const { type, setType } = Variables();
    const { eventID } = useParams();
    const { seanceID } = useParams();
    const [modif, setModif] = useState(false);
    // loading
    const [loading, setLoading] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };
    // for change value of globla quota
    const [open, setOpen] = useState(false);
    const [refreh, setRefresh] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        // setModif(false);
        setRefresh((prev) => !prev);
    };

    // take tickets from firebase
    const [event, setEvent] = useState();
    const [seance, setSeance] = useState();
    const [tickets, setTickets] = useState([]);
    const [quotas, setQuotas] = useState([]);
    const [count, setCount] = useState(0);
    const [statusB, setStatusB] = useState([]);
    const [modifQuota, setModifQuota] = useState(false);

    const handleTime = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        return tmp[0];
    };
    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
    };

    const handleTimestampCustom = (e) => {
        const date = e.toDate();
        return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
    };

    // update status séance

    const updateState = async () => {
        const tmpBillets = seance.data().billets;
        try {
            handleToggle();
            const statRef = doc(db, `evenement/${eventID}/seances`, `${seance.id}`);
            if (seance.data().status === 1) {
                await updateDoc(statRef, {
                    status: parseInt(0),
                });
            } else {
                await updateDoc(statRef, {
                    status: parseInt(1),
                });
            }
            setGeneralReload(!generalReload)
            handleClose();
        } catch (error) {
            setRefresh(!refreh);
            handleClose();
            console.log(error.message);
        }

        console.log(tmpBillets);
    };

    const navigate = useNavigate();

    // recuperation des dates
    useEffect(() => {
        (async () => {
            // setTickets([]);
            try {
                setLoading(true);
                // event
                const docRef0 = doc(db, 'evenement', eventID);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                    setEvent(docSnap.data());
                }
                // date info
                const dateRef = doc(db, `evenement/${eventID}/seances`, `${seanceID}`);
                const snapDate = await getDoc(dateRef);
                if (snapDate.exists()) {
                    setSeance(snapDate);
                }

                // recuperation des tickets
                const q1 = query(collection(db, `evenement/${eventID}/tickets`));
                const snapshot1 = await getDocs(q1);
                const tmpStatus = [];
                const tmpQuota = [];
                let tmpCount = 0;
                setTickets([])
                snapshot1.forEach((d) => {
                    setTickets((tickets) => [...tickets, d]);
                    console.log('ticket')
                    console.log(d.id)
                    // console.log(snapDate.data()?.billets)
                    if (snapDate.data()?.billets?.find(({ id }) => id === d.id)) {
                        tmpQuota.push(
                            snapDate.data().billets[
                                snapDate.data().billets.findIndex(({ id }) => id === d.id)
                            ].quota
                        );
                        if (
                            snapDate.data().billets[
                                snapDate.data().billets.findIndex(({ id }) => id === d.id)
                            ].status === 1
                        ) {
                            tmpStatus.push(true);
                            tmpCount += 1;
                        } else {
                            tmpStatus.push(0);
                        }
                        console.log('trouvé')
                    } else {
                        console.log('pas trouvé')
                        tmpQuota.push(0);
                        tmpStatus.push(false);
                    }

                });
                setDateDebut(handleTimestamp(snapDate.data().date))
                setTimeDebut(handleTimestampCustom(snapDate.data().date))
                setDebut(handleTime(snapDate.data().date));
                setNom(snapDate.data().name)
                setLieu(snapDate.data().lieu_event);
                setVille(snapDate.data().city);
                setPays(snapDate.data().country);
                setCount(tmpCount);
                setQuotas(tmpQuota);
                setStatusB(tmpStatus);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [eventID, seanceID, generalReload]);





    // supression d'un billet
    const handleSupp = async () => {
        handleToggle();
        console.log('supp');
        if (seance.data().participants === 0) {
            try {
                await deleteDoc(doc(db, `evenement/${eventID}/seances`, `${seance.id}`));
                navigate(-1)
            } catch (error) {
                console.log(error.message);
                handleClose();
            }
        }
        handleClose();
    };
    const [nom, setNom] = useState('');
    const [debut, setDebut] = useState('');

    // handle location
    const searchOptions = {
        componentRestrictions: { country: 'CIV' },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['establishment'],
    };
    const [address, setAddress] = useState('');
    const [lieuError, setLieuError] = useState(false);
    const [lieu, setLieu] = useState('');
    const [ville, setVille] = useState('');
    const [pays, setPays] = useState('');
    const [location, setLocation] = useState('');
    const handleSelect = async (address, placeId, suggestion) => {
        console.log(suggestion.description);
        const tmp = suggestion.description.split(',');
        setLieu(tmp[0]);
        setVille(tmp[tmp.length - 2]);
        setPays(tmp[tmp.length - 1]);
        setAddress(suggestion.description);
        const results = await geocodeByAddress(address);
        const ll = await getLatLng(results[0]);
        setLocation(ll);
    };

    // date / time
    const [dateError, setDateError] = useState(false);
    const [dateErrorMsg, setDateErrorMsg] = useState('');
    const [dateDebut, setDateDebut] = useState(null);
    const [timeDebut, setTimeDebut] = useState('');


    const handleDateDebutChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };

    const handleUpdateSeance = () => {
        setType(7)
        setOpenDrawer(true)
    };

    return (
        <Page title="Seance">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {loading && <LinearProgress />}
            {
                seance ?
                    <Container>
                        <Stack paddingTop={2} spacing={3}>
                            <Stack direction="row" justifyContent="space-between" mb={2}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'initial',
                                        border: '2px solid transparent',
                                        color: 'black',
                                        backgroundColor: 'white',
                                        borderRadius: '7px',
                                        borderColor: 'black',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: 'black',
                                            color: 'white',
                                            borderColor: 'black',
                                        },
                                    }}
                                    startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                                    onClick={() => navigate(-1)}
                                >
                                    retour
                                </Button>
                                <Stack direction="row" spacing={1}>
                                    <LoadingButton
                                        sx={{
                                            textTransform: 'initial',
                                            border: '2px solid transparent',
                                            color: 'white',
                                            backgroundColor: 'red',
                                            borderRadius: '7px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                color: 'red',
                                                borderColor: 'red',
                                            },
                                        }}
                                        disabled={seance?.data().participants === 0 ? false : true}
                                        onClick={handleSupp}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        Supprimer
                                    </LoadingButton>
                                    <LoadingButton
                                        sx={{
                                            textTransform: 'initial',
                                            border: '2px solid transparent',
                                            color: 'white',
                                            backgroundColor: '#5E17EB',
                                            borderRadius: '7px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                color: '#5E17EB',
                                                borderColor: '#5E17EB',
                                            },
                                        }}
                                        onClick={updateState}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {seance.data().status === 1
                                            ? 'Desactiver la seance'
                                            : 'Activer la séance'}
                                    </LoadingButton>
                                </Stack>
                            </Stack>


                            <Stack
                                direction='column'
                                spacing={{ xs: 1, sm: 2, md: 4 }}
                                padding={2}
                                sx={{ bgcolor: '#daccff', borderRadius: '10px' }}
                            >
                                <Stack direction='column'>
                                    <Box><CustomTag text={seance.data().status === 1 ? 'seance active' : 'seance inactive'} type={seance.data().status === 1 ? null : 'error'} /></Box>

                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {format(new Date(handleTimestamp(seance.data().date)), 'EEEE dd MMMM yyyy', { locale: fr })
                                                .toString()
                                                .toLocaleUpperCase()}{' '}
                                            - {handleTimestampCustom(seance.data().date)}
                                        </Typography>
                                        <IconButton onClick={handleUpdateSeance}><Iconify icon={'eos-icons:content-modified'} sx={{ width: 24, height: 24 }} /></IconButton>
                                    </Stack>
                                    <Typography variant='subtitle1'>{(nom !== null && nom !== '') ? nom : '-'}</Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} justifyContent={'space-between'} >
                                    <Box sx={{ padding: 2, width: '100%' }}>
                                        <Stack spacing={2}>
                                            <Typography variant='body1'><strong>Revenues nettes</strong></Typography>
                                            {
                                                loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{shortNumber(seance.data().recette)} CFA</Typography>
                                            }
                                        </Stack>
                                    </Box>
                                    <Box sx={{ padding: 2, width: '100%' }}>
                                        <Stack spacing={2}>
                                            <Typography variant='body1'><strong>Tickets vendus</strong></Typography>
                                            {
                                                loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{shortNumber(seance.data().participants)}</Typography>
                                            }
                                        </Stack>
                                    </Box>
                                    <Box sx={{ padding: 2, width: '100%' }}>
                                        <Stack spacing={2}>
                                            <Typography variant='body1'><strong>Billets actifs</strong></Typography>
                                            {
                                                loading ? <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" /> : <Typography variant='h4'>{count} / {tickets.length}</Typography>
                                            }

                                        </Stack>
                                    </Box>

                                </Stack>
                            </Stack>

                            <Box>
                                <Box>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                            <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                                                <TableRow>
                                                    <TableCell>Billets</TableCell>
                                                    <TableCell align="right">Montant</TableCell>
                                                    <TableCell align="right">Ventes</TableCell>
                                                    <TableCell align="right">Quotas</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tickets.map((ticket, index) => (
                                                    <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                                                        <TableCell component="th" scope="row">
                                                            {ticket.data().ticket_name}
                                                        </TableCell>
                                                        <TableCell align="right">{shortNumber(ticket.data().price)} CFA</TableCell>
                                                        <TableCell align="right">
                                                            {seance?.data()?.billets?.find(({ id }) => id === ticket.id) ? (
                                                                <>
                                                                    {seance.data().billets[
                                                                        seance.data().billets.findIndex(({ id }) => id === ticket.id)
                                                                    ].sell ? (
                                                                        shortNumber(
                                                                            seance.data().billets[
                                                                                seance.data().billets.findIndex(({ id }) => id === ticket.id)
                                                                            ].sell)
                                                                    ) : (
                                                                        <div>-</div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <div>-</div>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">

                                                            {quotas[index] !== 0 && !modifQuota ? (
                                                                shortNumber(quotas[index])
                                                            ) : quotas[index] === 0 && !modifQuota ? (
                                                                <>-</>
                                                            ) : (
                                                                <TextField
                                                                    size="small"
                                                                    value={quotas[index]}
                                                                    onChange={(e) => {
                                                                        setQuotas((existItem) => [
                                                                            ...existItem.slice(0, index),
                                                                            e.target.value,
                                                                            ...existItem.slice(index + 1),
                                                                        ]);
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">

                                                            {
                                                                statusB[index] ? <CustomTag text={'actif'} type={'success'} /> : <CustomTag text={'inactif'} type={'error'} />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Stack>
                    </Container>

                    : null
            }
            <Swipeable type={type} eventID={eventID} seanceID={seanceID} seanceData={seance?.data()} />
        </Page>
    );
}

import React, { createContext, useContext, useState, useEffect } from 'react';
import { setDoc, doc, collection, Timestamp, serverTimestamp, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth'
import { auth, db } from '../firebase.config';



const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [user, setUser] = useState({})
    const [userData, setUserData] = useState()
    const [event, setCurrentEvent] = useState({})
    const [permissions, setPermissions] = useState([]);


    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }



    function signUpSecondStep(id, mail, idorg) {

        const newCityRef = doc(db, "organisateurs", id);
        setDoc(newCityRef, {
            id,
            role: { id: 0, name: 'super Admin' },
            dateCreation: serverTimestamp(),
            email: mail,
            idOrg: idorg,
        });
    }

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }



    function logOut() {
        setUserData()
        return signOut(auth)
    }

    function addEvent(name, dateDebut, dateFin, location, idVisuel) {

    }

    function resetpass(oobCode, newpass) {
        return confirmPasswordReset(auth, oobCode, newpass)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            console.log(currentUser);
            if (currentUser) {
                setUser(currentUser);
                await fetchPermissions(currentUser.uid);
            } else {
                setPermissions({});
            }
        });
        return () => {
            unsubscribe();
        };
    }, []); // Ajout du tableau de dépendances pour éviter un rendu infini

    useEffect(() => {
        (async () => {
            if (user != null) {
                try {
                    const docRef0 = doc(db, 'users', user?.uid);
                    const docSnap = await getDoc(docRef0);
                    if (docSnap.exists()) {
                        setUserData(docSnap.data())
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [user]);

    const fetchPermissions = async (userId) => {
        try {
            console.log('fetch permission-------------------')
            const memberRef = doc(db, `organisateurs`, userId);
            const memberSnap = await getDoc(memberRef);

            if (memberSnap.exists()) {
                const { roleId, idOrg } = memberSnap.data();

                const roleRef = doc(db, `organisations/${idOrg}/roles`, roleId);
                const roleSnap = await getDoc(roleRef);
                console.log(roleSnap.data())
                if (roleSnap.exists()) {
                    if (roleSnap.data().permissionId) {
                        const baseRoleRef = doc(db, 'admins/basedRole/roles', `${roleSnap.data().permissionId}`);
                        const baseRoleSnap = await getDoc(baseRoleRef);
                        if (baseRoleSnap.exists()) {
                            const roleData = baseRoleSnap.data().permissions || {};
                            setPermissions(roleData); // Stocker sous forme de MAP
                        } else {
                            setPermissions({}); // Si le rôle n'existe pas, éviter un undefined
                        }
                    } else {
                        const roleData = roleSnap.data().permissions || {};
                        setPermissions(roleData); // Stocker sous forme de MAP
                    }

                } else {
                    setPermissions({}); // Si le rôle n'existe pas, éviter un undefined
                }
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des permissions :", error);
            setPermissions({}); // Sécuriser le state en cas d'erreur
        }
    };





    return (
        <AuthContext.Provider value={{ signUp, logIn, logOut, addEvent, signUpSecondStep, resetpass, user, userData, permissions }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => useContext(AuthContext);
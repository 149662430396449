/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import { filter, forEach } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput,
    MenuItem,
    useTheme,
    Tooltip,
    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Link,
    FormControlLabel,
    Autocomplete,
    Divider,
    Alert,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import EmailEditor from 'react-email-editor';
import { httpsCallable } from 'firebase/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toPng } from 'html-to-image';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';

import Iconify from '../../../../../../components/Iconify';


import { db, functions } from '../../../../../../firebase.config';
import CheckCard from '../../../../../../components/CheckCard';
import { UserAuth } from '../../../../../../context/AuthContext';
import CustomButton from '../../../../../../components/CustomButton';
import { Variables } from '../../../../../../context/VariableContext';
import { useSnackbar } from '../../../../../../context/snackbarContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '5px',
    boxShadow: 0,
    p: 3,
};

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Perfs() {




    return (

        <Box paddingBottom={10} sx={{ width: '100%' }} padding={2}>
            <Stack spacing={2} padding={2}>
                <Typography variant='h6'>Performance de la campagne</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ boxShadow: 5, borderRadius: '10px' }}>
                            <CardContent>

                                <Box display="flex" justifyContent="space-between" mt={1}>
                                    <Box>
                                        <Typography variant="body2">🖱️ Clics :</Typography>
                                        <Typography variant="h6">10</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2">👁️ Impressions :</Typography>
                                        <Typography variant="h6">20</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2">💵 CPC :</Typography>
                                        <Typography variant="h6">20 €</Typography>
                                    </Box>
                                </Box>


                            </CardContent>
                        </Card>
                        { /* <Card sx={{ boxShadow: 5, borderRadius: '10px' }}>
                                                    <Box mt={4}>
                                                        <Typography variant="h6" gutterBottom>
                                                            📊 Évolution quotidienne
                                                        </Typography>
                                                        <ResponsiveContainer width="100%" height={300}>
                                                            <LineChart data={metricsByDay}>
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Line type="monotone" dataKey="clicks" stroke="#3f51b5" name="Clics" />
                                                                <Line type="monotone" dataKey="impressions" stroke="#f50057" name="Impressions" />
                                                            </LineChart>
                                                        </ResponsiveContainer>
                                                    </Box>
                    
                                                </Card> */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ boxShadow: 5, borderRadius: '10px', padding:2 }}>
                            <Typography variant='subtitle1'>Suggestion</Typography>
                            <Typography variant='body2'>qsdqsnlkqsq jdjsd fjsd f shd shdfjsd jf sdj hsjdnxb hjxwcn xcw xc nqshjqksqs dhqs</Typography>
                        </Card>
                        
                    </Grid>

                </Grid>
            </Stack>

        </Box>

    );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Skeleton,
  Menu,
  styled,
  alpha,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';

// mock
import USERLIST from '../../_mock/user';

import { db, functions } from '../../firebase.config';
import CheckCard from '../../components/CheckCard';
import { UserAuth } from '../../context/AuthContext';
import CustomButton from '../../components/CustomButton';
import CustomTag from '../../components/customTag';
import { useSnackbar } from '../../context/snackbarContext';
import withAuthorization from '../../withAuthorization';
import { hasPermission } from '../../utils/functions';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Operateurs() {
  const { eventID } = useParams();
  const { user, permissions } = UserAuth();
  const showSnackBar = useSnackbar()
  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refreh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [ops, setOp] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setOp([]);
      setPassview([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/operateurs`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          const authInfoSnap = await getDoc(doc(db, 'authLinks', d.data().link.split('token=')[1]))
          console.log(authInfoSnap.data())
          setOp((ops) => [...ops, { id: d.id, opData: d.data(), authLink: authInfoSnap }]);

        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // GESTION DE LA MODIFICATION
  const [name, setName] = React.useState();
  const [id, setId] = React.useState();

  // *****ticket
  const [ticketVerif, setTicket] = React.useState([]);

  // *****recuperation de billets

  const [tickets, ticketList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      ticketList([])
      try {
        // setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d.data()]);
        });
        // setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);



  const handleActive = async () => {
    setLoading(true)
    handleCloseMenu();
    console.log(ops[selectedIndex])
    try {
      await updateDoc(doc(db, `evenement/${eventID}/operateurs`, `${ops[selectedIndex].id}`), {
        isActive: true,
      });
      await updateDoc(doc(db, `authLinks`, `${ops[selectedIndex].authLink.id}`), {
        isActive: true,
      });
      // navigate(-1, { replace: true });
      setRefresh(!refreh)
      showSnackBar('Opérateur activé avec succès', false)
    } catch (error) {
      console.log(error.message);
      showSnackBar('une erreur est survenue', true)
    } finally {
      setLoading(false)
    }

  };

  const handleDeactive = async () => {
    setLoading(true)
    handleCloseMenu();
    console.log(ops[selectedIndex])
    try {
      await updateDoc(doc(db, `evenement/${eventID}/operateurs`, `${ops[selectedIndex].id}`), {
        isActive: false,
      });
      await updateDoc(doc(db, `authLinks`, `${ops[selectedIndex].authLink.id}`), {
        isActive: false,
      });
      // navigate(-1, { replace: true });
      setRefresh(!refreh)
      showSnackBar('Opérateur desactivé avec succès', false)
    } catch (error) {
      console.log(error.message);
      showSnackBar('une erreur est survenue', true)
    } finally {
      setLoading(false)
    }

  };

  const resendEmail = async () => {
    setLoading(true)
    const generateAuthLink = httpsCallable(functions, "generateAuthLink");
    try {
      const response = await generateAuthLink({
        operatorID: id,
        name,
        eventID
      });
      console.log('link');
      console.log(response.data);


      // setMsg({ content: 'Opérateur créé avec succès', color: 'green' });
      // setOpenSnack(true);
      // navigate(-1, { replace: true });
    } catch (error) {
      console.log('error');
      console.log(error.message);
      // setMsg({ content: "une erreur est survenue", color: 'red' });
      // setOpenSnack(true);
    }
    setLoading(false)
  };

  const handleSubmit = async (e) => { };
  const [passView, setPassview] = React.useState([]);



  const [selectedIndex, setSelectedIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {

    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <Page title="Opérateurs">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Opérateurs Scan<br />
            <Typography variant="body2">
              Les profils d'opérateurs vous permettent de vous identifier sur nos appareils de contrôle d'accès,
              <br /> puis de scanner les participants à votre événement.
            </Typography>
          </Typography>
          <Button
            sx={{
              textTransform: 'initial',
              border: '2px solid transparent',
              color: 'white',
              backgroundColor: '#5E17EB',
              borderRadius: '7px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#5E17EB',
                borderColor: '#5E17EB',
              },
            }}
            variant="contained"
            component={RouterLink}
            to="ajout"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nouvel opérateur
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                cursor: 'pointer',
                bgcolor: '#f2f2f2',
              }}
            >
              <Typography textAlign="Start">
                <strong>Vos identifiants scan</strong>
              </Typography>
              <Typography variant='body2' textAlign="Start">
                Pour utiliser l'app de scan, vous pouvez vous connecter avec vos information de connexion. Vous pouvez egalement créer des operateurs additionnels pour vous aider dans la verification des accès en toute securité.
              </Typography>
              <Stack spacing={2} paddingTop={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>identitifiant :</strong>
                  </Typography>
                  <Typography variant="body2">
                    beschristyann@gmail.com
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>Mot de passe :</strong>
                  </Typography>
                  <Typography variant="body2">
                    *******
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>

            <Stack padding={3} spacing={2} alignItems={'start'} sx={{ bgcolor: '#1F1F1F', color: 'white', borderRadius: '7px', height: '100%' }}>
              <Typography variant='subtitle1' sx={{ color: 'white' }}>Verifier l'access en toute autonomie</Typography>
              <Typography variant='body2'>Choptonticket met a votre disposition un outils pour vous permettre de verifier en toute autonomie l'access a votre a votre evnement. cliquer sur le bouton suivant pour y acceder
              </Typography>
              <CustomButton onClick={() => {
                window.open(`https://scan.choptonticket.com`);
              }} endIcon={<Iconify icon="bi:qr-code-scan" />} type={'primaire'} buttonText={'Scanner vos billets'} />
            </Stack>
          </Grid>
          <Grid item xs={12} paddingTop={2}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="caption table">
                  <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                    <TableRow>
                      <TableCell align="left">Opérateur</TableCell>
                      <TableCell align="center">Lien de connexion</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : ops.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucune donnée</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <TableBody>
                      {ops.map((op, index) => (
                        <TableRow key={index} hover>
                          <TableCell
                            id={op.opData.name}

                            key={op.opData.name}
                            align="left"
                            component="th"
                            scope="row"
                          >
                            <Stack>
                              <Typography variant='body2'><strong>{op.opData.nameOp}</strong></Typography>
                              <Typography variant='body2'> {op.opData.idOp}</Typography>
                              <Stack direction={'row'} spacing={1}>
                                {
                                  op.opData.isScanning ?
                                    <CustomTag text={'Is scanning...'} type={'success'} />
                                    :
                                    <CustomTag text={'Non connecté'} type={'error'} />
                                }

                                {
                                  op.authLink.data().isActive ?
                                    <CustomTag text={'Actif'} /> : <CustomTag text={'Inactif'} type={'error'} />


                                }

                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell id={op.opData.link} align="center">
                            {op.opData.link}
                          </TableCell>
                          <TableCell id={op.opData.name} align="center">
                            <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined}>
                              <Iconify icon="material-symbols-light:more-vert" />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >

                              <MenuItem key={`${index}-1`} onClick={(e) => {
                                handleCloseMenu();
                                // handleModif(selectedIndex); // Utilise l'index sélectionné
                              }} disableRipple value={1}>
                                <Typography variant='body2'>Renvoyer le lien</Typography>
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                }}
                                disableRipple
                                value={0}
                              >
                                <Typography variant='body2'>Copier le lien</Typography>
                              </MenuItem>
                              {
                                ops[selectedIndex]?.authLink.data().isActive === true ?
                                  <MenuItem
                                    onClick={(e) => {
                                      handleDeactive();
                                      // handleVisible()
                                    }}
                                    disableRipple
                                    value={2}
                                  >
                                    <Typography variant='body2'>Désactiver</Typography>
                                  </MenuItem>
                                  : ops[selectedIndex]?.authLink.data().isActive === false ?
                                    <MenuItem
                                      onClick={(e) => {
                                        handleActive();
                                        // handleVisible()
                                      }}
                                      disableRipple
                                      value={2}
                                    >
                                      <Typography variant='body2'>Activer</Typography>
                                    </MenuItem> : null
                              }
                              {hasPermission(permissions, "delete_ops") && (
                               <MenuItem
                               onClick={(e) => {
                                 handleCloseMenu();
                                 // handleOpenDeleteModal()
                               }}
                               disableRipple
                               value={2}
                             >
                               <Typography variant='body2'>Supprimer</Typography>
                             </MenuItem>
                              )}
                              

                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default withAuthorization(Operateurs, "manage_ops")

/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import {
  Stack,
  Checkbox,
  Typography,
  Box,
  LinearProgress,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextareaAutosize,
  TextField,
  Grid,
  Button,
  alpha,
  Menu,
  styled,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';
// components
import { SketchPicker } from 'react-color';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { db } from '../../../firebase.config';
import CustomButton from '../../../components/CustomButton';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function WidgetCustom() {
  const { eventID } = useParams();
  // loading
  const [loading, setLoading] = React.useState(false);

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const [bgColor, setBgColor] = useState('ffffff');
  const [textColor, setTextColor] = useState('000000');
  const [primaryColor, setPrimaryColor] = useState('000000');
  const [secondaryColor, setSecondaryColor] = useState('000000');
  const [customIndex, setCustom] = useState(0);
  const [embedParams, setEmbedParams] = useState('');
  const [color, setColor] = useState({ bgColor: "", primaryColor: "#5E17EB", secondaryColor: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    if (event.target.id === 'bt-fdColor') {
      setCustom(1)
    } else if (event.target.id === 'bt-primaire') {
      setCustom(2)
    } else if (event.target.id === 'bt-secondaire') {
      setCustom(3)
    }
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
  };

  function replaceBetweenChars(str, startChar, endChar, replacement) {
    const regex = new RegExp(`${startChar}([^${endChar}]*)${endChar}`, 'g');
    return str.replace(regex, `${startChar}${replacement}${endChar}`);
  }

  const handleBackgroundColorChange = (c) => {
    setBgColor(c.hex);
    console.error(color);
    setColor({bgColor: c.hex, primaryColor, secondaryColor})
  };

  const handlePrimaryColorChange = (c) => {
    setPrimaryColor(c.hex);
    setColor({bgColor, primaryColor: c.hex, secondaryColor})
  };

  const handleSecondaryColorChange = (c) => {
    setSecondaryColor(c.hex);
    setColor({bgColor, primaryColor, secondaryColor: c.hex})
    
  };

  const [url, setUrl] = useState('');
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setUrl(`http://localhost:3001/fr/event_embed/${eventID}/${embedParams}`);
        // setUrl(`https://choptonticket.com/fr/event_embed/${eventID}/${embedParams}`);
        console.log(`http://localhost:3001/fr/event_embed/${eventID}/${embedParams}`);
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh]);

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };



  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const docRef0 = doc(db, `evenement/${eventID}/widget`, eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          //
          if (docSnap.data().color !== null) {
            console.log(docSnap.data().custom)
            setBgColor(docSnap.data().color.bgColor)
            setPrimaryColor(docSnap.data().color.primaryColor)
            setSecondaryColor(docSnap.data().color.secondaryColor)
          }
        }
      } catch (error) {
        console.log('error');
      }
      setLoading(false)
    })();
  }, [eventID]);

  // save population
  const saveCustom = async () => {
    setLoading(true)
    try {
      const statRef = doc(db, `evenement/${eventID}/widget`, `${eventID}`);
      await updateDoc(statRef, {
        color
      });
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleCopy = () => {
    navigator.clipboard.writeText(`choptonticket.com/fr/event/${eventID}`)
    // setMsg({ content: 'Lien copié dans le papier presse', color: 'green' });
    // setOpenSnack(true);
  };

  return (
    <Page title="Editeur" >
      <Box sx={{
        height: '60px',
        paddingX: { xs: 0, sm: 2 }, position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: 'background.paper',
        borderBottom: '2px solid #f2f2f2'
      }}>
        <Stack direction={'row'} alignItems={'center'} sx={{ height: '100%', width: '100%' }} justifyContent={'space-between'}>
          <CustomButton buttonText={'Retour'} type={'secondaire'} />
          <CustomButton onClick={saveCustom} buttonText={'Publier les modifications'} type={'primaire'} />
        </Stack>

      </Box>
      <Box sx={{ height: 'calc(100vh - 60px)' }}>
        {loading && <LinearProgress />}
        <Grid container columnSpacing={1} sx={{ height: '100%' }}>
          {/* Partie Personnalisation */}
          <Grid item xs={12} md={4} sx={{ height: '100%', overflowY: 'auto' }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon={'mingcute:down-line'} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                  <Iconify icon={'gis:color'} />
                  <Typography variant='subtitle1'>Couleur</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} padding={1}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography textAlign="Start" variant="body2">
                      Couleur de fond
                    </Typography>
                    <Box
                      id='bt-fdColor'
                      onClick={handleClick}
                      sx={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '7px',
                        border: '2px solid gainsboro',
                        cursor: 'pointer',
                        bgcolor: `${bgColor}`,
                      }}
                    />
                  </Stack>
                  <Divider />
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography textAlign="Start" variant="body2">
                      Couleur primaire
                    </Typography>
                    <Box
                      id='bt-primaire'
                      sx={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '7px',
                        border: '2px solid gainsboro',
                        cursor: 'pointer',
                        bgcolor: `${primaryColor}`,
                      }}
                      onClick={handleClick}
                    />
                  </Stack>
                  <Divider />
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography textAlign="Start" variant="body2">
                      Couleur secondaire
                    </Typography>
                    <Box
                      id='bt-secondaire'
                      sx={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '7px',
                        border: '2px solid gainsboro',
                        cursor: 'pointer',
                        bgcolor: `${secondaryColor}`,
                      }}
                      onClick={handleClick}
                    />
                  </Stack>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                  >
                    <SketchPicker
                      color={
                        customIndex === 1 ? bgColor : customIndex === 2 ? primaryColor : secondaryColor
                      }
                      onChange={
                        customIndex === 1 ? handleBackgroundColorChange : customIndex === 2 ? handlePrimaryColorChange : handleSecondaryColorChange
                      }
                    />
                  </StyledMenu>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon={'mingcute:down-line'} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Iconify icon={'octicon:typography-16'} />
                <Typography variant='subtitle1'>Typographie</Typography>
                </Stack>
                
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon={'mingcute:down-line'} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant='subtitle1'>Logo / Image de marque</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon={'mingcute:down-line'} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant='subtitle1'>Button d'action</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Texte du bouton */}
                <TextField
                  label="Texte du bouton"
                  fullWidth
                  // value={buttonText}
                  // onChange={(e) => setButtonText(e.target.value)}
                  sx={{ my: 2 }}
                />

                {/* Forme des boutons */}
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel>Forme du bouton</InputLabel>
                  <Select
                  // value={buttonRadius}
                  // onChange={(e) => setButtonRadius(e.target.value)}
                  >
                    <MenuItem value="4px">Arrondi</MenuItem>
                    <MenuItem value="0px">Carré</MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
            </Accordion>



          </Grid>

          {/* Partie Aperçu */}
          <Grid item xs={12} md={8} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px solid #f2f2f2', bgcolor:'#f2f2f2' }}>
            <iframe width="600px" height="600px" src={url} title="wiget" style={{ border: '1px solid gainsboro', borderRadius: '10px' }} />
          </Grid>

        </Grid>

      </Box>

    </Page>
  );
}

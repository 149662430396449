/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Paper,
    TableHead,
    Grid,
    Box,
    TextField,
    MenuItem,
    useTheme,
    IconButton,
    Backdrop,
    CircularProgress,
    Skeleton,
    InputAdornment,
    Menu,
    Avatar,
    Modal,
    Divider,
    FormControl,
    InputLabel,
    Select,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import CustomButton from '../../../../components/CustomButton';
import CustomTag from '../../../../components/customTag';
import withAuthorization from '../../../../withAuthorization';
import RoleInterface from './roles/roleInterface';
import { hasPermission } from '../../../../utils/functions';

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: 5,
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 15,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));



// ----------------------------------------------------------------------


function Roles() {

    const { user, permissions } = UserAuth();
    const navigate = useNavigate();

    const [orgID, setOrgID] = React.useState();

    // loading
    const [loading, setLoading] = React.useState(false);

    // GESTION DE LA MODIFICATION
    const { openDrawer, setOpenDrawer } = Variables();
    const { type, setType } = Variables();



    // for change value of globla quota
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [refreh, setRefresh] = React.useState(false);


    // take operateurs from firebase
    const [team, setTeam] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            setTeam([]);
            try {
                setLoading(true);
                const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
                const docSnap = await getDoc(docRef0);
                const q = query(collection(db, `organisations/${docSnap.data().idOrg}/roles`));
                const snapshot = await getDocs(q);
                snapshot.forEach(async (d) => {
                    if (d.data().permissionId) {
                        console.log('-ss--s')
                        const baseRoleRef = doc(db, 'admins/basedRole/roles', `${d.data().permissionId}`);
                        const baseRoleSnap = await getDoc(baseRoleRef);
                        if (baseRoleSnap.exists()) {
                            setTeam((team) => [...team, baseRoleSnap]);
                        }
                    }
                    else {
                        setTeam((team) => [...team, d]);
                    }

                });
                setOrgID(docSnap.data().idOrg);
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [refreh, openDrawer]);

    const [openRecetteModal, setOpenRecetteModal] = React.useState(false);
    const [email, setEmail] = React.useState();
    const [role, setRole] = React.useState();

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    };
    const handleOpenRecetteModal = async () => {
        setOpenRecetteModal(true);

    };
    const handleCreateTeamMember = async () => {
        setLoading(true)
        try {
            //
        } catch (error) {
            console.log(error)
        }
        setEmail()
        setLoading(false)
        setOpenRecetteModal(false);
        setRefresh(!refreh)
    };

    const deleteTeamMember = async () => {
        setLoading(true)
        try {
            await deleteDoc(doc(db, `organisations/${orgID}/roles`, `${team[selectedIndex].id}`));
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        setRefresh(!refreh)
    };
    const handleCloseRecetteModal = () => setOpenRecetteModal(false);



    const deleteteamMember = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette teamMember ?")) {
            await deleteDoc(doc(db, `organisations/${orgID}/crm/${orgID}/team`, id));
        }
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const handleOpenMenu = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    return (
        <Page title="Roles">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 3 } }}>
                <CheckCard />
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" paddingTop={2}>
                    <Typography variant="h4" gutterBottom>
                        Roles
                    </Typography>
                </Stack>
                <Stack paddingBottom={2} direction='row' spacing={2} justifyContent={'end'}>
                    { /* permissions.includes("add_role") && (
        <button>Créer une campagne</button>
      ) */}
                    {hasPermission(permissions, "add_roles") && (
                        <CustomButton type='primaire' buttonText='Ajoutes des roles' onClick={() => navigate('add')} />
                    )}

                </Stack>
                <Box>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">

                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : team.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="body">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {team.map((teamMember, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell
                                                    id={teamMember.id}
                                                    key={teamMember.id}
                                                    onClick={() => { navigate(`${teamMember.id}`); }}
                                                >
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Avatar sx={{ width: 30, height: 30 }} />
                                                        <Stack>
                                                            <Typography variant='caption'><strong>{teamMember.data().name}</strong></Typography>
                                                            <Typography variant='caption'>{teamMember.data().notEditable ? 'Creer par choptonticket' : 'Créer par votre organisation'}</Typography>
                                                        </Stack>
                                                    </Stack>

                                                </TableCell>
                                                <TableCell id={teamMember.data().nom} onClick={() => { navigate(`${teamMember.id}`); }}>
                                                    <Typography variant='body2'>{teamMember.data().size ? teamMember.data().size : '-'}</Typography>
                                                </TableCell>

                                                <TableCell align='right' id={teamMember.data().nom} onClick={(e) => {
                                                    navigate(`${teamMember.id}`);
                                                    e.stopPropagation()
                                                }}>
                                                    {
                                                        teamMember.data().notEditable === true ? null :
                                                            <Box>
                                                                <IconButton key={index} onClick={(e) => {
                                                                    handleOpenMenu(e, index)
                                                                    e.stopPropagation()
                                                                }
                                                                }
                                                                    aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={openMenu ? 'true' : undefined}>
                                                                    <Iconify icon="fluent:more-vertical-48-filled" />
                                                                </IconButton>
                                                                <StyledMenu
                                                                    id="demo-customized-menu"
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'demo-customized-button',
                                                                    }}
                                                                    anchorEl={anchorEl}
                                                                    open={openMenu}
                                                                    onClose={handleCloseMenu}
                                                                >
                                                                    {hasPermission(permissions, "edit_roles") && (
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                navigate(team[selectedIndex]?.id)
                                                                            }}
                                                                            disableRipple
                                                                            value={1}
                                                                        >
                                                                            <Typography variant='body2'>Modifier</Typography>
                                                                        </MenuItem>
                                                                    )}
                                                                    {hasPermission(permissions, "remove_roles") && (
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                // handleCloseMenu();
                                                                                // handleOpenSup()
                                                                            }}
                                                                            disableRipple
                                                                            value={2}
                                                                        >
                                                                            <Typography variant='body2'>Supprimer</Typography>
                                                                        </MenuItem>
                                                                    )}



                                                                </StyledMenu>

                                                            </Box>
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Box>
            </Container>
        </Page>
    );
}
/** gestion des access */
export default withAuthorization(Roles, "manage_roles")

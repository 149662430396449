import { Navigate, useParams, useRoutes } from 'react-router-dom';
// layouts
import RegisterOrg from './pages/RegisterOrg';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Home from './pages/home';
import NewEvent from './pages/NewEvent';
import EventData from './pages/configuration/EventData';
import Billets from './pages/configuration/Billets';
import AddTicket from './pages/configuration/AddTicket';
import Discount from './pages/communication/Discount';
import Participants from './pages/participants/Participants';
import AddParticipant from './pages/participants/AddParticipant';
import Operateurs from './pages/acces/Operateurs';
import AddOperateur from './pages/acces/AddOperateur';
import Acceuil from './pages/Acceuil';
import ProtectedRoute from './components/ProtectedRoute';
import AddDiscount from './pages/communication/addDiscount';
import Settings from './pages/settings';
import DateList from './pages/configuration/DateList';
import AddDate from './pages/configuration/addDate';
import Stats from './pages/stats/Stats';
import OrganisationLayout from './layouts/organisation/Organisationlayout';
import Organisation from './pages/organisation/monOrganisation';
import Comptabilite from './pages/organisation/Compabilite';
import Historique from './pages/organisation/Historique';
import Coordonnees from './pages/organisation/CoordonneesBancaires';
import InscriptionRoute from './components/InscriptionRoute';
import Publish from './pages/publish';
import Account from './pages/account';
import ChangePass from './pages/changePass';
import CrmLayout from './layouts/crm/CrmLayout';
import CrmTab from './pages/crm/crm';
import CreateCampaign from './pages/crm/component/campaing/createCampaign';
import ResetPass from './pages/resetPass';
import OwnSite from './pages/communication/canal_de_vente/ownSite';
import CanalVente from './pages/communication/canal_de_vente/CanalDeVente';
import WebBuilder from './pages/miniSite/editSite';
import CttDoor from './pages/organisation/cttDoor';
import SiteEditor from './pages/miniSite/SiteEditor';
import SiteBuilder from './pages/miniSite/siteBuilder';
import Affiliate from './pages/communication/affiliate/affiliate';
import AddAffiliate from './pages/communication/affiliate/addAffiliate';
import ShowSeance from './pages/configuration/seance/showSeance';
import FinanceLayout from './layouts/finance/FinanceLayout';
import HomeFinance from './pages/finance/home';
import ModifyTicket from './pages/configuration/billet/modifyBillet';
import MetricsLayout from './layouts/metrics/metricsLayout';
import Sales from './pages/metrics/ventes/sales';
import WidgetCustom from './pages/communication/canal_de_vente/widgetCustom';
import Date from './pages/configuration/seance/date';
import CreatePaidCampaign from './pages/crm/component/campaing/createPaidCampaign';
import Templates from './pages/miniSite/templates';
import GestionEquipeDashboard from './pages/organisation/equipe/gestionEquipeDashboard';
import RoleInterface from './pages/organisation/equipe/composant/roles/roleInterface';
import JoinOrganizationPage from './pages/organisation/equipe/joinPage';
import CampaignDashboard from './pages/crm/component/campaing/showPaidCampaignSum';

// ----------------------------------------------------------------------

export default function Router() {
  const params = useParams();
  return useRoutes([
    {
      path: '/:eventID/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />,
        </ProtectedRoute>
      ),
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'information', element: <EventData /> },
        { path: 'billets', element: <Billets /> },
        { path: 'billets/nouveau_billet', element: <AddTicket /> },
        { path: 'billets/:billetID', element: <ModifyTicket /> },
        { path: 'user', element: <User /> },
        { /* path: 'datelist', element: <Date /> */},
        { path: 'datelist', element: <DateList /> },
        { path: 'datelist/ajout', element: <AddDate /> },
        { path: 'datelist/:seanceID', element: <ShowSeance /> },
        { path: 'code_promo', element: <Discount /> },
        { path: 'code_promo/ajout', element: <AddDiscount /> },
        { path: 'code_promo/:codeID', element: <AddDiscount /> },
        { path: 'affiliate', element: <Affiliate /> },
        { path: 'affiliate/ajout', element: <AddAffiliate /> },
        { path: 'affiliate/:affId', element: <AddAffiliate /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'participants', element: <Participants /> },
        { path: "participants/:filtres", element: <Participants /> },
        { path: 'ajout_participant', element: <AddParticipant /> },
        { path: 'verification', element: <Operateurs /> },
        { path: 'verification/ajout', element: <AddOperateur /> },
        { path: 'statistique', element: <Stats /> },
        { path: 'site_personnel', element: <OwnSite /> },
        { path: 'social_media', element: <CanalVente /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // organisation part
    {
      path: '/organisation',
      element: (
        <ProtectedRoute>
          <OrganisationLayout />,
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <Navigate to="/organisation/app" /> },
        { path: 'app', element: <Organisation /> },
        { path: 'comptabilite', element: <Comptabilite /> },
        { path: 'team', element: <GestionEquipeDashboard /> },
        { path: 'team/users', element: <GestionEquipeDashboard /> },
        { path: 'team/roles', element: <GestionEquipeDashboard /> },
        { path: 'team/roles/add', element: <RoleInterface /> },
        { path: 'team/roles/:roleId', element: <RoleInterface /> },
        { path: 'historique', element: <Historique /> },
        { path: 'door', element: <CttDoor /> },
        
      ],
    },
    {
      path: '/join',
      element: <JoinOrganizationPage />,
    },

    // finance part
    {
      path: '/finance',
      element: (
        <ProtectedRoute>
          <FinanceLayout />,
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <Navigate to="/finance/paiements" /> },
        { path: 'paiements', element: <HomeFinance /> },
        { path: 'campaigns/:campaignID', element: <CreateCampaign /> },
        
        { path: 'settings', element:  <HomeFinance /> },
        { path: "contacts/:filtres", element: <CrmTab /> },
        { path: 'populations', element:  <CrmTab /> }, 
        { path: 'populations/:populationID', element:  <CrmTab /> }, 
        { path: 'socialmedia', element:  <CrmTab /> }, 
        { path: 'settings', element:  <CrmTab /> }, 
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // crm part
    {
      path: '/marketing',
      element: (
        <ProtectedRoute>
          <CrmLayout />,
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <CrmTab /> },
        { path: 'campaigns', element: <CrmTab /> },
        { path: 'campaigns/:campaignID', element: <CreateCampaign /> },
        { path: 'ads', element: <CrmTab /> },
        { path: 'ads/:campaignID', element: <CreatePaidCampaign /> },
        { path: 'ads/:campaignID/perfs', element: <CampaignDashboard /> },
        { path: 'contacts', element:  <CrmTab /> },
        { path: "contacts/:filtres", element: <CrmTab /> },
        { path: 'populations', element:  <CrmTab /> }, 
        { path: 'populations/:populationID', element:  <CrmTab /> }, 
        { path: 'socialmedia', element:  <CrmTab /> }, 
        { path: 'settings', element:  <CrmTab /> }, 
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },


    // metrics part
    {
      path: '/analyse',
      element: (
        <ProtectedRoute>
          <MetricsLayout />,
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <Navigate to="/analyse/vente" /> },
        { path: 'vente', element: <Sales /> },
        { path: 'vente/:eventID', element: <CreateCampaign /> },
        { path: 'communaute', element:  <CrmTab /> },
        { path: "comportment_vente", element: <CrmTab /> },
        { path: 'comportment_vente/:eventID', element:  <CrmTab /> }, 
        { path: 'socio_demographique', element:  <CrmTab /> }, 
        { path: 'gout', element:  <CrmTab /> }, 
      ],
    },

    {
      path: '/custom-widget/:eventID',
      element: <WidgetCustom />,
    },

    {
      path: 'mini-site',
      element: <WebBuilder />,
    },
    {
      path: 'mini-site1',
      element: <SiteEditor />,
    },
    {
      path: 'template',
      element: <Templates />,
    },
    {
      path: 'editor',
      element: <SiteBuilder />,
    },
    {
      path: 'editor/:id',
      element: <SiteBuilder />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'register/organisation',
      element: (
        <ProtectedRoute>
          <RegisterOrg />
        </ProtectedRoute>
      ),
    },
    {
      path: 'settings/account',
      element: (
        <ProtectedRoute>
          <Account />
        </ProtectedRoute>
      ),
    },
    {
      path: 'settings/password',
      element: (
        <ProtectedRoute>
          <ChangePass />
        </ProtectedRoute>
      ),
    },
    {
      path: 'reset-password/:upn',
      element: <ResetPass />,
    },
    {
      path: '/:eventID/publish',
      element: (
        <ProtectedRoute>
          <Publish />
        </ProtectedRoute>
      ),
    },
    {
      path: 'bo',
      element: (
        <ProtectedRoute>
          <Home />,
        </ProtectedRoute>
      ),
    },
    {
      path: 'bo/mon_compte',
      element: (
        <ProtectedRoute>
          <Settings />,
        </ProtectedRoute>
      ),
    },
    {
      path: 'bo/new_event',

      element: (
        <InscriptionRoute>
          <NewEvent />,
        </InscriptionRoute>
      ),
    },
    {
      path: '/',
      element: <Acceuil />,
      // element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/bo" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

import React, { useRef } from "react";
import { Card, CardHeader, CardContent, CardMedia, CardActions, Avatar, Typography, IconButton, Button, Box } from "@mui/material";
import { MoreHoriz, FavoriteBorder, ChatBubbleOutline, BookmarkBorder, Send } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";

const InstagramPost = ({ text, visuelPreview, visuel, linkTitle, linkDesc, btText }) => {
  const videoRef = useRef(null);
  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Remet la vidéo au début
      videoRef.current.play(); // Relance la lecture
    }
  };
  return (
    <Card sx={{ maxWidth: 380, boxShadow: 3, borderRadius: 2, margin: "auto" }}>
      <CardHeader
        avatar={<Avatar src="https://via.placeholder.com/40" alt="Page Logo" />}
        action={<IconButton><MoreHoriz /></IconButton>}
        title={<Typography fontWeight={600}>Your Page</Typography>}
        subheader={<Typography color="text.secondary">Sponsored</Typography>}
      />
      {/* Affichage de l'image ou lecture de la vidéo */}
      {visuelPreview && (
        <div style={{ marginTop: 20 }}>
          {visuel.type.startsWith("video/") ? (


            <video
              ref={videoRef}
              src={visuelPreview}
              controls
              autoPlay
              muted
              style={{ maxWidth: "100%", maxHeight: 400 }}
              onEnded={handleVideoEnd} // Redémarrer la vidéo automatiquement
            >
              <track
                kind="captions"
                srcLang="fr"
                label="Caption"
                default
              />
            </video>
          ) : (
            <LazyLoadImage
              src={visuelPreview}
              alt={visuelPreview}
              className="img-lazy"
              width="100%"
              height={500}
              style={{ objectFit: "cover", }}
              effect="blur"
            />

          )}
        </div>
      )}

      {
        !visuelPreview && <CardMedia
          component="img"
          height="500"
          image={visuel || "https://firebasestorage.googleapis.com/v0/b/event-86cbf.appspot.com/o/imageChoptonticket%2Fpreview.png?alt=media&token=8580a57d-7522-4ee3-8335-efc4a1a9261a"}
          alt="Event image"
        />
      }
      <Box sx={{ p: 1, borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography fontWeight={600} sx={{ cursor: "pointer" }}>Buy Tickets</Typography>
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Box>
      <CardActions disableSpacing>
        <IconButton><FavoriteBorder /></IconButton>
        <IconButton><ChatBubbleOutline /></IconButton>
        <IconButton><Send /></IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton><BookmarkBorder /></IconButton>
      </CardActions>
      <CardContent>
        <Typography variant="body2" fontWeight={600}>55 likes</Typography>
        <Typography variant="body2" color="text.primary" >
          {text !== '' ? text : 'Text du post'}
        </Typography>

      </CardContent>
    </Card>
  );
}

export default InstagramPost;
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import * as React from 'react';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import {
  Alert,
  AlertTitle,
  Avatar,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  ToggleButtonGroup,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { isNaN } from 'lodash';
// import { DateTimePicker } from "@material-ui/pickers";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { addDoc, collection, doc, getDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import ReactQuill from 'react-quill';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Confetti from 'react-confetti';
import { db, storage } from '../firebase.config';
import Iconify from './Iconify';
import { UserAuth } from '../context/AuthContext';
import CustomButton from './CustomButton';
import 'react-quill/dist/quill.snow.css';
// eslint-disable-next-line import/order
import { LoadingButton } from '@mui/lab';
import Maps from './map/Maps';
import CustomDateTimePicker from './CustomDateTimePicker';
import CustomSeanceDateTimePicker from './customSeanceDateTimePicker';
import useResponsive from '../hooks/useResponsive';
import { useSnackbar } from '../context/snackbarContext';


const randomstring = require("randomstring");
// accordion-------------------------------------------------
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<Iconify icon={'fluent:add-28-filled'} sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#f2f2f2',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: '7px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//--------------------------------------------------------

// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------

//-----------------------------------------------------------------------

// const steps = ['Informations génerales', 'Description & Visuels', 'visuels'];

export default function HorizontalLinearStepper() {
  const { user } = UserAuth();
  const showSnackbar = useSnackbar();
  const [steps, setSteps] = React.useState(['Informations génerales', 'Description & Visuels', 'visuels']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  // HANDLE TRAITEMENT
  const [name, setName] = React.useState();
  const [nameError, setNameError] = React.useState(false);
  // const [debut, setDateDebut] = React.useState();
  const [debutError, setDebutError] = React.useState(false);
  // const [fin, setDateFin] = React.useState();
  const [locationField, setLocation] = React.useState('');
  const [locationField2, setLocation2] = React.useState('');
  const [lieu, setLieu] = React.useState();
  const [sugClick, setSugClick] = React.useState(false);
  const [lieuError, setLieuError] = React.useState(false);
  const [infoLieu, setInfoLieu] = React.useState('');
  const [ville, setVille] = React.useState();
  const [villeError, setVilleError] = React.useState(false);
  const [pays, setpays] = React.useState();
  const [paysError, setPaysError] = React.useState(false);
  const [typeField, setTypeField] = React.useState();
  const [typeFieldError, setTypeFieldError] = React.useState(false);
  const [locationType, setLocationType] = useState(0);
  const [streamLink, setStreamLink] = React.useState('');
  const [streamLinkError, setStreamLinkError] = React.useState(false);
  const [webLink, setWeb] = React.useState();
  const [fbLink, setFb] = React.useState();
  const [instaLink, setInsta] = React.useState();
  const [desc, setDesc] = React.useState();
  const [descError, setDescError] = React.useState(false);
  const [url, setUrl] = React.useState();
  const [event, setEvent] = React.useState();
  const [load, setLoad] = React.useState(false);
  const [seance, setActiveSeance] = React.useState(0);
  const [day, setDay] = React.useState();
  const [hour, setHour] = React.useState();
  const [hourError, setHourError] = React.useState(false);
  const today = new Date();
  // date / time
  const [dateError, setDateError] = useState(false);
  const [dateDebut, setDateDebut] = useState(null);
  const [timeDebut, setTimeDebut] = useState('');
  const [dateDebutError, setDateDebutError] = React.useState(false);
  const [timeDebutError, setTimeDebutError] = React.useState(false);
  const [dateFintError, setDateFinError] = React.useState(false);
  const [timeFinError, setTimeFinError] = React.useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // google place auto
  const libraries = ['places'];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries,
  });
  const searchOptions = {
    componentRestrictions: { country: 'CIV' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
  };

  // upload functions

  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();
  const [progresspercent, setProgresspercent] = useState(0);

  const [reductionType, setReductionType] = React.useState(''); // reduction type

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // description richtext
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ['clean'],
    ],
  };

  const formats = [
    // 'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  const [value, setValue] = useState('');
  const handleChangeQuill = (content, delta, source, editor) => {
    console.log(editor.getHTML()); // html 사용시
    console.log(JSON.stringify(editor.getContents())); // delta 사용시
    setDesc(editor.getHTML());
  };

  // boutton  de passage a l'etape suivante
  const handleNext = () => {
    // Formatage de la date
    let result = 1
    const formattedDate = dayjs(day).format('YYYY-MM-DD');
    const dateTime = `${formattedDate}T${hour}`;
    console.log(Timestamp.fromDate(new Date(dateTime)));
    console.log(hour);

    // Réinitialisation des erreurs de validation
    const resetErrors = () => {
      setNameError(false);
      setTypeFieldError(false);
      setLieuError(false);
      setStreamLinkError(false)
      setVilleError(false);
      setPaysError(false);
      setDebutError(false);
      setHourError(false);
      setDateDebutError(false);
      setTimeDebutError(false);
      setDateFinError(false);
      setTimeDebutError(false);
      setSeancesError(false);
      setRecurrenceDaysError(false)
      setRecurrenceEndDateError(false)
    };
    resetErrors();

    // Validation des informations sur l'événement
    if (activeStep === 0) {
      let isValid = true;

      // Vérification des champs obligatoires
      const checkFieldError = (field, setError) => {
        if (!field) {
          setError(true);
          isValid = false;
        }
      };

      checkFieldError(name, setNameError);
      checkFieldError(typeField, setTypeFieldError);
      if (locationType === 0) {
        checkFieldError(lieu, setLieuError);
        checkFieldError(ville, setVilleError);
        checkFieldError(pays, setPaysError);
      } else {
        checkFieldError(streamLink, setStreamLinkError);
      }
      if (seance === 0) {
        checkFieldError(dateDebut, setDateDebutError);
        checkFieldError(timeDebut, setTimeDebutError);
        checkFieldError(dateFin, setDateFinError);
        checkFieldError(timeFin, setTimeFinError);
        const tmpDateDebut = new Date(dateDebut);
        // Utiliser setHours pour remplacer l'heure et les minutes
        tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
        const tmpDateFin = new Date(dateFin);
        // Utiliser setHours pour remplacer l'heure et les minutes
        tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
        result = calculateDifference(tmpDateDebut, tmpDateFin)
      }


      // Si validation échoue, afficher un message
      if (!isValid) {
        showSnackbar('Plusieurs champs requis ne sont pas renseignés', true)
        return;
      }
      if (result === 0) {
        setDateDebutError(true)
        setTimeDebutError(true)
        setDateFinError(true)
        setTimeFinError(true)
        showSnackbar('la date de debut doit être avant à la date de fin', true)
        return
        // eslint-disable-next-line no-else-return
      }
    } else if (activeStep === 2 && seance === 1) {
      let isValid = true;

      // Vérification des champs obligatoires
      const checkFieldError = (field, setError) => {
        if (!field) {
          setError(true);
          isValid = false;
        }
      };
      checkFieldError(seances, setSeancesError);
      
      const seancesToAdd = isRecurrent ? genererSeances() : seances
      if (isRecurrent) {
        if (seances[0].date === null || seances[0].debutTime === ''|| seances[0].endTime === '') {
          isValid = false;
          handleSeanceChange(seances[0].id, 'error', true)
          // setSeancesError(true)
        }
        // verification repetition
        if (recurence === 0 && recurrenceDays.length === 0) {
          isValid = false;
          setRecurrenceDaysError(true)
        }
        // verification de la date de fin
        checkFieldError(recurrenceEndDate, setRecurrenceEndDateError);
      } else {
        for (let index = 0; index < seancesToAdd.length; index++) {
          const s = seancesToAdd[index];
          console.log(s.id)
          if (s.date === null || s.debutTime === ''|| s.endTime === '') {
            isValid = false;
            handleSeanceChange(s.id, 'error', true)
            // setSeancesError(true)
          } else {
            handleSeanceChange(s.id, 'error', false)
          }
          
        }
      }
      if (seancesToAdd.length === 0) {
        showSnackbar('Verifier que vous avez saisi une date de fin suffisante pour generer des seances', true)
        return;
      }

      if (!isValid) {
        showSnackbar('Plusieurs champs requis ne sont pas renseignés', true)
        return;
      }

    }


    // Gérer les étapes sautées
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // Gérer l'étape finale
    if ((activeStep === 2 && seance === 0) || (activeStep === 3 && seance === 1)) {
      // eslint-disable-next-line no-unused-expressions
      seance === 0 ? handleSubmit() : seance === 1 ? handleSubmit1() : null;
    }

    // Passer à l'étape suivante
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  // boutton de retour en arriere
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const navigate = useNavigate();
  const finish = () => {
    handleSubmit();
    navigate('/dashboard');
    // setActiveStep(0);
  };

  // place autocomplete
  const [address, setAddress] = useState('');

  const handleSelect = async (address, placeId, suggestion) => {
    setSugClick(true);
    console.log(suggestion.description);
    const tmp = suggestion.description.split(',');
    setLieu(tmp[0].trim());
    setVille(tmp[tmp.length - 2].trim());
    setpays(tmp[tmp.length - 1].trim());
    setAddress(suggestion.description);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setLocation2(suggestion.description);
    setLocation(ll);
  };

  const handleSugClick = () => {
    if (!sugClick) {
      setLieu('');
      setVille('');
      setpays('');
    }
  };

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  /*
   const handleSubmit = async (e) => {
     setLoad(true);
     try {
       // Formattage des dates avec les heures
       const formatDateWithTime = (date, time) => {
         const tmpDate = new Date(date);
         const [hours, minutes] = time.split(':').map(Number);
         tmpDate.setHours(hours, minutes, 0);
         return tmpDate;
       };
 
       const tmpDateDebut = formatDateWithTime(dateDebut, timeDebut);
       const tmpDateFin = formatDateWithTime(dateFin, timeFin);
 
       // Génération d'un ID unique pour l'événement
       const generateUniqueEventId = async () => {
         let eventuid;
         let eventSnap;
         do {
           eventuid = randomstring.generate({
             length: 10,
             charset: "alphanumeric",
             capitalization: 'uppercase',
           });
           // eslint-disable-next-line no-await-in-loop
           eventSnap = await getDoc(doc(db, 'evenement', eventuid));
         } while (eventSnap.exists());
         return eventuid;
       };
 
       const eventuid = await generateUniqueEventId();
 
       // Récupération des données de l'organisateur
       const docRef0 = doc(db, 'organisateurs', user.uid);
       const docSnap = await getDoc(docRef0);
       if (!docSnap.exists()) throw new Error("Organisateur introuvable");
 
       const orgId = docSnap.data().idOrg;
 
       // Fonction pour sauvegarder les données de l'événement
       const saveEvent = async (eventData) => {
         await setDoc(doc(db, 'evenement', eventuid), eventData);
 
         await addDoc(collection(db, `evenement/${eventuid}/description`), {
           value: desc || '',
         });
 
         await setDoc(doc(db, `evenement/${eventuid}/recette`, eventuid), {
           recette: 0,
           reduction: 0,
           codePromo: 0,
           frais: 0,
         });
 
         if (seance === 1) {
           await addDoc(collection(db, `evenement/${eventuid}/seances`), {
             date: Timestamp.fromDate(tmpDateDebut),
             dateFin: Timestamp.fromDate(tmpDateFin),
             status: 1,
             lieu_event: '',
             city: '',
             country: '',
             localisation: '',
             participants: 0,
           });
         }
       };
 
       // Chargement de l'image (si disponible)
       if (selectedImage) {
         const storageRef = ref(storage, `/eventImages/${name}-${eventuid}`);
         const snapshot = await uploadBytes(storageRef, selectedImage);
         const downloadURL = await getDownloadURL(snapshot.ref);
         setUrl(downloadURL);
 
         if (locationType === 0) {
           await saveEvent({
             locationType,
             controleurSet: false,
             dateDebut: Timestamp.fromDate(tmpDateDebut),
             dateFin: Timestamp.fromDate(tmpDateFin),
             idVisuel: downloadURL,
             isImageSet: true,
             isTicketCreated: false,
             nomEvent: name.toLowerCase(),
             name,
             orgId,
             participant: 0,
             status: 0,
             seanceActive: seance === 1 ? 1 : 0,
             localisation: locationField,
             localisationDes: locationField2,
             city: ville,
             country: pays,
             lieu_event: lieu,
             info: infoLieu,
             createDate: Timestamp.fromDate(new Date()),
             type: typeField,
           });
         } else {
           await saveEvent({
             locationType,
             controleurSet: false,
             dateDebut: Timestamp.fromDate(tmpDateDebut),
             dateFin: Timestamp.fromDate(tmpDateFin),
             idVisuel: downloadURL,
             isImageSet: true,
             isTicketCreated: false,
             nomEvent: name.toLowerCase(),
             name,
             orgId,
             participant: 0,
             status: 0,
             seanceActive: seance === 1 ? 1 : 0,
             streamLink,
             createDate: Timestamp.fromDate(new Date()),
             type: typeField,
           });
         }
 
       } else {
         // eslint-disable-next-line no-lonely-if
         if (locationType === 0) {
           await saveEvent({
             locationType,
             controleurSet: false,
             dateDebut: Timestamp.fromDate(tmpDateDebut),
             dateFin: Timestamp.fromDate(tmpDateFin),
             idVisuel: '',
             isImageSet: false,
             isTicketCreated: false,
             nomEvent: name.toLowerCase(),
             name,
             orgId,
             participant: 0,
             status: 0,
             seanceActive: seance === 1 ? 1 : 0,
             localisation: locationField,
             localisationDes: locationField2,
             city: ville,
             country: pays,
             lieu_event: lieu,
             info: infoLieu,
             createDate: Timestamp.fromDate(new Date()),
             type: typeField,
           });
         } else {
           await saveEvent({
             locationType,
             controleurSet: false,
             dateDebut: Timestamp.fromDate(tmpDateDebut),
             dateFin: Timestamp.fromDate(tmpDateFin),
             idVisuel: '',
             isImageSet: false,
             isTicketCreated: false,
             nomEvent: name.toLowerCase(),
             name,
             orgId,
             participant: 0,
             status: 0,
             seanceActive: seance === 1 ? 1 : 0,
             streamLink,
             createDate: Timestamp.fromDate(new Date()),
             type: typeField,
           });
         }
       }
 
       setEvent(eventuid);
       setLoad(false);
     } catch (error) {
       console.error('Erreur:', error.message);
       setActiveStep(0);
       setLoad(false);
     }
   }; */

  const handleSubmit = async (e) => {
    setLoad(true);
    try {
      // 🔹 Formattage des dates avec les heures
      const formatDateWithTime = (date, time) => {
        const tmpDate = new Date(date);
        const [hours, minutes] = time.split(':').map(Number);
        tmpDate.setHours(hours, minutes, 0);
        return tmpDate;
      };

      const tmpDateDebut = formatDateWithTime(dateDebut, timeDebut);
      const tmpDateFin = formatDateWithTime(dateFin, timeFin);

      // 🔹 Génération d'un ID unique pour l'événement
      const generateUniqueEventId = async () => {
        let eventuid; let eventSnap;
        do {
          eventuid = randomstring.generate({ length: 10, charset: "alphanumeric", capitalization: 'uppercase' });
          eventSnap = await getDoc(doc(db, 'evenement', eventuid));
        } while (eventSnap.exists());
        return eventuid;
      };

      const eventuid = await generateUniqueEventId();

      // 🔹 Récupération des données de l'organisateur
      const docSnap = await getDoc(doc(db, 'organisateurs', user.uid));
      if (!docSnap.exists()) throw new Error("Organisateur introuvable");

      const orgId = docSnap.data().idOrg;

      // 🔹 Préparer les données de l'événement
      const eventData = {
        locationType,
        controleurSet: false,
        dateDebut: Timestamp.fromDate(tmpDateDebut),
        dateFin: Timestamp.fromDate(tmpDateFin),
        isImageSet: !!selectedImage,
        isTicketCreated: false,
        nomEvent: name.toLowerCase(),
        name,
        orgId,
        participant: 0,
        status: 0,
        seanceActive: seance === 1 ? 1 : 0,
        createDate: Timestamp.fromDate(new Date()),
        type: typeField,
        ...(locationType === 0
          ? { localisation: locationField, localisationDes: locationField2, city: ville, country: pays, lieu_event: lieu, info: infoLieu }
          : { streamLink }),
      };

      // 🔹 Promesse pour sauvegarder l'événement
      const saveEvent = async () => {
        await setDoc(doc(db, 'evenement', eventuid), eventData);
        return Promise.all([
          addDoc(collection(db, `evenement/${eventuid}/description`), { value: desc || '' }),
          setDoc(doc(db, `evenement/${eventuid}/recette`, eventuid), { recette: 0, reduction: 0, codePromo: 0, frais: 0 }),
          seance === 1
            ? addDoc(collection(db, `evenement/${eventuid}/seances`), {
              date: Timestamp.fromDate(tmpDateDebut),
              dateFin: Timestamp.fromDate(tmpDateFin),
              status: 1,
              lieu_event: '',
              city: '',
              country: '',
              localisation: '',
              participants: 0,
            })
            : Promise.resolve(),
        ]);
      };

      // 🔹 Gestion de l'upload d'image (en parallèle)
      if (selectedImage) {
        const storageRef = ref(storage, `/eventImages/${name}-${eventuid}`);
        const snapshot = await uploadBytes(storageRef, selectedImage);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setUrl(downloadURL);
        eventData.idVisuel = downloadURL;
      } else {
        eventData.idVisuel = '';
      }

      // 🔹 Exécution en parallèle des requêtes Firestore
      await saveEvent();

      setEvent(eventuid);
      setLoad(false);
    } catch (error) {
      console.error('Erreur:', error.message);
      setActiveStep(0);
      setLoad(false);
    }
  };


  // gestion activation seance
  const handleSeance = (e) => {
    try {
      setActiveSeance(parseInt(e));
      if (parseInt(e) === 0) {
        setSteps(['Informations génerales', 'Description', 'visuels'])
      } else if (parseInt(e) === 1) {
        setSteps(['Informations génerales', 'Description', 'Seances', 'visuels'])
      }

    } catch (error) {
      console.log(error.message);
    }
  };



  const handleDateDebutChange = (newDate) => {
    setDateDebut(newDate);
  };

  const handleTimeDebutChange = (newTime) => {
    console.log('parent')
    setTimeDebut(newTime);
  };
  const [dateFin, setDateFin] = useState(null);
  const [timeFin, setTimeFin] = useState('');

  const handleDateFinChange = (newDate) => {
    setDateFin(newDate);
  };

  const handleTimeFinChange = (newTime) => {
    setTimeFin(newTime);
  };

  const [differenceMsg, setDifferenceMsg] = React.useState('');


  const calculateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      setDifferenceMsg("Veuillez entrer des dates valides.");
      return 0;
    }

    if (end < start) {
      setDifferenceMsg("La date de fin doit être après la date de début.");
      return 0;
    }

    // Différence en millisecondes
    const diffMs = end - start;

    // Calcul des mois
    const startCopy = new Date(start);
    let months = 0;
    while (startCopy < end) {
      startCopy.setMonth(startCopy.getMonth() + 1);
      if (startCopy <= end) {
        // eslint-disable-next-line no-plusplus
        months++;
      }
    }

    const remainingMsAfterMonths = diffMs - months * 30 * 24 * 60 * 60 * 1000;

    // Calcul des jours
    const days = Math.floor(remainingMsAfterMonths / (1000 * 60 * 60 * 24));
    const remainingMsAfterDays = remainingMsAfterMonths % (1000 * 60 * 60 * 24);

    // Calcul des heures
    const hours = Math.floor(remainingMsAfterDays / (1000 * 60 * 60));
    const remainingMsAfterHours = remainingMsAfterDays % (1000 * 60 * 60);

    // Calcul des minutes
    const minutes = Math.floor(remainingMsAfterHours / (1000 * 60));

    // Construction du message
    const result = [];
    if (months > 0) result.push(`${months} mois`);
    if (days > 0) result.push(`${days} jours`);
    if (hours > 0) result.push(`${hours} heures`);
    if (minutes > 0) result.push(`${minutes} minutes`);

    setDifferenceMsg(result.join(", "));
    return 1
  };

  /** info de seance */
  const [seances, setSeances] = useState([{ id: Date.now(), date: null, debutTime: '', endTime: '', subtitle: '', tickets: [], error: false  }]);
  const [seancesError, setSeancesError] = useState(true);
  const weekDays = ['lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.', 'dim.'];
  const [jourMensuel, setJourMensuel] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [recurrenceDays, setRecurrenceDays] = useState([]);
  const [recurrenceDaysError, setRecurrenceDaysError] = useState(true);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);
  const [recurrenceEndDateError, setRecurrenceEndDateError] = useState(true);
  const [recurence, setRecurence] = React.useState();


  const handleChange = (event) => {
    setRecurence(event.target.value);
    if (event.target.value === 1 && seances[0].date) {
      const { recurrenceDay } = detectRecurrenceType(formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut);
      setRecurrenceDay(recurrenceDay);
      setJourMensuel(0)
    }

  };

  const [recurrenceDay, setRecurrenceDay] = useState(null);

  const addSeance = () => {
    setSeances([...seances, { id: Date.now(), date: null, debutTime: '', endTime: '', subtitle: '', tickets: [], error: false }]);
  };

  const removeSeance = (id) => {
    setSeances(seances.filter(seance => seance.id !== id));
  };

  const handleSeanceChange = (id, field, value) => {
    setSeances(seances.map(seance => seance.id === id ? { ...seance, [field]: value } : seance));
  };

  const detectRecurrenceType = (selectedDate) => {
    const date = new Date(selectedDate);
    if (isNaN(date.getTime())) {
      return null
    }
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.toLocaleDateString("fr-FR", { weekday: "long" }).toLowerCase(); // Ex: "monday"
    const month = date.getMonth();
    const year = date.getFullYear();

    // Trouver la semaine du mois (1er, 2ème, 3ème, 4ème, ou dernier)
    let week = 1;
    const tempDate = new Date(year, month, 1); // Premier jour du mois

    while (tempDate.getDate() !== dayOfMonth) {
      if (tempDate.getDay() === date.getDay()) {
        // eslint-disable-next-line no-plusplus
        week++;
      }
      tempDate.setDate(tempDate.getDate() + 1);
    }

    // Sinon, c'est un jour spécifique (ex: 2ème lundi)
    return { recurrenceType: "weekday", recurrenceDay: { week, day: dayOfWeek, dayOfMonth } };
  };

  const formatDateWithTime = (date, timeDebut, timeFin) => {
    const tmpDateDebut = new Date(date);
    console.log(tmpDateDebut)
    const [startHours, startMinutes] = timeDebut.split(':').map(Number);
    tmpDateDebut.setHours(startHours, startMinutes, 0);

    const tmpDateFin = new Date(date);
    const [endHours, endMinutes] = timeFin.split(':').map(Number);
    tmpDateFin.setHours(endHours, endMinutes, 0);

    // Si la date de fin est inférieure à la date de début, ajoutez un jour
    if (tmpDateFin < tmpDateDebut) {
      tmpDateFin.setDate(tmpDateFin.getDate() + 1);
    }

    return {
      dateDebut: tmpDateDebut,
      dateFin: tmpDateFin,
    };
  };


  const toggleRecurrenceDay = (day) => {
    setRecurrenceDays(prevDays =>
      prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
    );
  };

  const genererSeances = () => {
    const tmpSeances = [];
    const currentDate = formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut;
    const endDate = new Date(recurrenceEndDate);
    console.log(endDate)
    console.log(recurrenceEndDate)
    setLoad(true)
    while (currentDate <= endDate) {
      console.log("Date en cours :", currentDate);

      let shouldAdd = false;
      if (recurence === 2) {
        shouldAdd = true;
      }
      else if (recurence === 0) { // Quotidien ou hebdomadaire
        console.log("/////////////////1")
        console.log(recurrenceDays)
        const jourSemaine = format(currentDate, 'eee', {
          locale: fr,
        }).toString()
        console.log(jourSemaine)
        console.log("/////////////////2")
        if (recurrenceDays.includes(jourSemaine)) {
          shouldAdd = true;
          console.log("/////////////////3")
        }
      } else if (recurence === 1) { // Mensuel
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const dayOfMonth = currentDate.getDate();

        if (jourMensuel === 0) {
          // 📌 Cas 1 : Un jour précis du mois (ex: le 10, 15, 20…)
          if (recurrenceDay?.dayOfMonth === dayOfMonth) {
            shouldAdd = true;
          }
        } else if (jourMensuel === 1) {
          // 📌 Cas 2 : Un jour spécifique de la semaine (ex: deuxième lundi)
          const { week, day } = recurrenceDay; // ex: { week: 2, day: "monday" }
          const specificWeekday = getNthWeekdayOfMonth(year, month, week, day);
          currentDate.setDate(specificWeekday + 1)
          shouldAdd = true;

        }
      }

      if (shouldAdd) {
        console.log("Séance ajoutée :", currentDate);

        const tmpDateFin = new Date(currentDate);
        const [endHours, endMinutes] = seances[0].endTime.split(':').map(Number);
        tmpDateFin.setHours(endHours, endMinutes, 0);

        if (tmpDateFin < currentDate) {
          tmpDateFin.setDate(tmpDateFin.getDate() + 1);
        }

        tmpSeances.push({
          start: currentDate.toJSON(),
          fin: tmpDateFin.toJSON(),
        });
      }

      // Incrémentation de la date
      /* if (recurence === 2 || recurence === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 1) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (jourMensuel === 1) {
          currentDate.setDate(1); // Revenir au premier jour du mois avant de vérifier les conditions
        }
 
      } */

      if (recurence === 2) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 1) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (jourMensuel === 1) {
          currentDate.setDate(1); // Revenir au premier jour du mois avant de vérifier les conditions
        }
      }
    }
    setLoad(false)
    return tmpSeances;
  };

  /**
 * 📌 Fonction pour récupérer le deuxième lundi d'un mois donné
 */

  const getNthWeekdayOfMonth = (year, month, week, day) => {
    const weekdays = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
    const dayIndex = weekdays.indexOf(day.toLowerCase());

    const date = new Date(year, month, 1);
    let count = 0;

    while (date.getMonth() === month) {
      if (date.getDay() === dayIndex) {
        count++;
        if (count === week) {
          return date.getDate();
        }
      }
      date.setDate(date.getDate() + 1);
    }

    return null;
  };

  
  const handleSubmit1 = async (e) => {
    setLoad(true);
    try {
      

      // const tmpDateDebut = formatDateWithTime(dateDebut, timeDebut);
      // const tmpDateFin = formatDateWithTime(dateFin, timeFin);

      // 🔹 Génération d'un ID unique pour l'événement
      const generateUniqueEventId = async () => {
        let eventuid; let eventSnap;
        do {
          eventuid = randomstring.generate({ length: 10, charset: "alphanumeric", capitalization: 'uppercase' });
          eventSnap = await getDoc(doc(db, 'evenement', eventuid));
        } while (eventSnap.exists());
        return eventuid;
      };

      const eventuid = await generateUniqueEventId();

      // 🔹 Récupération des données de l'organisateur
      const docSnap = await getDoc(doc(db, 'organisateurs', user.uid));
      if (!docSnap.exists()) throw new Error("Organisateur introuvable");

      const orgId = docSnap.data().idOrg;

      // 🔹 Préparer les données de l'événement
      const eventData = {
        locationType,
        controleurSet: false,
        // dateDebut: Timestamp.fromDate(tmpDateDebut),
        // dateFin: Timestamp.fromDate(tmpDateFin),
        isImageSet: !!selectedImage,
        isTicketCreated: false,
        nomEvent: name.toLowerCase(),
        name,
        orgId,
        participant: 0,
        status: 0,
        seanceActive: seance === 1 ? 1 : 0,
        createDate: Timestamp.fromDate(new Date()),
        type: typeField,
        ...(locationType === 0
          ? { localisation: locationField, localisationDes: locationField2, city: ville, country: pays, lieu_event: lieu, info: infoLieu }
          : { streamLink }),
      };

      // 🔹 Vérification et création des séances
      const seancesToAdd = isRecurrent ? genererSeances() : seances.map(seance => {
        const tmpDate = formatDateWithTime(seance.date, seance.debutTime, seance.endTime);

        return {
          date: tmpDate.dateDebut,
          fin: tmpDate.dateFin,
          subtitle: seance.subtitle ? seance.subtitle : ''
        };
      });

      if (!seancesToAdd || seancesToAdd.length === 0) {
        console.error("Erreur : Aucune séance à ajouter.");
        // showSnackbar('Aucune séance à ajouter.');
        setLoad(false);
        return;
      }

      console.log("Séances à ajouter :", seancesToAdd);
      console.log("Séances à ajouter :", seances);

      // 🔹 Gestion de l'upload d'image (en parallèle)
      if (selectedImage) {
        const storageRef = ref(storage, `/eventImages/${name}-${eventuid}`);
        const snapshot = await uploadBytes(storageRef, selectedImage);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setUrl(downloadURL);
        eventData.idVisuel = downloadURL;
      } else {
        eventData.idVisuel = '';
      }

      // 🔹 Exécution en parallèle des requêtes Firestore
      await Promise.all([
        setDoc(doc(db, 'evenement', eventuid), eventData),
        addDoc(collection(db, `evenement/${eventuid}/description`), { value: desc || '' }),
        setDoc(doc(db, `evenement/${eventuid}/recette`, eventuid), { recette: 0, reduction: 0, codePromo: 0, frais: 0 }),
      ]);

      let minDate = new Date(seancesToAdd[0].start || seancesToAdd[0].date);
      let maxDate = new Date(seancesToAdd[0].fin);

      // 🔹 Création des séances en parallèle
      await Promise.all(seancesToAdd.map(async (seance) => {
        const startDate = new Date(seance.start || seance.date);
        const endDate = new Date(seance.fin);
        console.log(startDate)
        console.log(seance)
        await addDoc(collection(db, `evenement/${eventuid}/seances`), {
          date: Timestamp.fromDate(startDate),
          fin: Timestamp.fromDate(endDate),
          status: 1,
          participants: 0,
          name: !isRecurrent && seance.subtitle ? seance.subtitle : '',
        });

        if (!minDate || startDate < minDate) minDate = startDate;
        if (!maxDate || endDate > maxDate) maxDate = endDate;
      }));

      // 🔹 Mise à jour des dates min et max de l'événement
      if (minDate && maxDate) {
        await updateDoc(doc(db, 'evenement', eventuid), {
          dateDebut: Timestamp.fromDate(minDate),
          dateFin: Timestamp.fromDate(maxDate),
        });
        console.log("Dates de l'événement mises à jour :", minDate, maxDate);
      }

      setEvent(eventuid);
      setLoad(false);
    } catch (error) {
      console.error('Erreur:', error);
      setActiveStep(0);
      setLoad(false);
    }
  };

  // mis a jour de la variable jour du mois 
  React.useEffect(() => {
    try {
      if (recurence === 1) {
        const { recurrenceDay } = detectRecurrenceType(formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut);
        setRecurrenceDay(recurrenceDay);
        setJourMensuel(0)
      }
    } catch {
      //
    }

  }, [seances]);

  /** fin info seance */
  React.useEffect(() => {

    const tmpDateDebut = new Date(dateDebut);
    // Utiliser setHours pour remplacer l'heure et les minutes
    tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
    const tmpDateFin = new Date(dateFin);
    // Utiliser setHours pour remplacer l'heure et les minutes
    tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
    calculateDifference(tmpDateDebut, tmpDateFin)

  }, [dateDebut, dateFin, timeDebut, timeFin]);

  const [countSeances, setCountSeances] = useState(0);
  React.useEffect(() => {

    try {
      const data = genererSeances()
      setCountSeances(data.length)
    } catch (error) {
      console.log(error)
    }
  }, [recurrenceEndDate, seances, jourMensuel, recurrenceDays, recurence]);

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper orientation={mdUp ? `horizontal` : "vertical"} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          {
            load === true ? <Box height={'200px'} width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box> :
              <>
                <Container sx={{ p: 10 }}>
                  <Confetti />
                  <Stack display="flex" justifyContent="center" alignItems="center" spacing={1}>
                    <Typography textAlign="center" variant="subtitle1">
                      Félicitation, vous venez créer votre événement.
                      <br />
                      Continuer vers votre page d'administration pour créer vos différents tickets et publier votre evenement
                    </Typography>
                  </Stack>
                </Container>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <LoadingButton
                    loading={load}
                    loadingPosition="end"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    component={RouterLink}
                    to={`/${event}/dashboard/app/`}
                  >
                    {load ? 'encours' : "Continuer vers le panel d'administration"}
                  </LoadingButton>
                </Box>
              </>
          }

        </>
      ) : (
        <>
          {activeStep === 0 ? (
            <Container sx={{ padding: 0 }}>
              <Stack paddingTop={3} spacing={3}>
                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    {!nameError ? (
                      <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Nom de l'événement"
                        required
                        fullWidth
                      />
                    ) : (
                      <TextField
                        error
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Nom de l'événement"
                        required
                        fullWidth
                        helperText="Le nom de l'événement est obligatoire"
                      />
                    )}
                  </Box>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography>Type d'évenement</Typography>
                      </Grid>

                      <Grid item xs={12} sm={8} md={8}>
                        {!typeFieldError ? (
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeField}
                            placeholder="Indiqué le type d'évenement"
                            onChange={(e) => setTypeField(e.target.value)}
                          >
                            <MenuItem value={'Concert'}>Concert</MenuItem>
                            <MenuItem value={'Festival'}>Festival</MenuItem>
                            <MenuItem value={'Atelier'}>Atelier</MenuItem>
                            <MenuItem value={'Compétition'}>Compétition</MenuItem>
                            <MenuItem value={'Conférence'}>Conférence</MenuItem>
                            <MenuItem value={'Cours'}>Cours</MenuItem>
                            <MenuItem value={'Défilé'}>Défilé</MenuItem>
                            <MenuItem value={'Dégustation'}>Dégustation</MenuItem>
                            <MenuItem value={'Exposition'}>Exposition</MenuItem>
                            <MenuItem value={'Formation'}>Formation</MenuItem>
                            <MenuItem value={'Forum'}>Forum</MenuItem>
                            <MenuItem value={'Randonnée'}>Randonnée</MenuItem>
                            <MenuItem value={'Soirée clubbing'}>Soirée clubbing</MenuItem>
                            <MenuItem value={'Spétacle'}>Spétacle</MenuItem>
                          </Select>
                        ) : (
                          <Select
                            error
                            helperText="Le nom de l'événement est obligatoire"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeField}
                            placeholder="Indiqué le type d'évenement"
                            onChange={(e) => setTypeField(e.target.value)}
                          >
                            <MenuItem value={'Concert'}>Concert</MenuItem>
                            <MenuItem value={'Festival'}>Festival</MenuItem>
                            <MenuItem value={'Atelier'}>Atelier</MenuItem>
                            <MenuItem value={'Compétition'}>Compétition</MenuItem>
                            <MenuItem value={'Conférence'}>Conférence</MenuItem>
                            <MenuItem value={'Cours'}>Cours</MenuItem>
                            <MenuItem value={'Défilé'}>Défilé</MenuItem>
                            <MenuItem value={'Dégustation'}>Dégustation</MenuItem>
                            <MenuItem value={'Exposition'}>Exposition</MenuItem>
                            <MenuItem value={'Formation'}>Formation</MenuItem>
                            <MenuItem value={'Forum'}>Forum</MenuItem>
                            <MenuItem value={'Randonnée'}>Randonnée</MenuItem>
                            <MenuItem value={'Soirée clubbing'}>Soirée clubbing</MenuItem>
                            <MenuItem value={'Spétacle'}>Spétacle</MenuItem>
                          </Select>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Dates</Typography>
                    <Typography paddingBottom={2} variant="body2">Renseigner les dates de votre évènement</Typography>

                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={6} sm={4} md={4}>
                        <Typography variant='subtitle1'> Type de date</Typography>
                      </Grid>
                    </Grid>
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={6} sm={8} md={8}>
                        <Stack my={2} direction='row' spacing={2}>
                          <Box onClick={() => handleSeance(0)} sx={seance === 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                            :
                            { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.5} >

                            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                              <Iconify icon={'material-symbols-light:event-outline'} sx={{ minWidth: 30, minHeight: 30 }} />
                              <Stack justifyContent={'center'}>
                                <Typography variant='subtitle1'>Evénement unique</Typography>
                                <Typography variant='caption'>Pour les événements qui n'ont lieu qu'une seule fois</Typography>
                              </Stack>
                            </Stack>
                          </Box>
                          <Box onClick={() => handleSeance(1)} sx={seance === 1 ? { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                            :
                            { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.5} >
                            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                              <Iconify icon={'material-symbols-light:event-list-outline'} sx={{ minWidth: 30, minHeight: 30 }} />
                              <Stack justifyContent={'center'}>
                                <Typography variant='subtitle1'>Evénement à seance</Typography>
                                <Typography variant='caption'>A lieu sur plusieurs seances</Typography>
                              </Stack>

                            </Stack>

                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                    {/** message d'information sur le mode seance */}
                    {seance === 1 && (
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} >
                          <Alert severity="info" sx={{ padding: 1 }}>
                            <AlertTitle>Mode seance</AlertTitle>
                            <Typography variant='body2'>le mode seance vous permets de créer des evenements sur plusieurs dates différentes. Vous pourrez ajouter des dates à l'étape plus tard.</Typography>
                          </Alert>
                        </Grid>
                      </Grid>
                    )}
                    {seance === 0 ? (

                      <Stack spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px', border: `${dateError ? '1px solid red' : null}` }}>
                        <Box>
                          <Typography>Début</Typography>
                        </Box>
                        <Stack spacing={1}>
                          <CustomDateTimePicker errorDate={dateDebutError} errorHour={timeDebutError} date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                        </Stack>
                        <Box paddingTop={2}>
                          <Typography>Fin</Typography>
                        </Box>
                        <Stack spacing={1}>
                          <CustomDateTimePicker errorDate={dateFintError} errorHour={timeFinError} date={dateFin} time={timeFin} minDate={dateDebut || null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                        </Stack>
                      </Stack>
                    ) : null
                      /* <Stack sx={{ border: '2px solid gainsboro', borderRadius: '7px', marginBottom: 2 }}>
                       <Box paddingX={2} paddingY={1}>
                         <Stack direction="row" alignItems="center" justifyContent="space-between">
                           <Typography variant="body2">
                             <strong>Premiere séance</strong>
                           </Typography>
                         </Stack>
                       </Box>
                       <Divider />
                       <Stack margin={2} spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px', border: `${dateError ? '1px solid red' : null}` }}>
                         <Box>
                           <Typography>Début</Typography>
                         </Box>
                         <Stack spacing={1}>
                           <CustomDateTimePicker errorDate={dateDebutError} errorHour={timeDebutError} date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                         </Stack>
                         <Box paddingTop={2}>
                           <Typography>Fin</Typography>
                         </Box>
                         <Stack spacing={1}>
                           <CustomDateTimePicker errorDate={dateFintError} errorHour={timeFinError} date={dateFin} time={timeFin} minDate={dateDebut || null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                         </Stack>
                       </Stack>
                     </Stack> */
                    }
                    <Box paddingY={1}>
                      <Typography variant='body2'>Votre {seance === 0 ? 'evenement' : 'seance'} durera <strong>{differenceMsg}</strong></Typography>
                    </Box>
                  </Box>

                </Box>

                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h5">Localisation</Typography>
                    <Typography variant="body2">Préciser la localisation de votre évènement</Typography>

                    <Grid paddingY={3} alignItems="center" container spacing={3}>
                      <Grid item xs={12}>
                        <Stack my={2} direction='row' spacing={1}>
                          <Box onClick={() => setLocationType(0)} sx={locationType === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >

                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                              <Iconify icon={'basil:location-outline'} />
                              <Typography variant='body2'>Lieu</Typography>
                            </Stack>
                          </Box>
                          <Box onClick={() => setLocationType(1)} sx={locationType === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                            :
                            { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                              <Iconify icon={'streamline:web'} />
                              <Typography variant='body2'>Evénement en ligne</Typography>
                            </Stack>

                          </Box>
                        </Stack>
                      </Grid>
                      {
                        locationType === 0 ?
                          <Grid item xs={12}>
                            {isLoaded && (
                              <PlacesAutocomplete
                                value={locationField2}
                                onChange={setLocation2}
                                onSelect={handleSelect}
                                searchOptions={searchOptions}
                                googleCallbackName=""
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    {!lieuError ? (
                                      <TextField
                                        {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                        fullWidth
                                        onFocus={(e) => { e.target.select() }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <Iconify icon="iconoir:search" />
                                            </InputAdornment>
                                          ),

                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    ) : (
                                      <TextField
                                        {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                        error
                                        helperText="Le lieu de l'évnement est importante. veuillez remplir ce champs"
                                        fullWidth
                                        onFocus={(e) => { e.target.select() }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <Iconify icon="iconoir:search" />
                                            </InputAdornment>
                                          ),

                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                    <Card>
                                      {loading && (
                                        <Typography variant="body1" paddingX={1}>
                                          Recherche...
                                        </Typography>
                                      )}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 2 }
                                          : { backgroundColor: '#ffffff', cursor: 'pointer', padding: 2 };
                                        return (
                                          <Box
                                            key={suggestion.placeId}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <Typography variant="body1" paddingX={1}>
                                              {suggestion.description}
                                            </Typography>
                                          </Box>
                                        );
                                      })}
                                    </Card>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                          </Grid>
                          :
                          <Grid item xs={12}>
                            <Stack spacing={2}>
                              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Iconify icon={'material-symbols-light:link'} sx={{ width: 24, height: 24 }} />
                                <Typography variant='subtitle1'>Ajouter le lien de votre flux video</Typography>
                              </Stack>
                              <TextField
                                value={streamLink}
                                onChange={(e) => setStreamLink(e.target.value)}
                                placeholder="https://www.video-provider.com/"
                                required
                                fullWidth
                                error={streamLinkError}
                              />
                            </Stack>
                          </Grid>
                      }

                    </Grid>
                    {
                      locationType === 0 && <>
                        <Typography variant="body2">{locationField2}</Typography>
                        <Box sx={{ height: 180, borderRadius: '7px' }}>
                          <Maps loading={isLoaded} centerC={locationField} height={180} />
                        </Box>
                      </>
                    }

                  </Box>
                </Box>
                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Réseau sociaux / page web de l'evenement</Typography>
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          value={webLink}
                          label="Site web"
                          type="text"
                          fullWidth
                          onChange={(e) => setWeb(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={'mdi:web'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          value={fbLink}
                          label="Facebook"
                          type="text"
                          fullWidth
                          onChange={(e) => setFb(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={'fa-brands:facebook'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          value={instaLink}
                          label="Instagram"
                          type="text"
                          fullWidth
                          onChange={(e) => setInsta(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={'mdi:instagram'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Stack>
            </Container>
          ) : null}

          {activeStep === 1 ? (
            <Container sx={{ padding: 0 }}>
              <Stack paddingTop={3} spacing={3}>
                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Description</Typography>
                    <Typography variant="body2">
                      Cette description apparaitra sur la page de l’évènement. Ce champ est optionnel et peut-être édité plus tard
                    </Typography>
                    <Grid paddingY={3} container>
                      <Grid item xs={12}>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={desc}
                          onChange={handleChangeQuill}
                          placeholder="Saisissez une description pour votre evenement ici..."
                        />
                      </Grid>
                      {/* <div dangerouslySetInnerHTML={{ __html: value }}></div> */}
                    </Grid>
                  </Box>
                </Box>
              </Stack>
            </Container>
          ) : null}
          {(activeStep === 2 && seance === 1) ? (

            <Container>
              {/** seance */}
              <Stack paddingTop={3} spacing={3}>
                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Type de seance</Typography>
                    <Typography variant="body2">
                      Souhaitez vous créer de une seance repetes ou plusieurs seances distinctes ?
                    </Typography>
                    <Box paddingTop={3} dir="ltr">

                      <FormControl fullWidth>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={isRecurrent}
                          onChange={(e) => setIsRecurrent(e.target.value)}
                        >
                          <MenuItem value={false}>Plusieurs seances</MenuItem>
                          <MenuItem value>Seances repétés</MenuItem>
                        </Select>
                      </FormControl>

                    </Box>
                  </Box>
                </Box>
                <Stack spacing={1} sx={{ border: '2px solid #ddd', borderRadius: '7px', marginBottom: 10 }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Informations sur vos seances</Typography>
                    <Typography variant="body2">
                      Entrer les dates de debut et de fin vos seances
                    </Typography>
                  </Box>
                  <Stack padding={3}>
                    {seances.map(seance => (
                      <>
                        <Stack key={seance.id} sx={{ border: '1px solid #ddd', borderRadius: '10px', padding: 2 }}>
                          {!isRecurrent && seances.length > 1 &&
                            <Stack direction="row" justifyContent="end" >
                              <IconButton onClick={() => removeSeance(seance.id)}>
                                <Iconify icon="typcn:delete" sx={{ color: 'red' }} />
                              </IconButton>
                            </Stack>}
                          <Stack spacing={2}>
                            {
                              !isRecurrent && <TextField placeholder='Sous-titre de la seance: jour1, partie1, manche1...' value={seance.subtitle} onChange={(e) => handleSeanceChange(seance.id, 'subtitle', e.target.value)} />
                            }
                            <Box sx={seance.error ? { border: '1px solid red' } : {}}>
                              <CustomSeanceDateTimePicker
                                date={seance.date}
                                debutTime={seance.debutTime}
                                endTime={seance.endTime}
                                onDateChange={value => handleSeanceChange(seance.id, 'date', value)}
                                onDebutTimeChange={value => handleSeanceChange(seance.id, 'debutTime', value)}
                                onEndTimeChange={value => handleSeanceChange(seance.id, 'endTime', value)}
                              />
                            </Box>
                          </Stack>

                          {isRecurrent && (
                            <Stack spacing={2} marginTop={2}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Recurence</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={recurence}
                                  label="Recurence"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={2}>Quotien</MenuItem>
                                  <MenuItem value={0}>Hebdomadaire</MenuItem>
                                  <MenuItem value={1}>Mensuel</MenuItem>
                                </Select>
                              </FormControl>
                              {
                                recurence === 0 ?
                                  <>
                                    <Typography>le</Typography>

                                    <Stack spacing={1} direction={'row'}>
                                      {weekDays.map(day => (
                                        <Avatar
                                          sx={recurrenceDays.includes(day) ? { width: 30, height: 30, cursor: 'pointer', bgcolor: '#5E17EB', color: 'white', border: '1px solid #f2f2f2' } : { width: 30, height: 30, cursor: 'pointer', bgcolor: 'white', color: 'black', border: '1px solid #f2f2f2' }}
                                          key={day}
                                          variant={recurrenceDays.includes(day) ? 'contained' : 'outlined'}
                                          onClick={() => toggleRecurrenceDay(day)}
                                        >
                                          <Typography variant='caption'>{day}</Typography>
                                        </Avatar>
                                      ))}
                                    </Stack>
                                    {
                                      recurrenceDaysError && <Typography variant='body2' sx={{ color: 'red' }}>Veuillez selectionner les jours de recurrence</Typography>
                                    }

                                  </>
                                  : recurence === 1 ?

                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">Jour du mois</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={jourMensuel}
                                        label="Jour du mois"
                                        onChange={(e) => setJourMensuel(e.target.value)}
                                      >
                                        <MenuItem value={0}>le {recurrenceDay?.dayOfMonth}</MenuItem>
                                        <MenuItem value={1}>le {recurrenceDay?.week}{recurrenceDay?.week === 1 ? 'er' : 'e'} {recurrenceDay?.day}</MenuItem>
                                      </Select>
                                    </FormControl> : null
                              }


                              <TextField
                                label="Fin"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={recurrenceEndDate}
                                onChange={(e) => setRecurrenceEndDate(e.target.value)}
                                fullWidth
                                error={!!recurrenceEndDateError}
                                helperText='Ce champs est requis'
                              />

                            </Stack>
                          )}
                        </Stack>
                        <Divider />
                        {
                          isRecurrent && recurrenceEndDate && <Box padding={3}>
                            <Stack><Typography variant='body2'>Votre evenement se tiendra sur <strong>{countSeances}</strong> séances</Typography></Stack>
                          </Box>
                        }
                      </>

                    ))}
                  </Stack>

                  {
                    !isRecurrent && <CustomButton type={'tertiaire'} buttonText={'Ajouter une séance'} onClick={addSeance} />
                  }

                </Stack>
                {/** seance */}
              </Stack>
            </Container>
          ) : null}
          {(activeStep === 2 && seance === 0) || (activeStep === 3 && seance === 1) ? (
            <Container>
              <Stack paddingTop={3} spacing={3}>
                <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h4">Visuel</Typography>
                    <Typography variant="body2">
                      La bannière sera utilisée à travers l’app et le site web Choptonticket. Choisissez-en une qui corresponde bien à votre identité de marque.
                    </Typography>
                    <Box sx={{ p: 3 }} dir="ltr">

                      <Grid item xs={12} sm={10}>
                        {imageUrl && (<Grid container sx={{
                          position: 'relative', // Important pour superposer le contenu sur l'image
                          bgcolor: 'rgba(245, 245, 245, 0.5)', // Couleur de fond avec opacité
                          direction: 'flex',
                          justifyContent: 'center',
                          borderRadius: { xs: '12px', sm: '0px' },
                          overflow: 'hidden', // Pour éviter que le flou dépasse les bordures
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `url(${imageUrl})`, // Remplace par l'URL de ton image
                            backgroundSize: 'cover', // Adapter l'image pour couvrir tout le container
                            backgroundPosition: 'center', // Centrer l'image
                            filter: 'blur(8px)', // Effet de flou
                            zIndex: -1, // Met l'image derrière le contenu
                          },
                        }}>
                          <Grid item xs={12} sm={9}>
                            <Paper elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden' }}>

                              <img
                                src={imageUrl}
                                alt={imageUrl}
                                style={{
                                  width: '100%',
                                  minHeight: { xs: '200px', sm: '425px' },
                                  maxHeight: { xs: '200px', sm: '425px' },
                                  objectFit: 'cover',

                                }}
                              />
                            </Paper>
                          </Grid>
                        </Grid>)}
                        {!imageUrl && (

                          <Box p={2}>
                            <Button
                              fullWidth
                              disableElevation
                              size="medium"
                              sx={{
                                marginTop: 2,
                                textTransform: 'initial',
                                boxShadow: 'none',
                                border: '1px dashed gainsboro',
                                color: 'black',
                                backgroundColor: '#f2f2f2',
                                '&:hover': {
                                  backgroundColor: '#f2f2f2',
                                  color: '#5E17EB',
                                  borderColor: '#5E17EB',
                                },
                              }}
                              variant="contained"
                              component="label"
                            >
                              <input
                                hidden
                                accept="image/*"
                                id="select-image-button"
                                type="file"
                                onChange={(e) => setSelectedImage(e.target.files[0])}
                              />
                              <Stack
                                padding={2}
                                sx={{
                                  justifyContent: 'space-around',
                                  alignItems: 'center',

                                }}
                              >

                                <>
                                  <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, }} />
                                  <Typography variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                                  <Typography variant='caption'>Seul les types de fichier .csv, .xsl sont acceptés</Typography>
                                </>

                              </Stack>
                            </Button>
                          </Box>

                        )}
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', paddingY: 2 }}>
                          <Button
                            size="medium"
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              Color: 'white',
                              backgroundColor: 'black',
                              borderRadius: '7px',
                              boxShadow: 'none',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                                borderColor: 'black',
                              },
                            }}
                            startIcon={<Iconify icon="material-symbols:download" />}
                            variant="contained"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              id="select-image-download-button"
                              type="file"
                              onChange={(e) => setSelectedImage(e.target.files[0])}
                            />
                            Parcourir
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Container>
          ) : null}

          <Box sx={{ display: 'flex', flexDirection: 'row', p: 3 }}>
            <CustomButton
              loading={load}
              disabled={activeStep === 0}
              type='secondaire'
              buttonText="Retour"
              onClick={handleBack}
            />
            <Box sx={{ flex: '1 1 auto' }} />
            <CustomButton
              loading={load}
              type='primaire'
              buttonText={activeStep === steps.length - 1 ? 'Créé mon évenement' : 'Suivant'}
              onClick={handleNext}
            />
          </Box>
        </>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Stack spacing={1} direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='caption'>{msg.content}{' '}</Typography>
            <Iconify
              sx={{ color: msg.color }}
              icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Stack>
        }
      />
    </Box>
  );
}

import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  Button,
  Alert,
  AccordionDetails,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// hooks
import { deepPurple } from '@mui/material/colors';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections

import BasicTabs from '../components/home/BasicTabs';
import HomeNavbar from '../layouts/home/HomeNavbar';
import HorizontalLinearStepper from '../components/HorizontalStepper';
import Iconify from '../components/Iconify';
import CustomButton from '../components/CustomButton';

// ---------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: `calc(100%*0.8)`,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: '1px solid black',
  borderRadius: '7px',
  backgroundColor: 'transparent',
  color: 'black',
  marginBottom: 2,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------

export default function NewEvent() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Page title="Nouvel événement">
      <RootStyle>
        <HomeNavbar onOpenSidebar={() => setOpen(true)} />

        <Container>
          {mdUp ? (
            <ContentStyle>
              <Grid container paddingBottom={2} spacing={2}>
                <Grid textAlign="left" item xs={8}>
                  <CustomButton
                    startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                    type='secondaire'
                    buttonText="Annuler"
                    component={RouterLink}
                    to="/bo/"
                  />
                </Grid>
              </Grid>
              <Box sx={{ padding: 2 }} />
              <HorizontalLinearStepper />
            </ContentStyle>
          ) : (
            <>
              <Grid container paddingTop={10} paddingBottom={3} spacing={6}>
                <Grid item xs={8}>
                <CustomButton
                  startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                    type='secondaire'
                    buttonText="Annuler"
                    component={RouterLink}
                    to="/bo/"
                  />
                </Grid>
              </Grid>
              <Box sx={{ padding: 2 }} />
              <HorizontalLinearStepper />
            </>
          )}
        </Container>
      </RootStyle>
    </Page>
  );
}

/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TableHead,
  Grid,
  Box,
  TextField,
  MenuItem,
  useTheme,
  IconButton,
  Backdrop,
  CircularProgress,
  Skeleton,
  InputAdornment,
  Menu,
  Avatar,
  Modal,
  Divider,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock

import { auth, db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import CustomButton from '../../../../components/CustomButton';
import CustomTag from '../../../../components/customTag';
import { hasPermission } from '../../../../utils/functions';
import { useSnackbar } from '../../../../context/snackbarContext';
import withAuthorization from '../../../../withAuthorization';

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: 5,
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));


const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  '@media (max-width: 600px)': {
    width: '90%', // Redimensionne sur mobile
  },
};
// ----------------------------------------------------------------------



function Utilisateurs() {
 
  const {  userData, permissions } = UserAuth();
  const showSnackbar = useSnackbar()
  const navigate = useNavigate();

  const [orgID, setOrgID] = React.useState();

  // loading
  const [loading, setLoading] = React.useState(false);

  // GESTION DE LA MODIFICATION
  const { generalReload, setGeneralReload } = Variables();





  // for change value of globla quota
  const [open, setOpen] = React.useState(false);

  const [refreh, setRefresh] = React.useState(false);


 

  // take operateurs from firebase
  const [team, setTeam] = React.useState([]);



  const [openRecetteModal, setOpenRecetteModal] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [roles, setRole] = React.useState([]);
  
  const [selectedRoleId, setSelectedRoleId] = React.useState();
  const [selectedRoleName, setSelectedRoleName] = React.useState([]);

  const handleChangeRole = (event) => {
    console.log(event.target.value)
    /* setSelectedRoleIndex(event.target.value);
    setSelectedRole({ id: roles[event.target.value].id, name: roles[event.target.value]?.data().name }) */
    const selectedRoleId = event.target.value; // Récupérer l'ID du rôle sélectionné
    const selectedRole = roles.find(role => role.id === selectedRoleId); // Trouver l'objet rôle correspondant

    if (selectedRole) {
        setSelectedRoleId(selectedRole.id);
        setSelectedRoleName(selectedRole.data().name); // Mettre à jour le nom du rôle
    }
  };
  const handleOpenRecetteModal = async () => {
    setOpenRecetteModal(true);

  };

  
  const handleCloseRecetteModal = () => {
    setEmail()
    setSelectedRoleId()
    setSelectedRoleName()
    setSelectedIndex()
    setOpenRecetteModal(false);
  }


  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleInvite = async () => {
    setLoading(true)
    if (!email || !selectedRoleId || !selectedRoleName) {
      showSnackbar("Veuillez remplir tous les champs.", true);
      return;
    }

    try {
      const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/inviteUserToOrganization', {
        method: 'POST',
        body: JSON.stringify({
          email,
          orgId: userData.idOrg,
          roleId: selectedRoleId,
          roleName: selectedRoleName,
          userId: auth.currentUser.uid
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data)
      if (response.ok) {
        showSnackbar("Invitation envoyée avec succès !");
        setEmail("");
        setSelectedRoleId();
        setSelectedRoleName();
        handleCloseRecetteModal()
        setGeneralReload(!generalReload)
      } else {
        showSnackbar(data.error || "Une erreur est survenue.", true);
      }
    } catch (error) {
      console.error("Erreur lors de l'invitation :", error);
      showSnackbar("Une erreur est survenue.", true);
    } finally {
      setLoading(false)
    }
  };

  const updateTeamMember = () => {
    setEmail(team[selectedIndex].email)
    setSelectedRoleId(team[selectedIndex].roleId)
    handleOpenRecetteModal()
  }

  const handleDeleted = async () => {
    // console.log(team[selectedIndex].data())
    setLoading(true)
    try {
      const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/deleteUserToOrganization', {
        method: 'POST',
        body: JSON.stringify({
          id: team[selectedIndex].id,
          invitationId: team[selectedIndex].invitation,
          userId: team[selectedIndex].userId
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data)
      if (response.ok) {
        showSnackbar("Invitation envoyée avec succès !");
        setEmail("");
        setSelectedRoleId();
        setSelectedRoleName();
        handleCloseRecetteModal()
        setGeneralReload(!generalReload)
      } else {
        showSnackbar(data.error || "Une erreur est survenue.", true);
      }
      setSelectedIndex();
    } catch (error) {
      console.error("Erreur lors de l'invitation :", error);
      showSnackbar("Une erreur est survenue.", true);
      setSelectedIndex();
    }
    finally {
      setLoading(false)
    }
  };

  const handleUpdateRole = async () => {
    setLoading(true)
    try {
      const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/updateRoleToUser', {
        method: 'POST',
        body: JSON.stringify({
          id: team[selectedIndex].id,
          orgId: userData.idOrg,
          newRoleId: selectedRoleId,
          newRoleName: selectedRoleName,
          userId: team[selectedIndex].userId
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data)
      if (response.ok) {
        showSnackbar("Invitation envoyée avec succès !");
        setEmail("");
        setSelectedRoleId();
        setSelectedRoleName();
        handleCloseRecetteModal()
        setGeneralReload(!generalReload)
      } else {
        showSnackbar(data.error || "Une erreur est survenue.", true);
      }
      setSelectedIndex();
    } catch (error) {
      console.error("Erreur lors de l'invitation :", error);
      showSnackbar("Une erreur est survenue.", true);
      setSelectedIndex();
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const fetchTeam = async () => {
        setTeam([]);
        if (!userData) {
            return;
        }

        try {
            setLoading(true);
            const q = query(
                collection(db, `organisations/${userData.idOrg}/team`),
                orderBy("invitedAt", "asc") // ✅ Trier par date d'invitation en ordre croissant
            );
            const snapshot = await getDocs(q);

            const teamData = await Promise.all(snapshot.docs.map(async (d) => {
                const data = d.data();

                // ✅ Récupérer le nom du rôle
                let roleName = "Rôle inconnu";
                if (data.roleId) {
                    const roleRef = doc(db, `organisations/${userData.idOrg}/roles/${data.roleId}`);
                    const roleSnap = await getDoc(roleRef);
                    if (roleSnap.exists()) {
                        roleName = roleSnap.data().name;
                    }
                }

                // ✅ Récupérer les informations de l'utilisateur ayant envoyé l'invitation
                let invitedByInfo = null;
                if (data.invitedBy) {
                    const userRef = doc(db, `users/${data.invitedBy}`);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        invitedByInfo = userSnap.data();
                    }
                }

                return {
                    id: d.id,
                    ...data,
                    roleName,
                    invitedByInfo, // ✅ Informations sur l'utilisateur ayant envoyé l'invitation
                };
            }));
            console.log(teamData)
            setTeam(teamData);
            setOrgID(userData.idOrg);
            setLoading(false);
        } catch (error) {
            console.log("❌ Erreur lors de la récupération de l'équipe :", error);
            setLoading(false);
        }
    };

    fetchTeam();
}, [userData, generalReload]);

  React.useEffect(() => {
    (async () => {
      setRole([]);
      try {
        setLoading(true);

        const q = query(collection(db, `organisations/${userData.idOrg}/roles`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          if (d.data().permissionId) {
            console.log('-ss--s')
            const baseRoleRef = doc(db, 'admins/basedRole/roles', `${d.data().permissionId}`);
            const baseRoleSnap = await getDoc(baseRoleRef);
            if (baseRoleSnap.exists()) {
              setRole((roles) => [...roles, baseRoleSnap]);
            }
          }
          else {
            setRole((roles) => [...roles, d]);
          }
          // setRole((roles) => [...roles, d]);
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [userData]);

  return (
    <Page title="teamMember">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <CheckCard />
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" paddingTop={2}>
          <Typography variant="h4" gutterBottom>
            Utilisateurs
          </Typography>
        </Stack>
        <Stack paddingBottom={2} direction='row' spacing={2} justifyContent={'end'}>
          {hasPermission(permissions, "add_users") && (
            <CustomButton type='primaire' buttonText='Inviter des utilisateurs' onClick={handleOpenRecetteModal} />
          )}

          <Modal
            open={openRecetteModal}
            onClose={handleCloseRecetteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: '7px' }}
          >
            <Box sx={styleModal}>
              <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='subtitle1'>
                  {
                    selectedIndex ? 'Mise à jour des role' : 'Inviter des utilisateurs'
                  }
                </Typography>
              </Box>
              <Divider />
              <Stack spacing={3} padding={3} justifyContent='center' alignItems='center'>
                <Typography textAlign={'center'} variant='caption'>
                  Saisissez les adresses e-mail des utilisateurs que vous aimeriez ajouter, puis choisissez le role qu'ils doivent avoir au sein de l'organisation
                </Typography>
                <TextField value={email} onChange={(e) => setEmail(e.target.value)} fullWidth placeholder='Email' />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRoleId}
                    label="Role"
                    onChange={handleChangeRole}
                  >
                    {roles.map((role, index) => (
                      <MenuItem key={role.id} value={role.id}>{role?.data().name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Divider />
              <Stack direction='row' spacing={2} padding={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                <CustomButton type='secondaire' buttonText='Annuler' loading={loading} onClick={handleCloseRecetteModal} />
                <CustomButton type='primaire' buttonText='Ajouter' loading={loading} onClick={selectedIndex ? handleUpdateRole : handleInvite} />
              </Stack>
            </Box>
          </Modal>
        </Stack>
        <Box>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="caption table">
                <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {loading ? (
                  <caption>
                    <Skeleton variant="rounded" width="100%" />
                  </caption>
                ) : team.length === 0 && !loading ? (
                  <caption>
                    <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                      <Typography variant="body">Aucune donnée</Typography>
                    </Stack>
                  </caption>
                ) : (
                  <TableBody>
                    {team.map((teamMember, index) => (
                      <TableRow key={index} hover>
                        <TableCell
                          id={teamMember.id}
                          key={teamMember.id}

                        >
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <Avatar sx={{ width: 30, height: 30 }} />
                            <Stack>
                              <Typography variant='caption'><strong>{teamMember.email}</strong></Typography>
                              <Typography variant='caption'>{teamMember.status === 0 ? 'Invitation en cours' : teamMember.status === 1 ? 'Invitation envoyé' : teamMember.email}</Typography>
                              <Typography variant='caption'>Invité par  {teamMember.invitedByInfo.nom}  {teamMember.invitedByInfo.prenom}</Typography>
                            </Stack>
                          </Stack>

                        </TableCell>
                        <TableCell id={teamMember.nom} onClick={() => { navigate(`${teamMember.id}`); }}>
                          <Typography variant='caption'>{teamMember.roleName}</Typography>
                        </TableCell>

                        <TableCell align='right' id={teamMember.nom} onClick={(e) => { }}>
                          <Box>
                            <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined}>
                              <Iconify icon="fluent:more-vertical-48-filled" />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >
                              {hasPermission(permissions, "remove_users") && (
                                <MenuItem
                                  onClick={(e) => {
                                    // navigate(affiliations[selectedIndex]?.id)
                                    handleCloseMenu();
                                    handleDeleted()
                                  }}
                                  disableRipple
                                  value={1}
                                >
                                  <Typography variant='body2'>Supprimer</Typography>
                                </MenuItem>
                              )}

                              {hasPermission(permissions, "update_users") && (
                                <>
                                  <MenuItem
                                    onClick={(e) => {
                                      // handleCloseMenu();
                                      // handleOpenSup()
                                    }}
                                    disableRipple
                                    value={2}
                                  >
                                    <Typography variant='body2'>Copier le lien de l'invitation</Typography>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) => {
                                      handleCloseMenu();
                                      updateTeamMember()
                                    }}
                                    disableRipple
                                    value={3}
                                  >
                                    <Typography variant='body2'>Mettre à jour les role</Typography>
                                  </MenuItem>
                                </>

                              )}



                            </StyledMenu>

                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}

export default withAuthorization(Utilisateurs, "manage_users")
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable spaced-comment */
/* eslint-disable no-unreachable-loop */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
import 'dayjs/locale/fr';
// @mui
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  Avatar,
  Divider,
} from '@mui/material';

// components
import { addDoc, collection, doc, getDoc, getDocs, Timestamp, updateDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { isNaN } from 'lodash';
import { db } from '../../firebase.config';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CustomSeanceDateTimePicker from '../../components/customSeanceDateTimePicker';
import CustomButton from '../../components/CustomButton';
import { useSnackbar } from '../../context/snackbarContext';

// sections

// ----------------------------------------------------------------------

export default function AddDate() {
  const navigate = useNavigate();
  const { eventID } = useParams();
  const showSnackbar = useSnackbar();
  // const 
  const [tickets, setTickets] = useState([]);
  const [seances, setSeances] = useState([{ id: Date.now(), date: null, debutTime: '', endTime: '', subtitle: '', tickets: [] }]);
  const weekDays = ['lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.', 'dim.'];
  const [jourMensuel, setJourMensuel] = useState(0);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [recurrenceDays, setRecurrenceDays] = useState([]);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, `evenement/${eventID}/tickets`));
        const ticketsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTickets(ticketsData);
      } catch (error) {
        console.error("Erreur de récupération des billets :", error);
      }
      setLoading(false);
    };
    fetchTickets();
  }, [eventID]); 

  

  const addSeance = () => {
    setSeances([...seances, { id: Date.now(), date: null, debutTime: '', endTime: '', subtitle: '', tickets: [] }]);
  };

  const removeSeance = (id) => {
    setSeances(seances.filter(seance => seance.id !== id));
  };

  const handleSeanceChange = (id, field, value) => {
    setSeances(seances.map(seance => seance.id === id ? { ...seance, [field]: value } : seance));
  };

  const toggleRecurrenceDay = (day) => {
    setRecurrenceDays(prevDays =>
      prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
    );
  };

  const [recurrenceDay, setRecurrenceDay] = useState(null);


  const detectRecurrenceType = (selectedDate) => {
    try {
      const date = new Date(selectedDate);
      if (isNaN(date.getTime())) {
        return null
      }
      const dayOfMonth = date.getDate();
      const dayOfWeek = date.toLocaleDateString("fr-FR", { weekday: "long" }).toLowerCase(); // Ex: "monday"
      const month = date.getMonth();
      const year = date.getFullYear();

      // Trouver la semaine du mois (1er, 2ème, 3ème, 4ème, ou dernier)
      let week = 1;
      const tempDate = new Date(year, month, 1); // Premier jour du mois
      console.log(tempDate)
      while (tempDate.getDate() !== dayOfMonth) {
        if (tempDate.getDay() === date.getDay()) {
          week++;
        }
        tempDate.setDate(tempDate.getDate() + 1);
      }
      // Sinon, c'est un jour spécifique (ex: 2ème lundi)
      return { recurrenceType: "weekday", recurrenceDay: { week, day: dayOfWeek, dayOfMonth } };
    } catch (error) {
      console.log('erreur')
    }

  };

  const [recurence, setRecurence] = React.useState();
  const handleChange = (event) => {
    setRecurence(event.target.value);
    if (event.target.value === 1 && seances[0].date) {
      const { recurrenceDay } = detectRecurrenceType(formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut);
      setRecurrenceDay(recurrenceDay);
      setJourMensuel(0)
    }

  };

  const formatDateWithTime = (date, timeDebut, timeFin) => {
    const tmpDateDebut = new Date(date);
    console.log(tmpDateDebut)
    const [startHours, startMinutes] = timeDebut.split(':').map(Number);
    tmpDateDebut.setHours(startHours, startMinutes, 0);

    const tmpDateFin = new Date(date);
    const [endHours, endMinutes] = timeFin.split(':').map(Number);
    tmpDateFin.setHours(endHours, endMinutes, 0);

    // Si la date de fin est inférieure à la date de début, ajoutez un jour
    if (tmpDateFin < tmpDateDebut) {
      tmpDateFin.setDate(tmpDateFin.getDate() + 1);
    }

    return {
      dateDebut: tmpDateDebut,
      dateFin: tmpDateFin,
    };
  };


  /* const genererSeances = () => {
    const tmpSeances = [];
    const currentDate = formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut;
    const endDate = new Date(recurrenceEndDate);
    while (currentDate <= endDate) {
      console.log(currentDate)
      // const jourSemaine = currentDate.toLocaleDateString("fr-FR", { weekday: "short" }).toLowerCase();
      const jourSemaine = format(currentDate, 'eee', {
        locale: fr,
      }).toString()


      if (
        (recurence === 2) ||
        (recurence === 0 && recurrenceDays.includes(jourSemaine)) ||
        (recurence === 1 && jourMensuel === currentDate.getDate())
      ) {
        console.log("ok")
        console.log(seances[0].endTime)
        const tmpDateFin = new Date(currentDate);
        const [endHours, endMinutes] = seances[0].endTime.split(':').map(Number);
        tmpDateFin.setHours(endHours, endMinutes, 0);
        if (tmpDateFin < currentDate) {
          tmpDateFin.setDate(tmpDateFin.getDate() + 1);
        }
        tmpSeances.push({
          start: currentDate.toJSON(),
          fin: tmpDateFin.toJSON(),
          // heureDepart,
          // heureFin
        });
      }
      console.log('-------------')
      console.log(new Date(currentDate))
      // Incrémentation en fonction du type de récurrence
      if (recurence === 2) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      }  else if (recurence === 1) {
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    }

    return tmpSeances;
  }; */

  const genererSeances = () => {
    const tmpSeances = [];
    const currentDate = formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut;
    const endDate = new Date(recurrenceEndDate);
    console.log(endDate)
    console.log(recurrenceEndDate)

    while (currentDate <= endDate) {
      console.log("Date en cours :", currentDate);

      let shouldAdd = false;
      if (recurence === 2) {
        shouldAdd = true;
      }
      else if (recurence === 0) { // Quotidien ou hebdomadaire
        console.log("/////////////////1")
        console.log(recurrenceDays)
        const jourSemaine = format(currentDate, 'eee', {
          locale: fr,
        }).toString()
        console.log(jourSemaine)
        console.log("/////////////////2")
        if (recurrenceDays.includes(jourSemaine)) {
          shouldAdd = true;
          console.log("/////////////////3")
        }
      } else if (recurence === 1) { // Mensuel
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const dayOfMonth = currentDate.getDate();

        if (jourMensuel === 0) {
          // 📌 Cas 1 : Un jour précis du mois (ex: le 10, 15, 20…)
          if (recurrenceDay?.dayOfMonth === dayOfMonth) {
            shouldAdd = true;
          }
        } else if (jourMensuel === 1) {
          // 📌 Cas 2 : Un jour spécifique de la semaine (ex: deuxième lundi)
          const { week, day } = recurrenceDay; // ex: { week: 2, day: "monday" }
          const specificWeekday = getNthWeekdayOfMonth(year, month, week, day);
          currentDate.setDate(specificWeekday + 1)
          shouldAdd = true;

        }
      }

      if (shouldAdd) {
        console.log("Séance ajoutée :", currentDate);

        const tmpDateFin = new Date(currentDate);
        const [endHours, endMinutes] = seances[0].endTime.split(':').map(Number);
        tmpDateFin.setHours(endHours, endMinutes, 0);

        if (tmpDateFin < currentDate) {
          tmpDateFin.setDate(tmpDateFin.getDate() + 1);
        }

        tmpSeances.push({
          start: currentDate.toJSON(),
          fin: tmpDateFin.toJSON(),
        });
      }

      // Incrémentation de la date
      /* if (recurence === 2 || recurence === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 1) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (jourMensuel === 1) {
          currentDate.setDate(1); // Revenir au premier jour du mois avant de vérifier les conditions
        }

      } */

      if (recurence === 2) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (recurence === 1) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (jourMensuel === 1) {
          currentDate.setDate(1); // Revenir au premier jour du mois avant de vérifier les conditions
        }
      }
    }

    return tmpSeances;
  };

  /**
   * 📌 Fonction pour récupérer le deuxième lundi d'un mois donné
   */


  const getNthWeekdayOfMonth = (year, month, week, day) => {
    const weekdays = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
    const dayIndex = weekdays.indexOf(day.toLowerCase());

    const date = new Date(year, month, 1);
    let count = 0;

    while (date.getMonth() === month) {
      if (date.getDay() === dayIndex) {
        count++;
        if (count === week) {
          return date.getDate();
        }
      }
      date.setDate(date.getDate() + 1);
    }

    return null;
  };


  /* const handleSubmit = async () => {
    setLoading(true);
    try {

      if (isRecurrent) {
        const tmpseances = genererSeances();
        console.log('tmpseances')
        console.log(tmpseances)
        for (const seance of tmpseances) {
          await addDoc(collection(db, `evenement/${eventID}/seances`), {
            date: Timestamp.fromDate(new Date(seance.start)),
            fin: Timestamp.fromDate(new Date(seance.fin)),
            status: 1,
            participants: 0,
            subtitle: '',
          });
        }

      } else {
        for (const seance of seances) {
          console.log(seance)
          const tmpDate = formatDateWithTime(seance.date, seance.debutTime, seance.endTime);
          await addDoc(collection(db, `evenement/${eventID}/seances`), {
            date: Timestamp.fromDate(tmpDate.dateDebut),
            fin: Timestamp.fromDate(tmpDate.dateFin),
            status: 1,
            participants: 0,
            subtitle: '',
          });
        }
        
      }
      navigate(-1);
    } catch (error) {
      console.error("Erreur d'enregistrement :", error);
    }
    setLoading(false);
  }; */

  /* const handleSubmit = async () => {
     setLoading(true);
     try {
         const eventRef = doc(db, `evenement/${eventID}`);
         const eventSnap = await getDoc(eventRef);
 
         if (!eventSnap.exists()) {
             console.error("L'événement n'existe pas.");
             return;
         }
 
         const eventData = eventSnap.data();
         let minDate = eventData.dateDebut ? eventData.dateDebut.toDate() : null;
         let maxDate = eventData.dateFin ? eventData.dateFin.toDate() : null;
 
         if (isRecurrent) {
             const tmpseances = genererSeances();
             console.log('tmpseances', tmpseances);
 
             for (const seance of tmpseances) {
                 const startDate = new Date(seance.start);
                 const endDate = new Date(seance.fin);
 
                 await addDoc(collection(db, `evenement/${eventID}/seances`), {
                     date: Timestamp.fromDate(startDate),
                     fin: Timestamp.fromDate(endDate),
                     status: 1,
                     participants: 0,
                     subtitle: '',
                 });
 
                 // Mise à jour des min et max dates
                 if (!minDate || startDate < minDate) minDate = startDate;
                 if (!maxDate || endDate > maxDate) maxDate = endDate;
             }
         } else {
             for (const seance of seances) {
                 console.log(seance);
                 const tmpDate = formatDateWithTime(seance.date, seance.debutTime, seance.endTime);
                 const startDate = tmpDate.dateDebut;
                 const endDate = tmpDate.dateFin;
 
                 await addDoc(collection(db, `evenement/${eventID}/seances`), {
                     date: Timestamp.fromDate(startDate),
                     fin: Timestamp.fromDate(endDate),
                     status: 1,
                     participants: 0,
                     subtitle: '',
                 });
 
                 // Mise à jour des min et max dates
                 if (!minDate || startDate < minDate) minDate = startDate;
                 if (!maxDate || endDate > maxDate) maxDate = endDate;
             }
         }
 
         // Mise à jour de l'événement si nécessaire
         if (minDate && maxDate) {
             await updateDoc(eventRef, {
                 dateDebut: Timestamp.fromDate(minDate),
                 dateFin: Timestamp.fromDate(maxDate),
             });
             console.log("Dates de l'événement mises à jour :", minDate, maxDate);
         }
 
         navigate(-1);
     } catch (error) {
         console.error("Erreur d'enregistrement :", error);
     }
     setLoading(false);
 }; */

  const handleSubmit = async () => {
    setLoading(true);
    try {

      // verification des informations

      // fin des verifications

      const eventRef = doc(db, `evenement/${eventID}`);
      const eventSnap = await getDoc(eventRef);

      if (!eventSnap.exists()) {
        console.error("L'événement n'existe pas.");
        showSnackbar("L'événement n'existe pas.", 'error')
        setLoading(false);
        return;
      }

      const eventData = eventSnap.data();
      let minDate = eventData.dateDebut ? eventData.dateDebut.toDate() : null;
      let maxDate = eventData.dateFin ? eventData.dateFin.toDate() : null;

      const seancesToAdd = isRecurrent ? genererSeances() : seances.map(seance => {
        const tmpDate = formatDateWithTime(seance.date, seance.debutTime, seance.endTime);
        return {
          date: tmpDate.dateDebut,
          fin: tmpDate.dateFin,
          subtitle: seance.subtitle ? seance.subtitle : ''
        };
      });

      // 🔹 Vérification des informations avant soumission

      if (!seancesToAdd || seancesToAdd.length === 0) {
        console.error("Erreur : Aucune séance à ajouter.");
        showSnackbar('Aucune séance à ajouter.')
        setLoading(false);
        return;
      }

      console.log("Séances à ajouter :", seancesToAdd);

      // Créer toutes les séances en parallèle
      await Promise.all(seancesToAdd.map(async (seance) => {
        const startDate = new Date(seance.start || seance.date);
        const endDate = new Date(seance.fin);
        
        // Ajout de la séance
        await addDoc(collection(db, `evenement/${eventID}/seances`), {
          date: Timestamp.fromDate(startDate),
          fin: Timestamp.fromDate(endDate),
          status: 1,
          participants: 0,
          name: !isRecurrent && seance.subtitle ? seance.subtitle : '',
        });

        // Mise à jour des dates min et max
        if (!minDate || startDate < minDate) minDate = startDate;
        if (!maxDate || endDate > maxDate) maxDate = endDate;
      }));

      // Mettre à jour l'événement uniquement si nécessaire
      if (minDate && maxDate) {
        await updateDoc(eventRef, {
          dateDebut: Timestamp.fromDate(minDate),
          dateFin: Timestamp.fromDate(maxDate),
        });
        console.log("Dates de l'événement mises à jour :", minDate, maxDate);
      }
      showSnackbar('Seances ajoutée avec success.')
      navigate(-1);
    } catch (error) {
      showSnackbar("Erreur d'enregistrement.", true)
      console.error("Erreur d'enregistrement :", error);
    }
    setLoading(false);
  };

  // mis a jour de la variable jour du mois 
  useEffect(() => {
    try {
      if (recurence === 1) {
        const { recurrenceDay } = detectRecurrenceType(formatDateWithTime(seances[0].date, seances[0].debutTime, seances[0].endTime)?.dateDebut);
        setRecurrenceDay(recurrenceDay);
      }
    } catch {
      //
    }
    
  }, [seances]);


  return (
    <Page title="Ajout de Séance">
      <Container>

        <Typography variant="h4" sx={{ marginBottom: 2 }}>Ajoutez des séances</Typography>

        <Stack paddingTop={3} spacing={3} marginBottom={10}>
          <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }}>
            <Box sx={{ p: 3 }} dir="ltr">
              <Typography variant="subtitle1">Type de seance</Typography>
              <Typography variant="body2">
                Souhaitez vous créer de une seance repetes ou plusieurs seances distinctes ?
              </Typography>
              <Box paddingTop={3} dir="ltr">

                <FormControl fullWidth>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={isRecurrent}
                    onChange={(e) => setIsRecurrent(e.target.value)}
                  >
                    <MenuItem value={false}>Plusieurs seances</MenuItem>
                    <MenuItem value>Seances repétés</MenuItem>
                  </Select>
                </FormControl>

              </Box>
            </Box>
          </Box>
          <Stack spacing={1} sx={{ border: '2px solid #ddd', borderRadius: '7px', marginBottom: 10 }}>
            <Box sx={{ p: 3 }} dir="ltr">
              <Typography variant="subtitle1">Informations sur vos seances</Typography>
              <Typography variant="body2">
                Entrer les dates de debut et de fin vos seances
              </Typography>
            </Box>
            <Stack padding={3}>
              {seances.map(seance => (
                <Stack spacing={2} key={seance.id} sx={{ border: '1px solid #ddd', borderRadius: '10px', padding: 2, marginY: 2, maxWidth: 700 }}>
                  {!isRecurrent && seances.length > 1 &&
                    <Stack direction="row" justifyContent="end" >
                      <IconButton onClick={() => removeSeance(seance.id)}>
                        <Iconify icon="typcn:delete" sx={{ color: 'red' }} />
                      </IconButton>
                    </Stack>}
                  {
                    !isRecurrent && <TextField value={seance.subtitle} onChange={(e) => handleSeanceChange(seance.id, 'subtitle', e.target.value)} />
                  }
                  <Box sx={{}}>
                    <CustomSeanceDateTimePicker
                      date={seance.date}
                      debutTime={seance.debutTime}
                      endTime={seance.endTime}
                      onDateChange={value => handleSeanceChange(seance.id, 'date', value)}
                      onDebutTimeChange={value => handleSeanceChange(seance.id, 'debutTime', value)}
                      onEndTimeChange={value => handleSeanceChange(seance.id, 'endTime', value)}
                    />
                  </Box>
                  {isRecurrent && (
                    <Stack spacing={2} marginTop={2} maxWidth={700} >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">A lieu</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={recurence}
                          label="A lieu"
                          onChange={handleChange}
                        >
                          <MenuItem value={2}>Quotien</MenuItem>
                          <MenuItem value={0}>Hebdomadaire</MenuItem>
                          <MenuItem value={1}>Mensuel</MenuItem>
                        </Select>
                      </FormControl>
                      {
                        recurence === 0 ?
                          <>
                            <Typography>le</Typography>

                            <Stack spacing={1} direction={'row'}>
                              {weekDays.map(day => (
                                <Avatar
                                  sx={recurrenceDays.includes(day) ? { width: 30, height: 30, cursor: 'pointer', bgcolor: '#5E17EB', color: 'white', border: '1px solid #f2f2f2' } : { width: 30, height: 30, cursor: 'pointer', bgcolor: 'white', color: 'black', border: '1px solid #f2f2f2' }}
                                  key={day}
                                  variant={recurrenceDays.includes(day) ? 'contained' : 'outlined'}
                                  onClick={() => toggleRecurrenceDay(day)}
                                >
                                  <Typography variant='caption'>{day}</Typography>
                                </Avatar>
                              ))}
                            </Stack>
                          </>
                          : recurence === 1 ?

                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Jour du mois</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={jourMensuel}
                                label="Jour du mois"
                                onChange={(e) => setJourMensuel(e.target.value)}
                              >
                                <MenuItem value={0}>le {recurrenceDay?.dayOfMonth}</MenuItem>
                                <MenuItem value={1}>le {recurrenceDay?.week}{recurrenceDay?.week === 1 ? 'er' : 'e'} {recurrenceDay?.day}</MenuItem>
                              </Select>
                            </FormControl> : null
                      }


                      <TextField
                        label="Fin"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={recurrenceEndDate}
                        onChange={(e) => setRecurrenceEndDate(e.target.value)}
                        fullWidth
                      />

                    </Stack>
                  )}
                </Stack>

              ))}
            </Stack>

            {
              !isRecurrent && <CustomButton type={'tertiaire'} buttonText={'Ajouter une séance'} onClick={addSeance} />
            }

          </Stack>
          {/** seance */}
        </Stack>


        <Container>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'white',
              padding: '16px',
              // borderTop: '2px solid gainsboro',
              boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            <CustomButton
              type='secondaire'
              buttonText='Annuler'
              loading={loading}
              onClick={() => window.history.back()}
            />
            <CustomButton
              loading={loading}
              type='primaire'
              buttonText='Créer'
              onClick={handleSubmit}
            />
          </Stack>
        </Container>

      </Container>
    </Page>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { filter } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Paper,
    TableHead,
    Box,
    LinearProgress,
    IconButton,
    Backdrop,
    CircularProgress,
    Snackbar,
    Skeleton,
    MenuItem,
    alpha,
    styled,
    Menu,
    Modal,
} from '@mui/material';
// components
import { collection, getDocs, query } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { db } from '../../../firebase.config';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import CustomButton from '../../../components/CustomButton';
import CustomTag from '../../../components/customTag';


// ----------------------------------------------------------------------
// ----------- for more bouton modal
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: 5,
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 15,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '7px',
    boxShadow: 0,
    p: 3,
};

export default function Affiliate() {
    const [page, setPage] = useState(0);
    const { eventID } = useParams();
    const navigate = useNavigate()
    // loading
    const [loading, setLoading] = React.useState(false);


    // for change value of globla quota
    const [modif, setModif] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [refreh, setRefresh] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setModif(false);
        setRefresh((prev) => !prev);
    };

    //  snack bar
    const [openSnack, setOpenSnack] = React.useState(false);
    const [msg, setMsg] = React.useState({ content: '', color: '' });
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    // setup des codes promo
    const [affiliations, setAffiliations] = useState([]);
    const [curent, setCurent] = useState();
    React.useEffect(() => {
        (async () => {
            setAffiliations([]);
            try {
                setLoading(true);
                const q = query(collection(db, `evenement/${eventID}/affiliations`));
                const snapshot = await getDocs(q);
                snapshot.forEach((d) => {
                    setAffiliations((affiliations) => [...affiliations, d]);
                    // console.log(d.id, ' => ', d.data());
                });
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [refreh]);

    const handleToggle = () => {
        setOpen(!open);
    };
    const handleVisible = async (index) => {
        //
    };
    const handleModif = async (index) => {
        setLoading(true);
        setCurent(index);
        try {
            //
        } catch (error) {
            console.log(error);
        }
        setModif(true);
        setCurent(index);
        setLoading(false);
    };

    // Modal deleted
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const handleOpenMenu = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        setOpenMenu(false);
    };


    const activeAffilation = async () => {
        setLoading(true)
        console.log(affiliations[selectedIndex].id)
        // const id = affiliations[e]
        try {
            const response = await fetch(`https://us-central1-event-86cbf.cloudfunctions.net/activeAffiliate?eventId=M9W1Y6W5O6&aff=${affiliations[selectedIndex].id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log(response)
        } catch (error) {
            console.error(error)
        }
        finally {
            setRefresh(!refreh)
            setLoading(false)
        }
    };
    const deleteAffilation = async (e) => {
        setLoading(true)
        console.log(affiliations[e].id)
        // const id = affiliations[e]
        
        try {
            const response = await fetch(`https://us-central1-event-86cbf.cloudfunctions.net/deletedAffiliate?eventId=M9W1Y6W5O6&aff=${affiliations[e].id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log(response)
        } catch (error) {
            console.error(error)
        }
        finally {
            setRefresh(!refreh)
            setLoading(false)
        }

    };
    const suspendAffilation = async () => {
       //  const response = await fetch(`https://us-central1-event-86cbf.cloudfunctions.net/suspendAffiliate?eventId=M9W1Y6W5O6&aff=${affiliations[e].id}`)
       setLoading(true)
        try {
            const response = await fetch(`https://us-central1-event-86cbf.cloudfunctions.net/suspendAffiliate?eventId=M9W1Y6W5O6&aff=${affiliations[selectedIndex].id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log(response)
        } catch (error) {
            console.error(error)
        }
        finally {
            setRefresh(!refreh)
            setLoading(false)
        }
    };
    const handleTimestamp = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        return tmp[0];
      };

    return (
        <Page title="Affilation">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {loading && <LinearProgress />}


            <Container sx={{ marginTop: { xs: 3 } }}>
                {/** popup de suppression du billet */}
                <Modal
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ borderRadius: '7px' }}
                >
                    <Box sx={styleModal}>
                        <Stack spacing={4}>
                            {
                                affiliations[selectedIndex]?.data()?.sell > 0 ?
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="subtitle1">Impossible de supprimer le billet</Typography>
                                        </Box>
                                        <Box sx={{ marginTop: 5 }} dir="ltr">
                                            <Typography align='center' variant='body2'>Vous ne pouvez pas supprimer ce billet car il a déjà été commandé par des participants.</Typography>
                                        </Box>
                                        <Stack direction='row' spacing={2} justifyContent='center'>
                                            <CustomButton type='secondaire' buttonText='Ok' onClick={handleCloseDeleteModal} />
                                        </Stack>
                                    </>
                                    :
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="subtitle1">Supprimer le billet</Typography>
                                        </Box>
                                        <Box sx={{ marginTop: 5 }} dir="ltr">
                                            <Typography align='center' variant='body2'>Voulez-vous vraiment supprimer le billet <strong>{affiliations[selectedIndex]?.data()?.ticket_name}</strong> ? Cette action ne peut pas être annulée.</Typography>
                                        </Box>
                                        <Stack direction='row' spacing={2} justifyContent='center'>
                                            <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseDeleteModal} />
                                            <CustomButton type='primaire' buttonText='Supprimer' onClick={() => deleteAffilation(selectedIndex)} />
                                        </Stack>
                                    </>
                            }


                        </Stack>

                    </Box>
                </Modal>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'start', sm: 'center' }}
                    justifyContent="space-between"
                    mb={5}
                    spacing={2}
                >
                    <Typography variant="h3" gutterBottom>
                        Affiliation <br />
                        <Typography variant="body2">
                            Gérez vos partenaires faisant la promotion de vos évènements et générant des ventes
                        </Typography>
                    </Typography>
                </Stack>
                <Box padding={3} sx={{ bgcolor: '#F5EFFA', borderRadius: '7px' }}>
                    <Typography variant='h4'>✨ Boostez vos ventes grace aux influenceurs et partenaire locaux !</Typography>
                    <Typography variant='body2'>Offrez-leur les outils dont ils ont besoin (portail dédié, liens d'affiliation personnalisés, suivi des performances en temps réel) et suivez leurs clics, ventes et revenus.
                    </Typography>
                </Box>

                <Box paddingY={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <CustomButton type='primaire' buttonText='Créer une nouvelle affiliation' onClick={() => { navigate('ajout') }} />
                </Box>
                <Box>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                <caption>
                                    {' '}
                                    {affiliations.length === 0
                                        ? "Vous pouvez créer des codes de promotion pour votre événement. Utilisez-les pour offrir une remise sur le prix de vos billets ou dans le cadre de vos opérations marketing ! Vous n'avez pas encore créé de code de promotion. Créez-en un !"
                                        : null}{' '}
                                </caption>
                                <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                                    <TableRow>
                                        <TableCell align="left">Affiliation</TableCell>
                                        <TableCell align="right">Nombre de clicks</TableCell>
                                        <TableCell align="right">Billets vendu</TableCell>
                                        <TableCell align="right">Revenue</TableCell>
                                        <TableCell align="right">Statut</TableCell>
                                        <TableCell align="right" />
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : affiliations.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="caption">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {affiliations.map((row, index) => (
                                            <TableRow key={row.data().name}>
                                                <TableCell align="left" component="th" scope="row">
                                                    <Stack>
                                                        <Typography variant='subtitle1'>{row.data()?.name}</Typography>
                                                        <Typography variant='body1'>{row.data()?.email}</Typography>
                                                        <Typography variant='body2'>Commission de {row.data().commission?.value} {row.data().commission?.type === 0 ? 'CFA' : '%'}</Typography>
                                                        {
                                                            row.data()?.startAt ? 
                                                            <Typography variant='caption'>Affiliation effective le {format(new Date(handleTimestamp(row.data()?.startAt)), 'EEEE dd, MMMM yyyy', { locale: fr })} à { format(new Date(handleTimestamp(row.data()?.startAt)), 'HH:mm', { locale: fr }) }</Typography>
                                                            : null
                                                        }
                                                        
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="right"><Typography variant='body1'>{row.data().clicks}</Typography></TableCell>
                                                <TableCell align="right">
                                                    <Typography variant='body1'>{row.data().sell}</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant='body1'>{row.data().revenueGenerated}</Typography>
                                                </TableCell>
                                                <TableCell align="right" >
                                                    <Box>
                                                        {
                                                            row.data().status === 0 ?
                                                                <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <CustomTag type={'warning'} text={'Validation encours'} />
                                                                </Box>
                                                                : row.data().status === 1 ?
                                                                    <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <CustomTag type={'success'} text={'Validé et actif'} />
                                                                    </Box>
                                                                    : row.data().status === 2 ?
                                                                        <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                            <CustomTag type={'error'} text={'refusé'} />
                                                                        </Box> :
                                                                        row.data().status === 3 ?
                                                                            <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <CustomTag type={'error'} text={'inactif'} />
                                                                            </Box> :
                                                                            row.data().status === 4 ?
                                                                                <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                    <CustomTag type={'error'} text={'expiré'} />
                                                                                </Box> : null
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="right" >
                                                    <Box>
                                                        <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                                                            aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={openMenu ? 'true' : undefined}>
                                                            <Iconify icon="fluent:more-vertical-48-filled" />
                                                        </IconButton>
                                                        <StyledMenu
                                                            id="demo-customized-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'demo-customized-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={openMenu}
                                                            onClose={handleCloseMenu}
                                                        >
                                                            <MenuItem
                                                                onClick={(e) => {
                                                                    navigate(affiliations[selectedIndex]?.id)
                                                                    // handleCloseMenu();
                                                                    // goToSite()
                                                                }}
                                                                disableRipple
                                                                value={1}
                                                            >
                                                                <Typography variant='body2'>Modifier</Typography>
                                                            </MenuItem>


                                                            <MenuItem
                                                                onClick={(e) => {
                                                                    handleCloseMenu();
                                                                    if (affiliations[selectedIndex]?.data().status === 1) {
                                                                        suspendAffilation()
                                                                    } else {
                                                                        activeAffilation()
                                                                    }
                                                                }}
                                                                disableRipple
                                                                value={2}
                                                            >

                                                                <Typography variant='body2'>{affiliations[selectedIndex]?.data().status === 1 ? 'Suspendre' : 'Activer'}</Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={(e) => {
                                                                    // handleCloseMenu();
                                                                    // handleOpenSup()
                                                                }}
                                                                disableRipple
                                                                value={4}
                                                            >
                                                                <Typography variant='body2'>Copier le lien</Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={(e) => {
                                                                    handleCloseMenu();
                                                                    handleOpenDeleteModal()
                                                                }}
                                                                disableRipple
                                                                value={4}
                                                            >
                                                                <Typography variant='body2'>Supprimer</Typography>
                                                            </MenuItem>

                                                        </StyledMenu>
                                                        
                                                    </Box>
                                                </TableCell>
                                                
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Box>
            </Container>
            <Snackbar
                open={openSnack}
                autoHideDuration={5000}
                onClose={handleCloseSnack}
                message={
                    <Box>
                        {msg.content}{' '}
                        <Iconify
                            sx={{ color: msg.color }}
                            icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
                        />
                    </Box>
                }
            />
        </Page>
    );
}

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// material
import {
    Stack,
    Typography,
    Box,
    Avatar,
    Grid,
    Card,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';
import Iconify from '../../../../../components/Iconify';
import { db } from '../../../../../firebase.config';
import CustomButton from '../../../../../components/CustomButton';
import { UserAuth } from '../../../../../context/AuthContext';
import { useSnackbar } from '../../../../../context/snackbarContext';
import CustomTag from '../../../../../components/customTag';


export default function FacebookLinkAccount() {

    const [searchParams] = useSearchParams();
    const [accessToken, setAccessToken] = useState();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [authDetail, setAuthDetail] = useState([]);
    const [fbData, setFbData] = useState([]);
    const [pages, setPages] = useState([]);
    const [adAccounts, setAdAccounts] = useState([]);
    const [defaultPage, setDefaultPage] = useState();
    const [defaultAdAccount, setDefaultAdAccount] = useState();
    const [fbUser, setFbUser] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const showSnackbar = useSnackbar()
    const { userData } = UserAuth();
    useEffect(() => {
        // Vérifie si un token est déjà stocké
        console.log('////////////////')
        const token = Cookies.get("facebook_access_token")
        console.log(token)
        if (token) {
            setAccessToken(token);

        } else {
            const token = searchParams.get("access_token");
            console.log(token)
            if (token) {
                Cookies.set(`facebook_access_token`, JSON.stringify(token), { expires: 7 });
                setAccessToken(token);
            }
        }
    }, [searchParams]);


    const handleConnect = async () => {
        const appId = '9736171633120398';
        const redirectUri = 'https://us-central1-event-86cbf.cloudfunctions.net/linkFacebookCallBack';
        const scope = 'ads_management,ads_read,business_management,pages_read_engagement';
        const authUrl = `https://www.facebook.com/v22.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
        window.location.href = authUrl;
    };

    const handleDefaultPage = async (index) => {
        try {
            await updateDoc(doc(db, `organisations/${userData.idOrg}/facebookAuthData/${fbUser.id}`), { defaultPage: index });
            showSnackbar("Page facebook défini par défaut !");
            setRefresh(!refresh)
        } catch (error) {
            console.log(error)
        }
    };

    const handleDefaultAdAccount = async (index) => {
        try {
            await updateDoc(doc(db, `organisations/${userData.idOrg}/facebookAuthData/${fbUser.id}`), { defaultAdAccount: index });
            showSnackbar("Compte publicitaire défini par défaut !");
            setRefresh(!refresh)
        } catch (error) {
            console.log(error)
        }
    };

    const handleDeconnect = async (index) => {
        try {
            await deleteDoc(doc(db, `organisations/${userData.idOrg}/facebookAuthData/${fbUser.id}`));
            Cookies.remove(`facebook_access_token`);
            showSnackbar("Compte facebook dissocié avec sucess !", false);
            setRefresh(!refresh)
        } catch (error) {
            showSnackbar("Une erreur est survenue !", true);
            console.log(error)
        }
    };

    const saveAccessToken = async (userId, accessToken) => {
        await setDoc(doc(db, `organisateurs/${userId}`), { accessToken }, { merge: true });
    };


    React.useEffect(() => {
        const fetchFacebookData = async () => {
            try {
                setLoading(true);
                setFbUser(null);
                setPages([]);
                setAdAccounts([]);

                const q = collection(db, `organisations/${userData.idOrg}/facebookAuthData`);
                const snapshot = await getDocs(q);

                if (!snapshot.empty) {
                    const fbData = snapshot.docs[0].data();
                    setFbUser(fbData.userData);
                    setPages(fbData.pages || []);
                    setAdAccounts(fbData.adAccounts || []);
                    setDefaultPage(fbData.defaultPage);
                    setDefaultAdAccount(fbData.defaultAdAccount);
                    console.log("[Facebook Auth] Données récupérées depuis Firestore.");
                } else {
                    const cookieToken = Cookies.get('facebook_access_token');
                    if (!cookieToken) {
                        console.warn("[Facebook Auth] Aucun token trouvé dans les cookies.");
                        return;
                    }

                    const accessToken = JSON.parse(cookieToken);
                    console.log("[Facebook Auth] Token trouvé, envoi vers Cloud Function...");

                    const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/storeFacebookAuthData', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            access_token: accessToken,
                            orgId: userData.idOrg,
                        }),
                    });
                    console.log("[Facebook Auth] cloud function terminé...");
                    let result;
                    try {
                        result = await response.json();
                    } catch (err) {
                        console.error("[Facebook Auth] Erreur parsing JSON :", err);
                        showSnackbar("Erreur de réponse du serveur Facebook.", true);
                        return;
                    }

                    if (response.ok) {
                        showSnackbar("Connexion Facebook enregistrée avec succès.");
                        setRefresh(!refresh)
                    } else {
                        showSnackbar(result.error || "Une erreur est survenue lors de l'enregistrement Facebook.", true);
                    }
                }
            } catch (err) {
                console.error("[Facebook Auth] Erreur :", err);
                showSnackbar("Erreur lors de la récupération des données Facebook", true);
            } finally {
                setLoading(false);
            }
        };

        if (userData?.idOrg) {
            fetchFacebookData();
        }
    }, [userData?.idOrg, refresh]);

    return (

        <Box>
            <Stack paddingTop={3} spacing={3}>
                <Stack direction={{xs:'column', md:'row'}} spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ border: '1px solid gainsboro', borderRadius: '7px' }}>
                    <Stack padding={4} direction='row'>
                        <Avatar sx={{ width: 70, height: 70, bgcolor: 'white' }}>
                            <Iconify icon='logos:facebook' sx={{ width: 70, height: 70, }} />
                        </Avatar>

                        <Box sx={{ display: 'flex', alignItems: 'end' }}>
                            <Iconify icon={!fbUser ? 'mdi:link' : 'flat-color-icons:ok'} sx={{ width: 20, height: 20, }} />
                        </Box>
                    </Stack>
                    <Stack spacing={2} padding={2}>
                        <Typography variant='h4'>Facebook</Typography>
                        <Typography variant='body2'>{!fbUser ? 'Configurez des campagnes publicitaires et partagez des événements sur votre page Facebook lorsque vous connectez votre compte avec Choptonticket.' : 'Vous pouvez créer des campagnes facebook et instagram'}
                        </Typography>
                        {
                            fbUser && <Stack direction={'row'} spacing={1}>
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <Typography variant='body2'>connecté en tant que </Typography>
                                    <CustomTag text={`${fbUser?.name}`} />
                                </Stack>
                                <CustomButton type='tertiaire' buttonText='Déconnexion' onClick={handleDeconnect} />
                            </Stack>
                        }

                        <Stack spacing={1}>
                            {
                                pages.length > 0 &&
                                <>
                                    <Typography variant='subtitle1'>Pages facebooks</Typography>
                                    <Grid container spacing={2}>
                                        {pages.length > 0 ? (
                                            pages.map((page, index) => (
                                                <Grid item xs={12} sm={6} md={4} key={index}>
                                                    <Card sx={{ padding: 2, height: '100%', minHeight: 80, maxHeight: 200, position: 'relative', boxShadow: 5, borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }} onMouseEnter={() => setHoveredIndex(index)}
                                                        onMouseLeave={() => setHoveredIndex(null)}>
                                                        {
                                                            index === defaultPage && <CustomTag text={`Defaut`} />
                                                        }
                                                        <Stack marginTop={1} direction={'row'} alignItems='center' spacing={1}>
                                                            {page.picture.data.url && (
                                                                <Avatar
                                                                    src={page.picture.data.url}
                                                                    alt={page.name}
                                                                    sx={{ width: 48, height: 48, border: '2px solid gainsboro' }}
                                                                />
                                                            )}

                                                            <Typography key={index}>{page.name}</Typography>
                                                        </Stack>

                                                        {hoveredIndex === index && (
                                                            <CustomButton
                                                                type="primaire"
                                                                buttonText={'utiliser par defaut'}
                                                                size="small"
                                                                sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}
                                                                onClick={() => handleDefaultPage(index)}
                                                            />
                                                        )}
                                                    </Card>

                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>Aucune page trouvée</Typography>
                                        )}
                                    </Grid>
                                </>
                            }
                        </Stack>
                        <Stack spacing={1}>
                            {
                                adAccounts.length > 0 &&
                                <>
                                    <Typography variant='subtitle1'>Comptes publicitaires</Typography>
                                    <Grid container spacing={2}>
                                        {adAccounts.length > 0 ? (
                                            adAccounts.map((account, index) => (
                                                <Grid item xs={12} sm={6} md={4} key={index}>
                                                    <Card sx={{ padding: 2, height: '100%', minHeight: 80, maxHeight: 200, position: 'relative', boxShadow: 5, borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }} onMouseEnter={() => setHoveredIndex(`ad-${index}`)}
                                                        onMouseLeave={() => setHoveredIndex(null)}>
                                                        {
                                                            index === defaultAdAccount && <CustomTag text={`Defaut`} />
                                                        }
                                                        <Typography key={index}>{account.name}</Typography>
                                                        {hoveredIndex === `ad-${index}` && (
                                                            <CustomButton
                                                                type="primaire"
                                                                buttonText={'utiliser par defaut'}
                                                                size="small"
                                                                sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}
                                                                onClick={() => handleDefaultAdAccount(index)}
                                                            />
                                                        )}
                                                    </Card>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>Aucune page trouvée</Typography>
                                        )}
                                    </Grid>
                                </>
                            }
                        </Stack>
                        {
                            !fbUser && <CustomButton type='tertiaire' buttonText='Connecter votre compte Facebook' onClick={handleConnect} loading={loading}/>
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
// material
import {
    Stack,
    Container,
    Typography,
    Box,
    Card,
    Avatar,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
// components
// eslint-disable-next-line no-unused-vars
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Page from '../../../components/Page';

// mock

import { UserAuth } from '../../../context/AuthContext';
import Utilisateurs from './composant/utilisateurs';
import Roles from './composant/roles';
import CustomButton from '../../../components/CustomButton';
import { db } from '../../../firebase.config';
import { useSnackbar } from '../../../context/snackbarContext';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function JoinOrganizationPage() {

    const [searchParams] = useSearchParams();
    const invitationId = searchParams.get("invitation_id");
    const invitationToken = searchParams.get("invitation_token");

    const { user, userData, logOut } = UserAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [invitedOrg, setInvitedOrg] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    useEffect(() => {
        console.log('-----------------------------')
        const checkInvitation = async () => {
            console.log(invitationId)
            console.log(invitationToken)
            console.log(user)
            if (!invitationId || !invitationToken) {
                showSnackbar("Lien d'invitation invalide.", true);
                // navigate("/");
                return;
            }

            if (!user.uid) {
                console.log('usernot ')
                showSnackbar("Veuillez vous connecter pour accepter l'invitation.", true);
                navigate(`/login?redirect=${encodeURIComponent(`/join?invitation_id=${invitationId}&invitation_token=${invitationToken}`)}`);
                return;
            }

            try {
                // Vérifier l'invitation dans Firestore
                const invitationRef = doc(db, `invitations/${invitationId}`);
                const invitationSnap = await getDoc(invitationRef);

                if (!invitationSnap.exists()) {
                    showSnackbar("Invitation introuvable.", true);
                    navigate("/");
                    return;
                }

                const invitationData = invitationSnap.data();
                if (invitationData.token !== invitationToken) {
                    showSnackbar("Une erreur est survenue.", true);
                    navigate("/");
                    return;
                }

                setInvitedOrg(invitationData.orgId);
                console.log(userData)
                // Vérifier si l'utilisateur appartient déjà à une organisation
                if (userData && userData.idOrg) {
                    setCurrentOrg(userData.idOrg);
                    console.log(userData)
                    if (userData.idOrg === invitationData.orgId) {
                        showSnackbar("Vous êtes déjà membre de cette organisation.", false);
                        navigate("/bo");
                        return;
                    }
                }

                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la vérification de l'invitation :", error);
                showSnackbar("Une erreur est survenue.", true);
                navigate("/");
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        checkInvitation();
    }, [user, userData, invitationId, invitationToken]);

    const handleAcceptInvitation = async () => {
        setOpenConfirmDialog(true);
    };



    const confirmAcceptInvitation = async () => {
        setLoading(true)
        try {
          const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/acceptInvitation', {
            method: 'POST',
            body: JSON.stringify({
              invitationId,
              userId: user.uid,
              invitationToken,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          const data = await response.json();
          console.log(data)
          if (response.ok) {
            showSnackbar("Vous avez rejoint l'organisation avec succès !");
            navigate("/bo");
          } else {
            showSnackbar(data.error || "Une erreur est survenue lors de l'acceptation.", true);
          }
        } catch (error) {
          console.error("Une erreur est survenue lors de l'acceptation :", error);
          showSnackbar("Une erreur est survenue.", true);
        } finally {
          setLoading(false)
        }
      };

    const handleLogout = async () => {
        try {
            await logOut();
            navigate(`/login?redirect=/join/?invitation_id=${invitationId}&invitation_token=${invitationToken}`);
        } catch (error) {
            console.error("Erreur lors de la déconnexion :", error);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress />
            </Box>
        );
    }




    return (
        <Page title="Rejoindre une organisation">
            <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
                <Container sx={{ marginTop: { xs: 3 }, }}>
                    <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                        <Typography textAlign='center' variant="h3" gutterBottom>
                            Vous avez été invité à vous joindre à Christian Yannick besse
                        </Typography>
                        <Card sx={{ padding: 4 }}>
                            <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                                <Avatar sx={{ width: 100, height: 100 }} />
                                <Typography textAlign='center'>Mgroup</Typography>
                            </Stack>
                        </Card>
                        <Typography textAlign='center' variant='body1'>En cliquant sur Accepter, vous serez ajouté à l'organisation, Christian Yannick besse. Vous aurez accès aux permissions admin</Typography>

                        <CustomButton type={'primaire'} buttonText={'Accepter'} onClick={handleAcceptInvitation} />
                        <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    {
                                        userData.idOrg && userData.idOrg != null ? 'Rejoindre votre nouvelle l\'organisation ' : ''
                                    }
                                    En acceptant cette invitation, vous quitterez votre organisation actuelle pour rejoindre la nouvelle. Êtes-vous sûr de vouloir continuer ?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <CustomButton buttonText={'Annuler'} type={'secondaire'} onClick={() => setOpenConfirmDialog(false)} />
                                <CustomButton buttonText={'Confirmer'} type={'primaire'} onClick={() => confirmAcceptInvitation()} />
                            </DialogActions>
                        </Dialog>
                        <Stack justifyContent={'center'} alignItems={'center'}>
                            <Avatar />
                            <Typography variant='body2'>Vous été connecter en tant que </Typography>
                            <CustomButton type={'tertiaire'} buttonText={'Deconnexion'} />
                        </Stack>
                    </Stack>

                </Container>
            </Box>
        </Page>
    );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  Modal,
  LinearProgress,
  IconButton,
  Backdrop,
  CircularProgress,
  Alert,
  MenuItem,
  alpha,
  styled,
  Menu,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
// mock
import CustomButton from '../../components/CustomButton';
import { useSnackbar } from '../../context/snackbarContext';
import { UserAuth } from '../../context/AuthContext';
import withAuthorization from '../../withAuthorization';
import { hasPermission } from '../../utils/functions';

// ----------------------------------------------------------------------
// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
// ----------------------------------------------------------------------



const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

function Discount() {

  const { eventID } = useParams();
  const { permissions } = UserAuth();
  // loading
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const showSnackbar = useSnackbar()
  // modification de code
  const [summary, setSummary] = React.useState({}); // valeur de la reduction
  // for change value of globla quota
  const [modif, setModif] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  // setup des codes promo
  const [discounts, setDiscount] = useState([]);

  React.useEffect(() => {
    (async () => {
      setDiscount([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/codePromo`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setDiscount((discounts) => [...discounts, d]);
          console.log(d.id, ' => ', d.data());
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, eventID]);

  React.useEffect(() => {
    (async () => {
      setDiscount([]);
      try {
        setLoading(true);
        const q = await getDoc(doc(db, `evenement/${eventID}/recette/`, eventID));
        if (q.exists()) {
          setSummary(q.data())
        }
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, eventID]);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleVisible = async () => {
    const discount = discounts[selectedIndex];
    if (discount.data().statut === 0) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`);

        await updateDoc(statRef, {
          statut: 1,
        });
        showSnackbar("Cde promotionel actif", false)
      } catch (error) {
        handleClose();
        console.log(error.message);
        showSnackbar("Une erreur est survenue", true)

      }
    } else if (discount.data().statut === 1) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`);
        await updateDoc(statRef, {
          statut: 0,
        });
        showSnackbar("Code promotionel inactif", false)
      } catch (error) {
        handleClose();
        console.log(error.message);
        showSnackbar("Une erreur est survenue", true)
      }
    }
    handleClose();
  };
  const handleModif = async (index) => {
    setLoading(true);
    navigate(`${discounts[selectedIndex].id}`)
    setLoading(false);
  };

  // supression d'un billet
  const handleSupp = async () => {
    handleToggle();
    console.log('supp');
    const discount = discounts[selectedIndex];

    try {
      await deleteDoc(doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`));
      showSnackbar("Code promotionel supprimé avec success", false)
    } catch (error) {
      console.log(error.message);
      showSnackbar("Une erreur est survenue", true)
      handleClose();
    }

    handleClose();
    handleCloseDeleteModal();
  };



  const [selectedIndex, setSelectedIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  return (
    <Page title="Code Promotion">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}

      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: '7px' }}
      >
        <Box sx={styleModal}>
          <Typography variant="h6">Supprimer cet code de promotion</Typography>
          <Box sx={{ marginTop: 5, p: 2 }} dir="ltr">
            <Alert severity="error">
              Cette action entraînera la suppression définitive le code de promotion.
            </Alert>
          </Box>
          <Grid container spacing={2} justifyContent="end" paddingTop={5}>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'black',
                  backgroundColor: 'white',
                  borderRadius: '7px',
                  borderColor: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'black',
                  },
                }}
                onClick={handleCloseDeleteModal}
              >
                Annuler
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  Color: 'white',
                  backgroundColor: 'red',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'red',
                    borderColor: 'red',
                  },
                }}
                onClick={() => handleSupp()}
              >
                Supprimer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', sm: 'center' }}
          justifyContent="space-between"
          mb={5}
          spacing={2}
        >
          <Typography variant="h3" gutterBottom>
            Code de promotion <br />
            <Typography variant="body2">
              Les codes de promotion vous permettent de créer des opérations ciblées dans le temps ou réservées à certains de vos participants.
            </Typography>
          </Typography>
        </Stack>

        <Stack spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <Card sx={{ padding: 3 }}>
                <Stack>
                  <Typography variant='body2'>Coût Total</Typography>
                  <Typography variant='subtitle1'>{summary.costPromoCode > 0 ? `${summary.costPromoCode} CFA` : '-'}</Typography>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Card sx={{ padding: 3 }}>
                <Stack>
                  <Typography variant='body2'>Revenu Total</Typography>
                  <Typography variant='subtitle1'>{summary.gainPromoCode > 0 ? `${summary.gainPromoCode} CFA` : '-'}</Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <CustomButton type='primaire' buttonText={'Ajouter un code promo'} component={RouterLink}
              to="ajout" />
          </Box>



          <Stack spacing={2}>
            {(discounts.length === 0 && !loading) && <Stack spacing={3} padding={2} justifyContent='center' alignItems={'center'} sx={{ border: '2px solid gainsboro', borderRadius: '10px' }}>
              <Iconify icon={'mdi-light:gift'} sx={{ height: 50, width: 50 }} />
              <Stack justifyContent='center' alignItems={'center'}>
                <Typography variant='subtitle1'>Pas de code promo disponible</Typography>
                <Typography variant='body2'>Vos codes promo apparaitront ici une fois créés</Typography>
              </Stack>
              <Box>
                {hasPermission(permissions, "add_promocode") && (
                  <CustomButton type='primaire' buttonText={'Ajouter un code promo'} component={RouterLink}
                    to="ajout" />
                )}

              </Box>

            </Stack>}
            {discounts.map((row, index) => (
              <Grid key={row.data().name} container padding={2} sx={{ display: 'flex', alignItems: 'center', bgcolor: '#f2f2f2', borderRadius: '10px' }}>
                <Grid item xs={12} sm={4}>
                  <Stack>
                    <Stack direction={'row'} spacing={1}>
                      <Typography variant='h6'>{row.id}</Typography>
                      {
                        row.data().statut === 0 &&

                        <Box sx={{ bgcolor: '#FFD6D6', borderRadius: '20px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography fontSize={11} sx={{ color: '#720000' }} variant='caption'>Inactif</Typography>
                        </Box>
                      }

                    </Stack>

                    <Typography variant='subtitle1'>{row.data().reduc.value}
                      {row.data().reduc.type === 0 ? '%' : 'CFA'} de reduction</Typography>
                    {row.data()?.billet?.length > 0 ? <Typography variant='caption'>Applicable à certains billets</Typography> : <Typography variant='caption'>Applicable à tous les billets</Typography>}

                  </Stack>
                </Grid>
                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack>
                    <Typography textAlign={'center'} variant='body2'>Utilisation</Typography>
                    <Typography textAlign={'center'} variant='subtitle1'>{row.data()?.now}/{row.data()?.max}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack>
                    <Typography textAlign={'center'} variant='body2'>Coût</Typography>
                    <Typography textAlign={'center'} variant='subtitle1'>{row.data().cost > 0 ? `${row.data().cost} CFA` : '-'}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Stack>
                    <Typography textAlign={'center'} variant='body2'>Revenu</Typography>
                    <Typography textAlign={'center'} variant='subtitle1'>{row.data().gain > 0 ? `${row.data().gain} CFA` : '-'}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                    aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined} sx={{ border: '1px solid gainsboro' }}>
                    <Iconify icon="material-symbols-light:more-vert" />
                  </IconButton>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                  >
                    {hasPermission(permissions, "add_promocode") && (
                      <CustomButton type='primaire' buttonText={'Ajouter un code promo'} component={RouterLink}
                        to="ajout" />
                    )}
                    <>
                    </>
                    <MenuItem key={`${index}-1`} onClick={(e) => {
                      handleCloseMenu();
                      handleModif(selectedIndex); // Utilise l'index sélectionné
                    }} disableRipple value={1}>
                      <Typography variant='body1'>Editer</Typography>
                    </MenuItem>

                    {
                      discounts[selectedIndex]?.data().statut === 1 ?
                        <MenuItem
                          onClick={(e) => {
                            handleCloseMenu();
                            handleVisible()
                          }}
                          disableRipple
                          value={2}
                        >
                          <Typography variant='body1'>Désactiver</Typography>
                        </MenuItem>
                        : discounts[selectedIndex]?.data().statut === 0 ?
                          <MenuItem
                            onClick={(e) => {
                              handleCloseMenu();
                              handleVisible()
                            }}
                            disableRipple
                            value={2}
                          >
                            <Typography variant='body1'>Activer</Typography>
                          </MenuItem> : null
                    }
                    <MenuItem
                      onClick={(e) => {
                        handleCloseMenu();
                        handleOpenDeleteModal()
                      }}
                      disableRipple
                      value={2}
                    >
                      <Typography variant='body1'>Supprimer</Typography>
                    </MenuItem>

                  </StyledMenu>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Stack>


      </Container>

    </Page>
  );
}

export default withAuthorization(Discount, "manage_promocode")

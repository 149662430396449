/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
// material
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Card,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import EmojiPicker from "emoji-picker-react";
// components
import {
    addDoc,
    collection,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';
import Papa from 'papaparse';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { db } from '../../../../../firebase.config';
import Iconify from '../../../../../components/Iconify';
import { UserAuth } from '../../../../../context/AuthContext';
import { Variables } from '../../../../../context/VariableContext';
import CustomButton from '../../../../../components/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function CreatePopulation({ orgID, populationID }) {
    const { user } = UserAuth();
    const [expanded, setExpanded] = React.useState(false);
    const [filters, setFilters] = useState({
        filterEvent: [], filterInterest: [], filterNbEvent: { min: '', max: '' }, filterBillet: { min: '', max: '' }, totalDepense: { min: '', max: '' }, lastDateOrder: { before: null, after: null }, filterNewsletter: null, filterNotif: null, filterAbonne: null, filterHist: null, start: null, end: null
    });
    const [events, setEvents] = React.useState([]);
    const [selectedEvents, setSelectedEvents] = React.useState([]);
    const [filterHist, setFilterHist] = React.useState('');
    const [refreshCount, setRefreshCount] = React.useState(false);
    const [nombre, setNombre] = React.useState(0);
    const { openDrawer, setOpenDrawer } = Variables();
    const [step, setStep] = React.useState(0);
    const [type, setType] = React.useState(0);
    const [nom, setNom] = React.useState('');
    const [desc, setDesc] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [types, setTypes] = React.useState([])
    const [selectedTypes, setSelectedTypes] = React.useState([]);



    const handleChangev1 = (field, value) => {
        console.log(value)
        setFilters(prev => ({ ...prev, [field]: value }));
    }


    const handleChangev2 = (field, key, value) => {
        setFilters(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                [key]: value
            }
        }));
    };

    const handleChange = (panel) => async (event, isExpanded) => {
        if (panel === 'panel1') {
            if (events.length === 0) {
                setEvents([]);
                const tmp = []
                console.log(user);
                const u1 = await getDoc(doc(db, 'organisateurs', `${user.uid}`));
                console.log(u1.data());
                const q = query(
                    collection(db, 'evenement'),
                    where('orgId', '==', u1.data().idOrg),
                    orderBy('dateDebut', 'asc')
                );
                const querySnapshot = await getDocs(q);
                // setEvents(querySnapshot.docs);
                querySnapshot.forEach((d) => {
                    // sort list
                    const element = d;
                    tmp.push(element)
                });
                setEvents(tmp)
            }
        } else {
            console.log('ko');
        }
        setExpanded(isExpanded ? panel : false);
    };



    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                if (populationID) {
                    setStep(1)
                    setType(0)
                    const popRef0 = doc(db, `organisations/${orgID}/crm/${orgID}/populations`, `${populationID}`);
                    const popSnap = await getDoc(popRef0);
                    if (popSnap.exists()) {
                        setChosenEmoji(popSnap?.data().emoji)
                        setNom(popSnap.data().name)
                        setDesc(popSnap.data().description)
                    }
                }
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [populationID]);

    // selection historique
    const handleChangeHistorique = (event) => {
        setFilterHist(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // emoji
    const [chosenEmoji, setChosenEmoji] = useState("😊");
    const [showPicker, setShowPicker] = useState(false);

    const onEmojiClick = (emojiObject) => {
        console.log(emojiObject.emoji)
        setChosenEmoji(emojiObject.emoji);
        setShowPicker(false);
    };

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    // create population
    const createPopulation = async () => {
        setLoading(true)
        try {
            console.log(orgID)
           
            if (type === 0) {
                await createDynamiquePopulation()
            } else if (type === 1) {
                const pop = await addDoc(collection(db, `organisations/${orgID}/crm/${orgID}/populations`), {
                    emoji: chosenEmoji,
                    name: nom,
                    type,
                    filter: type === 0 ? filters : null,
                    description: desc ? desc : null,
                    createdAt: new Date(),
                    updatedAt: new Date()
                });
                await handleUploadToFirebase(pop.id)
            }
            setOpenDrawer(false);
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };

    // save population
    const savePopulation = async () => {
        setLoading(true)
        try {
            console.log('salut')
            console.log(orgID)

            await updateDoc(doc(db, `organisations/${orgID}/crm/${orgID}/populations`, populationID), {
                emoji: chosenEmoji,
                name: nom,
                description: desc ? desc : null,
                filter: filters,
                updatedAt: new Date(),
            });
            setOpenDrawer(false);
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };

    const createDynamiquePopulation = async () => {
        const url = "https://us-central1-event-86cbf.cloudfunctions.net/generatePopulation"; // Remplace par ton URL Firebase

        const requestData = {
            orgID,
            filters,
            emoji: chosenEmoji,
            name: nom,
            type,
            desc: desc ? desc : null,
            
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            });
            console.log(response)
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const result = await response.json();
            console.log(result)
            return result.totalUsers; // Retourne le nombre total d'utilisateurs
        } catch (error) {
            console.error("Erreur lors de la creation de la population :", error);
            return 0; // Retourne 0 en cas d'erreur
        }
    };

    const fetchFilteredContactsCount = async () => {
        const url = "https://us-central1-event-86cbf.cloudfunctions.net/getFilteredContactsCount"; // Remplace par ton URL Firebase

        const requestData = {
            orgID,
            filters
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            });
            console.log(response)
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const result = await response.json();
            console.log(result)
            return result.totalUsers; // Retourne le nombre total d'utilisateurs
        } catch (error) {
            console.error("Erreur lors de la récupération du nombre de contacts :", error);
            return 0; // Retourne 0 en cas d'erreur
        }
    };

    /** creation de population manuelle */

    const [fileName, setFileName] = useState(null);
    const [csvData, setCsvData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [fieldMapping, setFieldMapping] = useState({});

    const defaultMappings = {
        'email': 'email',
        'telephone': 'telephone',
        'nom': 'nom',
        'prenoms': 'prenoms',
        'abonne': 'abonne',
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setLoading(true);
        setFileName(file.name);
        Papa.parse(file, {
            complete: (result) => {
                const { data, meta } = result;
                setHeaders(meta.fields || []);
                setCsvData(data);

                const initialMapping = {};
                (meta.fields || []).forEach(header => {
                    initialMapping[header] = defaultMappings[header.toLowerCase()] || '';
                });
                console.log(initialMapping)
                setFieldMapping(initialMapping);
                setLoading(false);
            },
            header: true,
            skipEmptyLines: true,
        });
    };

    const handleFieldMappingChange = (csvHeader, targetField) => {
        setFieldMapping((prevMapping) => ({
            ...prevMapping,
            [csvHeader]: targetField,
        }));
    };

    const handleRemoveFile = () => {
        setCsvData([]);
        setFileName(null);
        setHeaders([]);
        setFieldMapping({});
    };

    const handleUploadToFirebase = async (popId) => {
        setLoading(true);
        try {
            const collectionRef = collection(db, `organisations/${orgID}/crm/${orgID}/contacts`);
            const userPopRef = collection(db, `organisations/${orgID}/crm/${orgID}/populations/${popId}/users`);
            const mappedData = csvData.map((row) => {
                const transformedRow = {};
                headers.forEach((header) => {
                    const targetField = fieldMapping[header];
                    if (targetField) {
                        transformedRow[targetField] = row[header];
                    }
                });
                return transformedRow;
            });

            for (const data of mappedData) {
                console.log(data)
                const email = data.email?.toLowerCase();
                if (!email) continue;

                const docRef = doc(collectionRef, email);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) {
                    await setDoc(docRef, {
                        nom: data?.nom,
                        prenom: data?.prenoms,
                        abonne: data?.abonne === true ? true : false,
                        push: data?.abonne === true ? true : false,
                        newslettres: data?.abonne === true ? true : false,
                        createDate: new Date(),
                        updateDate: new Date()
                    });
                    await setDoc(doc(userPopRef, email), {
                        addedAt: new Date()
                    });
                } else {
                    // Création du document si non existant
                    await setDoc(doc(userPopRef, email), {
                        addedAt: new Date()
                    });
                }
            }

            handleRemoveFile();
            setOpenDrawer(false)
        } catch (error) {
            console.error("Erreur lors de l'importation des contacts:", error);
        }
        setLoading(false);
    };

    /** fin creation manuelle de population */

    React.useEffect(() => {
        const fetchCount = async () => {
            const count = await fetchFilteredContactsCount(filters);
            setNombre(count);
        };

        fetchCount();
    }, [filters]);

    React.useEffect(() => {
        (async () => {

            try {
                setLoading(true);
                setTypes([])
                const q = query(collection(db, `types`));
                const snapshot = await getDocs(q);

                snapshot.forEach(async (d) => {
                    setTypes((types) => [...types, d]);
                });
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <Box>
            {
                // eslint-disable-next-line no-nested-ternary
                step === 0 ?
                    <Box
                        paddingBottom={3}
                        paddingX={3}
                        sx={{ height: { xs: '75vh', sm: ' 78vh', xl: '85vh' }, overflowX: 'auto', overflowY: 'auto' }}
                    >

                        <Stack spacing={3}>
                            <Box padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                                <Typography variant='body2'>
                                    Une population est un groupe de contacts qui partagent des points communs ou comportements. Une population contient plusieurs contacts, et un contact peut appartenir à plusieurs population
                                </Typography>
                            </Box>
                            <Box padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                                <Stack spacing={2} justifyContent={'start'} alignItems={'start'}>
                                    <Typography variant='subtitle1'>
                                        Population filtres dynamiques
                                    </Typography>
                                    <Typography variant='body2'>
                                        Une population est un groupe de contacts qui partagent des points communs ou comportements. Une population contient plusieurs contacts, et un contact peut appartenir à plusieurs population
                                    </Typography>
                                    <CustomButton type={'primaire'} buttonText={'Creer une population dynamique'} onClick={() => {
                                        setType(0)
                                        setStep(1)
                                    }} />

                                </Stack>
                            </Box>
                            <Box padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                                <Stack spacing={2} justifyContent={'start'} alignItems={'start'}>
                                    <Typography variant='subtitle1'>
                                        Population manuelle via CSV
                                    </Typography>
                                    <Typography variant='body2'>
                                        Une population est un groupe de contacts qui partagent des points communs ou comportements. Une population contient plusieurs contacts, et un contact peut appartenir à plusieurs population
                                    </Typography>
                                    <CustomButton type={'primaire'} buttonText={'Creer une population manuelle'} onClick={() => {

                                        setType(1)
                                        setStep(1)

                                    }} />

                                </Stack>
                            </Box>
                        </Stack>
                    </Box> : step === 1 && type === 0 ?
                        <Box
                            paddingBottom={3}
                            paddingX={3}
                            sx={{ height: { xs: '75vh', sm: ' 78vh', xl: '85vh' }, overflowX: 'auto', overflowY: 'auto' }}
                        >
                            {/** Critères */}
                            <Typography variant="h5" paddingBottom={1} paddingTop={1}>
                                Nouvelle population 1/2
                            </Typography>
                            <Typography variant="subtitle1" paddingBottom={1} paddingTop={2}>
                                Evenements
                            </Typography>
                            {/** participation aux event */}
                            <Accordion
                                expanded={expanded === 'panel1'}
                                onChange={handleChange('panel1')}
                                sx={{ bgcolor: '#f2f2f2', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="mdi:calendar-outline" sx={{ minWidth: 20, minHeight: 20 }} />
                                        <Typography >Participations aux évènements</Typography>
                                        {filters.filterEvent.length > 0 && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters.filterEvent.length} selectionné
                                                </Typography>
                                                <IconButton onClick={(e) => {
                                                    setSelectedEvents([])
                                                    handleChangev1('filterEvent', [])
                                                }}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        events.length > 0 ?
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                options={events}
                                                getOptionLabel={(event) => event?.data().name}
                                                value={selectedEvents}
                                                renderOption={(props, option) => (
                                                    <Stack key={option.id} direction='row' spacing={1} component="li" {...props} display="flex" alignItems="center">
                                                        <LazyLoadImage
                                                            src={`${option.data().idVisuel}`}
                                                            alt={`${option.data().idVisuel}`}
                                                            className="img-lazy"
                                                            width={70}
                                                            height={70}
                                                            style={{ objectFit: 'cover' }}
                                                            effect="blur" // opacity | black-and-white
                                                        />
                                                        <Box>
                                                            <Typography variant="subtitle1">{option?.data().nomEvent}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                dans 3 jours
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {option?.data().participant} participants
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                )}
                                                onChange={(e, newValue) => {
                                                    setSelectedEvents(newValue)
                                                    setFilters((prevFilters) => ({
                                                        ...prevFilters,
                                                        filterEvent: newValue.map((event) => event.id) // Mettre à jour les intérêts sélectionnés
                                                    }));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder={filters.filterEvent.length > 0 ? null : 'Selectionner les evenements'}
                                                        // error={!!error}
                                                        multiline
                                                    // helperText={error}
                                                    />
                                                )}
                                                filterOptions={(options, { inputValue }) =>
                                                    options.filter((option) =>
                                                        option?.data()?.nomEvent?.toLowerCase().includes(inputValue.toLowerCase())
                                                    )
                                                }
                                                renderTags={(tagValue, getTagProps) =>
                                                    tagValue.map((event, index) => (
                                                        <Chip
                                                            sx={{ bgcolor: '#F5EFFA' }}
                                                            key={event.id}
                                                            label={event.data().name || event.data().nomEvent}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                            />
                                            :
                                            null
                                    }


                                </AccordionDetails>
                            </Accordion>
                            {/** nombre total d'event */}
                            <Accordion
                                expanded={expanded === 'panel2'}
                                onChange={handleChange('panel2')}
                                sx={{ bgcolor: '#f2f2f2', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="carbon:ticket" sx={{ minWidth: 20, minHeight: 20 }} />
                                        <Typography>Nombre total d'evenements</Typography>
                                        {(filters?.filterNbEvent?.min !== '' || filters?.filterNbEvent?.max !== '') && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.filterNbEvent?.min}-{filters?.filterNbEvent?.max}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('filterNbEvent', { min: '', max: '' })}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="caption">Nombre minimum et/ou maximum d’évènements pour lesquels vos contacts ont acheté des billets.</Typography>
                                    <Stack paddingY={1} spacing={2} direction="row" alignItems="center">
                                        <TextField
                                            placeholder="min"
                                            value={filters?.filterNbEvent?.min}
                                            onChange={(e) => handleChangev2('filterNbEvent', 'min', e.target.value)}
                                            size="small"
                                        />
                                        <Typography variant="body2">à</Typography>
                                        <TextField placeholder="max" value={filters?.filterNbEvent?.max} onChange={(e) => handleChangev2('filterNbEvent', 'max', e.target.value)} size="small" />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Typography variant="subtitle1" paddingBottom={1} paddingTop={2}>
                                Gouts musicaux
                            </Typography>
                            {/** interets */}
                            <Accordion
                                expanded={expanded === 'panel3'}
                                onChange={handleChange('panel3')}
                                sx={{ bgcolor: '#f2f2f2', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="material-symbols-light:interests-outline" sx={{ minWidth: 20, minHeight: 20 }} />
                                        <Typography>Genre musical</Typography>
                                        {(filters.filterInterest.length > 0) && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters.filterInterest.length === 1 ? filters.filterInterest[0] : filters.filterInterest.length > 1 ? `${filters.filterInterest[0]}  + ${filters.filterInterest.length - 1}` : null}
                                                </Typography>
                                                <IconButton onClick={(e) => {
                                                    setSelectedTypes([])
                                                    handleChangev1('filterInterest', [])
                                                }}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="caption">Contacts qui ont acheté des billets pour vos évènements étiquetés avec l’un de ces genres musicaux.</Typography>
                                    <Box padding={1}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={types}
                                            getOptionLabel={(type) => type?.data().nom}
                                            value={selectedTypes}
                                            onChange={(event, newValue) => {
                                                setSelectedTypes(newValue)
                                                setFilters((prevFilters) => ({
                                                    ...prevFilters,
                                                    filterInterest: newValue.map((population) => population.data().nom) // Mettre à jour les intérêts sélectionnés
                                                }));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={filters.filterInterest.length > 0 ? null : 'Selectionner des interêts'}
                                                    // error={!!error}
                                                    multiline
                                                // helperText={error}
                                                />
                                            )}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((population, index) => (
                                                    <Chip
                                                        sx={{ bgcolor: '#F5EFFA' }}
                                                        key={population.nom}
                                                        label={population.data().nom}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Typography variant="subtitle1" paddingBottom={1} paddingTop={2}>
                                Commandes
                            </Typography>
                            { }
                            {/** Nombre de billet detenue */}
                            <Accordion
                                expanded={expanded === 'panel4'}
                                onChange={handleChange('panel4')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="iconamoon:ticket-thin" sx={{ minWidth: 20, minHeight: 20 }} />
                                        <Typography>Nombre de billets detenue</Typography>
                                        {(filters?.filterBillet?.min !== '' || filters?.filterBillet?.max !== '') && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.filterBillet?.min}-{filters?.filterBillet?.max}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('filterBillet', { min: '', max: '' })}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="caption">Nombre minimum et/ou maximum de billet acheté.</Typography>
                                    <Stack paddingY={1} spacing={2} direction="row" alignItems="center">
                                        <TextField
                                            placeholder="min"
                                            value={filters?.filterBillet?.min}
                                            onChange={(e) => handleChangev2('filterBillet', 'min', e.target.value)}
                                            size="small"
                                        />
                                        <Typography variant="body2">à</Typography>
                                        <TextField placeholder="max" value={filters?.filterBillet?.max} onChange={(e) => handleChangev2('filterBillet', 'max', e.target.value)} size="small" />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            {/** total depensé */}
                            <Accordion
                                expanded={expanded === 'panel5'}
                                onChange={handleChange('panel5')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="arcticons:money-manager-ktwapps" sx={{ minWidth: 20, minHeight: 20 }} />
                                        <Typography>Total depensé</Typography>
                                        {(filters?.totalDepense?.min !== '' || filters?.totalDepense?.max !== '') && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.totalDepense?.min}-{filters?.totalDepense?.max}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('totalDepense', { min: '', max: '' })}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="caption">Montant dépensé pour vos évènements.</Typography>
                                    <Stack paddingY={2} spacing={2} direction="row" alignItems="center">
                                        <TextField placeholder="min" value={filters?.totalDepense?.min} onChange={(e) => handleChangev2('totalDepense', 'min', e.target.value)} size="small" />
                                        <Typography variant="body2">à</Typography>
                                        <TextField placeholder="max" value={filters?.totalDepense?.max} onChange={(e) => handleChangev2('totalDepense', 'max', e.target.value)} size="small" />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            {/** Date de dernier achat */}
                            <Accordion
                                expanded={expanded === 'panel6'}
                                onChange={handleChange('panel6')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="bitcoin-icons:cart-outline" sx={{ minWidth: 20, minHeight: 20 }} />
                                        <Typography>Date de dernier achat</Typography>
                                        {(filters?.lastDateOrder?.before !== null || filters?.lastDateOrder?.after !== null) && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.lastDateOrder?.before && filters?.lastDateOrder?.after ? `Entre ${filters?.lastDateOrder?.after} et ${filters?.lastDateOrder?.before}` : filters?.lastDateOrder?.before ? `Avant ${filters?.lastDateOrder?.before}` : filters?.lastDateOrder?.after ? `Après ${filters?.lastDateOrder?.after}` : ''}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('lastDateOrder', { before: null, after: null })}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="caption">Les personnes qui ont acheté des billets pour vos évènements, soit avant une date spécifique, soit après celle-ci, ou dans le délai spécifié.</Typography>
                                    <Stack paddingY={1} spacing={1} direction="column">
                                        <Typography variant="body2">Après</Typography>
                                        <TextField type='date' placeholder="Après cette date" value={filters?.lastDateOrder?.after} onChange={(e) => handleChangev2('lastDateOrder', 'after', e.target.value)} size="small" />
                                        <Typography variant="body2">Avant</Typography>
                                        <TextField type='date' placeholder="min" value={filters?.lastDateOrder?.before} onChange={(e) => handleChangev2('lastDateOrder', 'before', e.target.value)} size="small" />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>

                            <Typography variant="subtitle1" paddingBottom={1} paddingTop={2}>
                                Marketing
                            </Typography>
                            {/** Abonne aux notifications  */}
                            <Accordion
                                expanded={expanded === 'panel7'}
                                onChange={handleChange('panel7')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="carbon:notification" sx={{ width: 20, height: 20 }} />
                                        <Typography>Abonné aux notifications ?</Typography>
                                        {filters?.filterNotif !== null && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.filterNotif}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('filterNotif', null)}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                            <Typography variant="caption">
                                                Contacts ayant accepté de recevoir des notifications de votre organisation dans l'application
                                                Choptonticket
                                            </Typography>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={filters?.filterNotif}
                                            onChange={(e) => handleChangev1('filterNotif', e.target.value)}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                            <FormControlLabel value={false} control={<Radio />} label="Non" />
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            {/** Abonne a la newsletter */}
                            <Accordion
                                expanded={expanded === 'panel8'}
                                onChange={handleChange('panel8')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="mage:email" sx={{ width: 20, height: 20 }} />
                                        <Typography>Abonné à la newsletters ?</Typography>
                                        {filters?.filterNewsletter !== null && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.filterNewsletter}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('filterNewsletter', null)}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                            <Typography variant="caption">Contacts abonnés à votre page organisateur sur Choptonticket</Typography>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={filters?.filterNewsletter}
                                            onChange={(e) => handleChangev1('filterNewsletter', e.target.value)}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                            <FormControlLabel value={false} control={<Radio />} label="Non" />
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            {/** abonne a ma page choptonticket */}
                            <Accordion
                                expanded={expanded === 'panel9'}
                                onChange={handleChange('panel9')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                                        <Typography>Abonné à ma page Choptonticket?</Typography>
                                        {filters?.filterAbonne !== null && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.filterAbonne}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('filterAbonne', null)}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                            <Typography variant='caption'>Contacts abonnés à votre page organisateur sur Choptonticket</Typography>

                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={filters?.filterAbonne}
                                            onChange={(e) => handleChangev1('filterAbonne', e.target.value)}
                                        >
                                            <FormControlLabel value control={<Radio />} label="Oui" />
                                            <FormControlLabel value={false} control={<Radio />} label="Non" />
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            <Typography variant="subtitle1" paddingBottom={1} paddingTop={2}>
                                Historique
                            </Typography>
                            {/** Historique d'ajout */}
                            <Accordion
                                expanded={expanded === 'panel10'}
                                onChange={handleChange('panel10')}
                                sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                            >
                                <AccordionSummary
                                    expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Iconify icon="material-symbols:update-rounded" sx={{ width: 20, height: 20 }} />
                                        <Typography>Date d'ajout</Typography>
                                        {(filters?.filterHist !== null) && (
                                            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                    {filters?.filterHist === 'custom' && filters?.start && filters?.end ? `${filters?.start} - ${filters?.end}` : filters?.filterHist === 'today' ? 'Aujourd\'hui' : filters?.filterHist === 'yesterday' ? 'Hier' : filters?.filterHist === 'last-7-days' ? 'Derniers 7 jours' : filters?.filterHist === 'last-14-days' ? 'Derniers 14 jours' : filters?.filterHist === 'last-30-days' ? 'Derniers 30 jours' : null}
                                                </Typography>
                                                <IconButton onClick={(e) => handleChangev1('filterHist', null)}>
                                                    <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label1">
                                            Quand ce contact a été ajouté à votre communauté
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label1"
                                            defaultValue="female"
                                            name="radio-buttons-group1"
                                            value={filters?.filterHist}
                                            onChange={(e) => handleChangev1('filterHist', e.target.value)}
                                        >
                                            <FormControlLabel value="today" control={<Radio />} label="Aujourd'hui" />
                                            <FormControlLabel value="yesterday" control={<Radio />} label="Hier" />
                                            <FormControlLabel value="last-7-days" control={<Radio />} label="Derniers 7 jours" />
                                            <FormControlLabel value="last-14-days" control={<Radio />} label="Derniers 14 jours" />
                                            <FormControlLabel value="last-30-days" control={<Radio />} label="Derniers 30 jours" />
                                            <FormControlLabel value="custom" control={<Radio />} label="Selectionner la plage" />
                                        </RadioGroup>
                                    </FormControl>
                                    {
                                        filters?.filterHist === 'custom' && <Stack paddingY={1} spacing={1} direction="column">
                                            <Typography variant="body2">Debut</Typography>
                                            <TextField type='date' value={filters?.start} onChange={(e) => handleChangev1('start', e.target.value)} size="small" />
                                            <Typography variant="body2">Fin</Typography>
                                            <TextField type='date' value={filters?.end} onChange={(e) => handleChangev1('end', e.target.value)} size="small" />
                                        </Stack>
                                    }

                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        : step === 1 && type === 1 ?
                            <Box paddingBottom={3} paddingX={3} sx={{ height: { xs: '84vh', sm: ' 80.25vh', xl: '84.25vh' }, overflowX: 'auto', overflowY: 'auto' }}>
                                <Typography variant="h5">Importer des utilisateurs</Typography>
                                <Accordion
                                    expanded={expanded === 'panel1'}
                                    onChange={handleChange('panel1')}
                                    sx={{ bgcolor: '#F0F0F0', mb: 2, mt: 3 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Stack>
                                            <Stack spacing={1} direction="row" alignItems="center">
                                                <Iconify icon="mdi:calendar-outline" sx={{ width: 20, height: 20 }} />
                                                <Typography variant='body2'><strong>0/2000</strong> contacts importé Aujourd'hui</Typography>
                                            </Stack>
                                            <Typography textAlign={'center'} variant='body2'>{csvData.length === 1 ? `1 nouveau contact sera ajouteé ` : csvData.length > 1 ? `${csvData.length} nouveaux contacts seront ajouté` : ''}</Typography>
                                        </Stack>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant='caption'>
                                            Pour raison de performance et de coût l'importation de contacts est limité à 2000 par jour.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                {fileName ? (
                                    <Stack spacing={2}>
                                        <Stack sx={{ border: '2px solid #f2f2f2', bgcolor: '#f2f2f2', borderRadius: '5px', padding: 3 }} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                                            <Box >
                                                {fileName}
                                            </Box>
                                            <IconButton onClick={handleRemoveFile}>
                                                <Iconify icon={'material-symbols-light:delete-outline'} />
                                            </IconButton>
                                        </Stack>
                                        <Stack sx={{ border: '2px solid #f2f2f2', borderRadius: '5px', padding: 3 }} spacing={2}>
                                            <Stack>
                                                <Typography variant='subtitle1'>Correspondance des informations</Typography>
                                                <Typography variant='caption'>Veuillez sélectionner les colonnes de votre fichier CSV correspondant aux informations demandées</Typography>
                                            </Stack>
                                            {headers.map((header) => (
                                                <Stack key={header} spacing={1}>
                                                    <Typography variant='subtitle2'>{header}</Typography>
                                                    <Select value={fieldMapping[header]} onChange={(e) => handleFieldMappingChange(header, e.target.value)}>
                                                        <MenuItem value="">-- Sélectionner un champ --</MenuItem>
                                                        <MenuItem value="email">Email</MenuItem>
                                                        <MenuItem value="telephone">Téléphone</MenuItem>
                                                        <MenuItem value="nom">Nom</MenuItem>
                                                        <MenuItem value="prenoms">Prénom</MenuItem>
                                                        <MenuItem value="abonne">Abonné</MenuItem>
                                                    </Select>

                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Stack>
                                ) : (
                                    <Box>

                                        <Button
                                            fullWidth
                                            size="small"
                                            sx={{
                                                marginTop: 2,
                                                textTransform: 'initial',
                                                boxShadow: 'none',
                                                border: '1px dashed gainsboro',
                                                color: 'black',
                                                backgroundColor: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'white',
                                                    color: '#5E17EB',
                                                    borderColor: '#5E17EB',
                                                },
                                            }}
                                            variant="contained"
                                            component="label"
                                        >
                                            <input
                                                hidden
                                                accept=".csv"
                                                id="select-image"
                                                type="file"
                                                onChange={handleFileUpload}
                                            />
                                            <Stack
                                                padding={2}
                                                sx={{
                                                    justifyContent: 'space-around',
                                                    alignItems: 'center',
                                                    color: 'black',
                                                }}
                                            >
                                                {loading ? (
                                                    '... chargement'
                                                ) : (
                                                    <>
                                                        <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, color: 'gainsboro' }} />
                                                        <Typography variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                                                        <Typography variant='caption'>Seul le type de fichier .csv est accepté</Typography>
                                                    </>
                                                )}
                                            </Stack>
                                        </Button>
                                        <CustomButton type={'tertiaire'} buttonText={'Télécharger un exemple de fichier CSV'} />
                                    </Box>
                                )}
                            </Box>
                            : step === 2 ?
                                <Box
                                    paddingBottom={3}
                                    paddingX={3}
                                    sx={{ height: { xs: '75vh', sm: ' 78vh', xl: '85vh' }, overflowX: 'auto', overflowY: 'auto' }}
                                >
                                    {/** Information generale */}

                                    <Typography variant="h5" paddingBottom={1} paddingTop={1}>
                                        Nouvelle population 2/2
                                    </Typography>
                                    <Stack spacing={1} paddingTop={1}>
                                        <Typography variant='body2'>
                                            <strong>Emoji</strong>
                                        </Typography>

                                        <Box onClick={togglePicker} style={{ backgroundColor: '#F5EFFA', borderRadius: '7px', width: 80, height: 80, border: '1px solid gainsboro', fontSize: "3rem", cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {chosenEmoji}
                                        </Box>
                                        {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
                                    </Stack>
                                    <Stack spacing={1} paddingTop={2}>
                                        <Typography variant='body2'>
                                            <strong>Nom</strong>
                                        </Typography>
                                        <TextField size='small' value={nom} onChange={(e) => setNom(e.target.value)} />
                                    </Stack>
                                    <Stack spacing={1} paddingY={2}>
                                        <Typography variant='body2'>
                                            <strong>Description</strong>
                                        </Typography>
                                        <TextField value={desc} onChange={(e) => setDesc(e.target.value)} type='text' multiline minRows={2} />
                                    </Stack>
                                    <Stack spacing={2}>
                                        <Typography variant='body2'>
                                            <strong>Contacts correspondants:</strong>
                                        </Typography>
                                        <Typography variant='caption'>
                                            Ceci est un segment dynamique. Vos contacts seront automatiquement ajoutés et retirés de ce segment lorsqu’ils respectent ses conditions.
                                        </Typography>
                                        {
                                            type === 1 &&
                                            <>
                                                <Typography variant='body2'>
                                                    <strong>Filtres :</strong>
                                                </Typography>
                                                <Stack direction='row' useFlexGap flexWrap="wrap" rowGap={1} spacing={2} padding={1} sx={{ border: '2px solid #f2f2f2', borderRadius: '7px' }}>
                                                    {
                                                        filters.filterEvent.length !== 0 ?
                                                            <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                                <Iconify icon="mdi:calendar-outline" sx={{ width: 17, height: 17 }} />
                                                                <Typography variant='caption'>Participations de:</Typography>
                                                                <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                                            </Stack>
                                                            : null
                                                    }
                                                    {(filters?.filterNbEvent?.min !== '' || filters?.filterNbEvent?.max !== '') ?
                                                        <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="carbon:ticket" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Participations de:</Typography>
                                                            <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                                        </Stack> : null
                                                    }
                                                    {
                                                        filters?.filterInterest?.length > 0 ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="material-symbols-light:interests-outline" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Intérêt:</Typography>
                                                            <Typography variant='caption'><strong>biny club +2</strong></Typography>
                                                        </Stack> : null
                                                    }

                                                    {
                                                        (filters?.filterBillet?.min !== '' || filters?.filterBillet?.max !== '') ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="iconamoon:ticket-thin" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Nombre de billet:</Typography>
                                                            <Typography variant='caption'><strong> biny club +2</strong></Typography>
                                                        </Stack> : null
                                                    }

                                                    {
                                                        (filters?.totalDepense?.min !== '' || filters?.totalDepense?.max !== '') ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="arcticons:money-manager-ktwapps" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Total dépensé:</Typography>
                                                            <Typography variant='caption'><strong>1000 CFA et 2000 CFA</strong></Typography>
                                                        </Stack> : null
                                                    }

                                                    {
                                                        (filters?.lastDateOrder?.before !== null || filters?.lastDateOrder?.before !== null) ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="bitcoin-icons:cart-outline" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Dernier achat:</Typography>
                                                            <Typography variant='caption'><strong> 1000 CFA et 2000 CFA</strong></Typography>
                                                        </Stack> : null
                                                    }
                                                    {
                                                        (filters?.filterAbonne !== null) ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="carbon:notification" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Abonné a ma page:</Typography>
                                                            <Typography variant='caption'>true</Typography>
                                                        </Stack> : null
                                                    }
                                                    {
                                                        (filters?.filterNotif !== null) ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="mage:email" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Abonné aux notif:</Typography>
                                                            <Typography variant='caption'>true</Typography>
                                                        </Stack> : null
                                                    }

                                                    {
                                                        (filters?.filterNewsletter !== null) ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="ion:heart-outline" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Abonné aux newsletters:</Typography>
                                                            <Typography variant='caption'>true</Typography>
                                                        </Stack> : null
                                                    }
                                                    {
                                                        (filters?.filterHist !== null) ? <Stack sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} alignItems={'center'} padding={1} direction='row' spacing={0.5}>
                                                            <Iconify icon="material-symbols:update-rounded" sx={{ width: 17, height: 17 }} />
                                                            <Typography variant='caption'>Date d'ajout:</Typography>
                                                            <Typography variant='caption'>true</Typography>
                                                        </Stack> : null
                                                    }

                                                </Stack>
                                            </>
                                        }

                                    </Stack>
                                </Box>
                                : null
            }

            {/** boutons next/ back */}
            <Stack direction='column' spacing={1} padding={1} justifyContent={'right'} alignItems={'end'} sx={{ borderTop: '1px solid gainsboro' }}>
                {
                    step !== 0 && <>
                        {type === 0 && <Typography variant='body2'><strong>{nombre}</strong> contact</Typography>}
                        <Stack direction='row' spacing={2}>
                            <CustomButton loading={loading} type={'secondaire'} buttonText={step === 1 ? 'Annuler' : 'retour'} onClick={() => {
                                if (step === 0) {
                                    setOpenDrawer(false);
                                }
                                else {
                                    setStep(step - 1)
                                }

                            }} />
                            <CustomButton loading={loading} type={'primaire'} buttonText={step === 2 ? `${populationID ? 'Sauvegarder' : 'Créer la population'}` : 'Suivant'} onClick={() => {
                                if (step === 2) {
                                    if (populationID) {
                                        savePopulation()
                                    } else {
                                        createPopulation()
                                    }

                                } else {
                                    setStep(step + 1)
                                }
                            }} />
                        </Stack>
                    </>
                }
            </Stack>
        </Box>
    );
}

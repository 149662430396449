import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import { Avatar, Box, Card, Divider, IconButton, Stack, Typography } from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';

import { deepOrange } from '@mui/material/colors';
import Iconify from '../../../../components/Iconify';
import CustomTag from '../../../../components/customTag';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ContactDrawer({ user, contact }) {

  React.useEffect(() => {
    (async () => {
      try {
        console.log(user.email);
        console.log(contact.data());
      } catch (error) {
        console.log('error');
      }
    })();
  }, [user, contact]);

  return (
    <Box>
      {
        contact ? <Stack padding={3} spacing={3}>
        <Box sx={{ width: '100%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2',}}>
          <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          
            <Avatar sx={{  width: 70, height: 70 }} src={user ? user?.photoUrl : ''} />
            <Typography variant="h4">{user ? `${user?.nom} ${user?.prenom}` : '-'}</Typography>
            <Typography variant='body2'>{user.email  ? `${user?.email}` : `${contact?.id}`}</Typography>
            <Typography variant='body2'>{contact?.data().tel ? `${contact?.data().tel}` : `-`}</Typography>
            <Stack direction={'row'} spacing={1}>
              <CustomTag text={`${contact?.data().abonne ? 'Abonne : Oui' : 'Abonne : Non'}`} type={contact?.data().abonne ? 'success' : 'error'} />
              <CustomTag text={`${contact?.data().newslettres ? 'Newsletters : Oui' : 'Newsletters : Non'}`} type={contact?.data().newslettres ? 'success' : 'error'} />
              <CustomTag text={`${contact?.data().push ? 'Notif : Oui' : 'Notif : Non'}`} type={contact?.data().push ? 'success' : 'error'} />
            </Stack>
          </Stack>
        </Box>
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          <Box sx={{ width: '50%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2', }}>
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
              <Typography variant='subtitle1'>Total évènements</Typography>
              <Typography variant="h5">{contact?.data().events ? contact?.data().events?.length : 0}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '50%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2',
            }}
          >
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
              <Typography variant='subtitle1'> Total tickets</Typography>
              <Typography variant="h5">{contact?.data().tickets ? contact?.data().tickets : '-'}</Typography>
            </Stack>
          </Box>
        </Stack>
        <Box sx={{ width: '50%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2', }}>
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
              <Typography><strong>Total Dépensé</strong></Typography>
              <Typography variant="h5">{contact?.data().depenses ? contact?.data().depense : '-'}</Typography>
            </Stack>
          </Box>
      </Stack>: null
      }
      
    </Box>
  );
}

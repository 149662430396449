import { Navigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { UserAuth } from "./context/AuthContext";
import CustomButton from "./components/CustomButton";

// Fonction de vérification des permissions
const hasPermission = (permissions, requiredPermission) =>
  Object.values(permissions || {}).some((perms) =>
    perms.includes(requiredPermission)
  );

const withAuthorization = (WrappedComponent, requiredPermission) => (props) => {
  const { user, permissions } = UserAuth();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
        
      // Démarre le chargement
      setLoading(true);

      if (user && permissions) {
        const allowed = hasPermission(permissions, requiredPermission);
        setAuthorized(allowed);
      }

      // Termine le chargement (que l'accès soit OK ou pas)
      setLoading(false);
    };

    // Lance la vérification dès que user/permissions sont définis
    if (user !== undefined && permissions !== undefined) {

        setLoading(true);
      checkAccess();
    }
    setLoading(false);
  }, [user, permissions, requiredPermission]);

  // 🌀 Affichage du chargement
  if (loading) {
    return (
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  // 🔐 Redirection si non connecté
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // 🚫 Affichage du refus si pas autorisé
  if (!authorized) {
    return (
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Typography variant="h4" textAlign="center" gutterBottom>
          ⛔ Accès refusé
        </Typography>
        <Typography variant="body1" textAlign="center" mb={2}>
          Vous n’avez pas les permissions requises pour accéder à cette page.
        </Typography>
        <CustomButton type="secondaire" buttonText="Retour" onClick={() => window.history.back()} />
      </Box>
    );
  }

  // ✅ Tout est bon, affiche le composant
  return <WrappedComponent {...props} />;
};

export default withAuthorization;

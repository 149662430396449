import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//

import { doc, getDoc } from 'firebase/firestore';
import DashboardSidebar from '../dashboard/DashboardSidebar';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DashboardSidebarOrg from '../dashboard/DashboardSidebarOrg';
import { Variables } from '../../context/VariableContext';
import { UserAuth } from '../../context/AuthContext';
import { db } from '../../firebase.config';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background: '#ffffff'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: 'calc(100vh - 64px)',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(2),
  background: 'white',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function OrganisationLayout() {
  const [open, setOpen] = useState(false);
  const { generalReload, setGeneralReload } = Variables();
  const { user, userData } = UserAuth()
  const [org, setOrg] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        console.log('-------------------------------> reload ')
        setOrg();
        // setLoading(true);
        const docRef0 = doc(db, 'organisations', userData.orgId);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data());
        if (docSnap.exists()) {
          setOrg(docSnap.data())
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [generalReload, userData]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebarOrg isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} banner={org ? org.bannerVisuel : null} logo={org ? org.idVisuel : null} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
